import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { JobRoutingModule } from "./job-routing.module";
import { SharedModule } from "@shared/shared.module";
import { JobResolverService } from "./job-resolver.service";
import { JobComponent } from "./job/job.component";
import { JobMenuComponent } from "./job-menu/job-menu.component";
import { JobDetailComponent } from "./job-detail/job-detail.component";
import { JobSectionHeadingComponent } from "./job-section-heading/job-section-heading.component";
import { JobDescriptionComponent } from "./job-description/job-description.component";
import { JobInstructionComponent } from "./job-instruction/job-instruction.component";
import { JobDetailListComponent } from "./job-detail-list/job-detail-list.component";
import { JobLocationComponent } from "./job-location/job-location.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        JobRoutingModule
    ],
    providers: [
        JobResolverService
    ],
    declarations: [
        JobComponent,
        JobMenuComponent,
        JobDetailComponent,
        JobSectionHeadingComponent,
        JobDescriptionComponent,
        JobInstructionComponent,
        JobDetailListComponent,
        JobLocationComponent
    ]
})
export class JobModule { }
