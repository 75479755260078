import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";

import { ProductService } from "@services/product.service";
import { SharedService } from "@services/shared.service";
import { GlobalService } from "@services/global.service";
import { environment } from "@environment";
import { ProductQuickLink } from "@models/product-quick-link";
import { SharedHeader } from "@models/shared-header";

@Component({
    selector: "product-quick-link",
    templateUrl: "./product-quick-link.component.html",
    styleUrls: ["./product-quick-link.component.less"]
})
export class ProductQuickLinkComponent implements OnInit, OnDestroy {
    imageLocation: string = environment.ImageLocation;

    private subscription: Subscription;
    private routeSubscription: Subscription;

    links: ProductQuickLink[] = [];
    productTitle: string;
    showImages: boolean = false;
    showVideos: boolean = false;
    imageGalleryImage: string = "";
    imageGalleryImageHover: string = "";
    videoGalleryImage: string = "";
    videoGalleryImageHover: string = "";

    imageGalleryStyle: string = "";
    videoGalleryStyle: string = "";

    images: ProductQuickLink[] = [];
    videos: ProductQuickLink[] = [];

    constructor(
        private productService: ProductService,
        private sharedService: SharedService,
        private globalService: GlobalService,
        private route: ActivatedRoute
    ) {
        this.subscription = globalService.languageId$.subscribe(
            () => { this.ngOnInit(); }
        );
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe(
            (params: Params) => {
                this.productService.getQuicklinks(params["id"]).subscribe(
                    (links: ProductQuickLink[]) => {
                        this.processLinks(links);
                    }
                );

                this.sharedService.getHeader(params["id"]).subscribe(
                    (header: SharedHeader) => {
                        this.productTitle = header.Title;
                    }
                );
            }
        );
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    hover(type: string): void {
        if (type === "image") {
            this.imageGalleryStyle = this.imageGalleryImageHover;
        } else { // if (type === "video") {
            this.videoGalleryStyle = this.videoGalleryImageHover;
        }
    }

    clear(type: string): void {
        if (type === "image") {
            this.imageGalleryStyle = this.imageGalleryImage;
        } else { // if (type === "video") {
            this.videoGalleryStyle = this.videoGalleryImage;
        }
    }

    private processLinks(links: ProductQuickLink[]): void {
        this.images = [];
        this.videos = [];
        if (links !== null && links !== undefined) {
            links.forEach((link) => {
                if (link.MediaType.toLowerCase() === "image") {
                    this.images.push(link);
                } else if (link.MediaType.toLowerCase() === "video") {
                    this.videos.push(link);
                    // this.getVideoThumbnails("");
                } else if (link.MediaType.toLowerCase() === "button") {
                    if (link.Caption.toLowerCase() === "image gallery") {
                        this.imageGalleryImage = `url('${environment.ImageLocation}${link.FileInfo.FileName}')`;
                        this.imageGalleryImageHover = `url('${environment.ImageLocation}${link.HoverMediaFileName}')`;
                    } else {    // "video gallery"
                        this.videoGalleryImage = `url('${environment.ImageLocation}${link.FileInfo.FileName}')`;
                        this.videoGalleryImageHover = `url('${environment.ImageLocation}${link.HoverMediaFileName}')`;
                    }
                }
            });

            this.imageGalleryStyle = this.imageGalleryImage;
            this.videoGalleryStyle = this.videoGalleryImage;
        }

        this.links = links === null ? [] : links;

        const urlSegment: string = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
        if (urlSegment === "images" || urlSegment === "image") {
            this.showImages = true;
        } else if (urlSegment === "videos" || urlSegment === "video") {
            this.showVideos = true;
        }
    }

    videoLinkExists(): boolean {
        return this.videos !== null && this.videos.length > 0;
    }

    imageLinkExists(): boolean {
        return this.images !== null && this.images.length > 0;
    }

    onShowVideos(): void {
        this.showVideos = true;
    }

    onShowImages(): void {
        this.showImages = true;
    }

    onImageViewerClosed(closed: boolean): void {
        this.showImages = false;
    }

    onVideoViewerClosed(closed: boolean): void {
        this.showVideos = false;
    }

    getVideoThumbnails(videoId: string) {
        videoId = "6271487";
        this.productService.getVideoThumbnails(videoId).subscribe(
            (test: string) => {
                console.log(test);
            }
        );
    }
}
