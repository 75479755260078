import { Directive, OnInit, OnDestroy, EventEmitter, Output } from "@angular/core";
import { Subscription } from "rxjs";

import { SortService, ColumnSortedEvent } from "./sort.service";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: "[sortable-table]"
})
export class SortableTableDirective implements OnInit, OnDestroy {
    constructor(private sortService: SortService) {}

    private columnSortedSubscription: Subscription;

    @Output() sorted: EventEmitter<ColumnSortedEvent> = new EventEmitter<ColumnSortedEvent>();

    ngOnInit(): void {
        this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
            this.sorted.emit(event);
        });
    }

    ngOnDestroy(): void {
        if (this.columnSortedSubscription) {
            this.columnSortedSubscription.unsubscribe();
        }
    }
}
