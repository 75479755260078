<table class="pageTable">
    <tr *ngFor="let row of rows" [ngClass]="{ 'tableRowHeading' : row.DetailType==='Heading' }">
        <td *ngFor="let column of row.columns; let idx = index"
                [style.font-weight]="column.fontWeight"
                [style.width.px]="column.width"
                [class]="row.class"
                [attr.colspan]="row.DetailType === 'Footnote' ? row.NumberOfColumns + 1 : 1">
            {{column.value}}
        </td>
    </tr>
</table>
