import { Component, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "job-section-heading",
    templateUrl: "./job-section-heading.component.html",
    styleUrls: ["./job-section-heading.component.less"]
})
export class JobSectionHeadingComponent {
    text: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    setText(text: string): void {
        this.text = this.sanitizer.bypassSecurityTrustHtml(text);
    }
}
