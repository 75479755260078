import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

declare var enableClickDimensions: any;

import { GlobalService } from '@services/global.service';
import { PageDetail } from '@models/page-detail';
import { PageService } from '@services/page.service';
import { BaseComponent } from 'src/app/base/base.component';
import { MatSidenav } from '@angular/material/sidenav';
import { PageMenu } from '@models/menu-item';
import { PageSummary } from '@models/page-summary';

@Component({
    selector: 'page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.less'],
})
export class PageComponent extends BaseComponent implements OnInit, AfterViewInit
{
    details: PageDetail[] = [];
    pageId: string;
    menu: PageMenu[] = [];

    @ViewChild("mainMenu") mainMenu: MatSidenav;

    constructor(
        private globalService: GlobalService,
        private pageService: PageService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();

        this.subscriptions.push(
            this.globalService.languageId$.subscribe(() => {
                this.getDetails();
            })
        );

        this.subscriptions.push(
            this.route.params.subscribe((params: Params) => {
                this.pageId = params['id'];

                this.pageService.getMenu(params['id']).subscribe((menu: PageMenu[]) => {
                    this.menu = menu;
                });
            })
        );
    }

    ngOnInit() {
        this.subscriptions.push(this.route.data.subscribe(
            (data: { details: PageDetail[] }) => this.details = data.details
        ));
    }

    ngAfterViewInit() {
        if (this.globalService.cookiesEnabled()) {
            this.globalService.setupClickDimensions();
            // enableClickDimensions();
        }
    }

    onToggleMenu(): void {
        if (this.mainMenu) {
            if (this.mainMenu.opened) {
                this.mainMenu.close();
            } else {
                this.mainMenu.open();
            }
        }
    }

    private getDetails(): void {
        const pageId = this.route.snapshot.params['id'];

        this.pageService.getDetails(pageId).subscribe({
            next: (summary: PageSummary) => {
                if (summary.LoginRequired) {
                    if (summary.Details.length > 0) {
                        this.details = summary.Details;
                    } else {
                        sessionStorage.setItem('returnUrl', this.router.url);
                        this.router.navigate(['login'], { relativeTo: this.route })
                    }
                } else {
                    this.details = summary.Details;
                }
            },
            error: (error: any) => {
                this.handleError(error);
            }
        });
    }
}
