import { Component, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "job-instruction",
    templateUrl: "./job-instruction.component.html",
    styleUrls: ["./job-instruction.component.less"]
})
export class JobInstructionComponent {
    text: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    setText(text: string): void {
        this.text = this.sanitizer.bypassSecurityTrustHtml(text);
    }
}
