import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ContentRoutingModule } from "./content-routing.module";
import { ContentComponent } from "./content/content.component";
import { SharedModule } from "@shared/shared.module";
import { ContentMenuComponent } from "./content-menu/content-menu.component";
import { ContentDetailComponent } from "./content-detail/content-detail.component";
import { ContentService } from "@services/content.service";
import { ContentDescriptionHeadingComponent } from "./content-description-heading/content-description-heading.component";
import { ContentResolverService } from "./content-resolver.service";
import { ContentDescriptionComponent } from "./content-description/content-description.component";
import { ContentTestimonialComponent } from "./content-testimonial/content-testimonial.component";
import { ContentTableComponent } from "./content-table/content-table.component";
import { ContentImageComponent } from "./content-image/content-image.component";
import { ContentVideoComponent } from "./content-video/content-video.component";
import { ContentFeatureComponent } from "./content-feature/content-feature.component";
import { ContentContentFeatureComponent } from "./content-content-feature/content-content-feature.component";
import { ContentContentItemComponent } from "./content-content-item/content-content-item.component";
import { ContentMediaLinkComponent } from "./content-media-link/content-media-link.component";
import { ContentManualLinkComponent } from "./content-manual-link/content-manual-link.component";
import { ContentJobListingComponent } from "./content-job-listing/content-job-listing.component";

@NgModule({
    imports: [
        CommonModule,
        ContentRoutingModule,
        SharedModule
    ],
    providers: [
        ContentService,
        ContentResolverService
    ],
    declarations: [
        ContentComponent,
        ContentMenuComponent,
        ContentDetailComponent,
        ContentDescriptionHeadingComponent,
        ContentDescriptionComponent,
        ContentTestimonialComponent,
        ContentTableComponent,
        ContentImageComponent,
        ContentVideoComponent,
        ContentFeatureComponent,
        ContentContentFeatureComponent,
        ContentContentItemComponent,
        ContentMediaLinkComponent,
        ContentManualLinkComponent,
        ContentJobListingComponent
    ]
})
export class ContentModule { }
