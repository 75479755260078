import { Component, Input, Output, EventEmitter } from "@angular/core";

import { SearchResult } from "@models/search-result";
import { SearchResultCategory } from "@models/search-result-category";

@Component({
    selector: "search-result-categories",
    templateUrl: "./search-result-categories.component.html",
    styleUrls: ["./search-result-categories.component.less"]
})
export class SearchResultCategoriesComponent {
    @Input() result: SearchResult;

    @Output() onFilterResults: EventEmitter<string> = new EventEmitter<string>();

    filterBy: string = "";

    filterResults(category: SearchResultCategory) {
        if (category.Category === this.filterBy) {
            this.filterBy = "";
        } else {
            this.filterBy = category.Category;
        }

        this.onFilterResults.emit(this.filterBy);
    }
}
