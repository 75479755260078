import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { PageMenu } from '@models/menu-item';
import { GlobalService } from '@services/global.service';
import { PageService } from '@services/page.service';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
    selector: 'page-menu',
    templateUrl: './page-menu.component.html',
    styleUrls: ['./page-menu.component.less'],
})
export class PageMenuComponent extends BaseComponent implements OnInit {
    menu: PageMenu[] = [];
    pageId: string;
    selectedMenu: PageMenu;
    languageCode: string;

    constructor(
        private globalService: GlobalService,
        private pageService: PageService,
        private route: ActivatedRoute
    ) {
        super();

        this.subscriptions.push(globalService.languageId$.subscribe(() => {
            this.ngOnInit();
        }));
    }

    ngOnInit(): void {
        this.subscriptions.push(this.route.params.subscribe(
            (params: Params) => {
                this.pageId = params['id'];

                this.pageService.getMenu(params['id']).subscribe((menu: PageMenu[]) => {
                    this.menu = menu;
                    if (menu !== null && menu !== undefined) {
                        this.selectedMenu = menu.find(
                            (page) => page.Id.toLowerCase() === this.pageId.toLowerCase()
                        );
                    }
                });
            }
        ));

        this.languageCode = this.globalService.getLanguageCode();
    }

    getClass(item: PageMenu) {
        let cssClass: any;

        if (item.Id.toLowerCase() === this.selectedMenu.Id.toLowerCase()) {
            cssClass = {
                menuSelected: true,
                menuChild: false,
                menuParent: false,
                menuSibling: false,
            };
        } else if (item.Level < this.selectedMenu.Level) {
            cssClass = {
                menuSelected: false,
                menuChild: false,
                menuParent: true,
                menuSibling: false,
            };
        } else if (item.Level > this.selectedMenu.Level) {
            cssClass = {
                menuSelected: false,
                menuChild: true,
                menuParent: false,
                menuSibling: false,
            };
        } else {
            cssClass = {
                menuSelected: false,
                menuChild: false,
                menuParent: false,
                menuSibling: true,
            };
        }

        return cssClass;
    }

    getCurrentPageId(): string {
        const id: string = this.route.snapshot.params['id'];
        if (id) {
            return id;
        }

        return 'home';
    }

    getCurrentPageType(): string {
        if (this.route.snapshot.url.length > 2) {
            return this.route.snapshot.url[1].path;
        }

        return 'page';
    }
}
