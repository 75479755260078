import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalService } from './global.service';
import { SharedHeader } from '@models/shared-header';
import { environment } from '@environment';

@Injectable()
export class SharedService {
    constructor(
        private http: HttpClient,
        private globalService: GlobalService
    ) {}

    getHeader(id: string): Observable<SharedHeader> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });
        return this.http.get<SharedHeader>(`${environment.ApiBase}master/shared/header/${id}`,{ params: params });
    }

    getMobileHeader(id: string): Observable<SharedHeader> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });
        return this.http.get<SharedHeader>(`${environment.ApiBase}master/shared/header/${id}/mobile`,{ params: params });
    }
}
