import { Component } from '@angular/core';

import { environment } from '@environment';

@Component({
    selector: 'page-media-link',
    templateUrl: './page-media-link.component.html',
    styleUrls: ['./page-media-link.component.less'],
})
export class PageMediaLinkComponent {
    imageLocation: string = environment.ImageLocation;

    title: string;
    description: string;
    other: string;
    mediaFileName: string;

    protected getLink(): string {
        if (this.other !== '') {
            const idx = this.other.indexOf('Id=');
            if (idx > -1) {
                return this.other.substring(idx + 3);
            }
        }

        return this.other;
    }
}
