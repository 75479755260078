import { Directive, Input } from "@angular/core";
import { Validator, AbstractControl } from "@angular/forms";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: "uploadFileType"
})
export class UploadFileTypeValidatorDirective implements Validator {
    static imageFileExtensions: string[];
    static documentFileExtensions: string[];

    @Input() uploadFileType: string;

    constructor() {
        UploadFileTypeValidatorDirective.imageFileExtensions = [
            ".jpg",
            ".jpeg",
            ".pdf",
            ".png",
            ".docx"
        ];
    }

    validate(control: AbstractControl): { [key: string]: any } {
        let validFileType: boolean = false;

        if (this.uploadFileType === "image") {
            UploadFileTypeValidatorDirective.imageFileExtensions.forEach((t: string) => {
                if (control.value.endsWith(t)) {
                    validFileType = true;
                    return;
                }
            });
        }

        return validFileType ? null : { "uploadFileType": { value: control.value }};
    }
}
