import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@environment";
import { GlobalService } from "./global.service";
import { ContentDetail } from "@models/content-detail";
import { ContentMenu } from "@models/menu-item";

@Injectable()
export class ContentService {
    private basePageUrl: string = environment.ApiBase + "page/";
    private baseContentUrl: string = environment.ApiBase + "content/";

    constructor(private http: HttpClient, private globalService: GlobalService ) { }

    getDetails(id: string): Observable<ContentDetail[]> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });

        return this.http.get<ContentDetail[]>(this.baseContentUrl + id, { params: params });
    }

    getMenu(id: string): Observable<ContentMenu[]> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });

        return this.http.get<ContentMenu[]>(`${this.basePageUrl}menu/${id}`, { params: params });
    }
}
