import { Component, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "job-detail-list",
    templateUrl: "./job-detail-list.component.html",
    styleUrls: ["./job-detail-list.component.less"]
})
export class JobDetailListComponent {
    text: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {}

    setText(text: string): void {
        if (text !== "") {
            this.text = this.sanitizer.bypassSecurityTrustHtml(text);
        }
    }
}
