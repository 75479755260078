import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageComponent } from './page/page.component';
import { PageResolverService } from './page-resolver.service';
import { IsSecureGuard } from '../is-secure.guard';
import { DomainAndUserGuard } from '../domain-and-user.guard';

const routes: Routes = [
    {
        path: ':languageId/page/:id',
        component: PageComponent,
        resolve: { details: PageResolverService },
        canActivate: [IsSecureGuard, DomainAndUserGuard],
    },
    {
        path: ':languageId/page/:id/:subId',
        component: PageComponent,
        resolve: { details: PageResolverService },
        canActivate: [IsSecureGuard, DomainAndUserGuard],
    },
    {
        path: 'cp/i/:id',
        component: PageComponent,
        resolve: { details: PageResolverService },
        canActivate: [IsSecureGuard, DomainAndUserGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PageRoutingModule {}
