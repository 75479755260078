import { FileInfo } from "./file-info";

export class StandardFeature {
    Id: string;
    Color: string;
    HtmlColor: string;
    HtmlHoverColor: string;
    TargetType: string;
    Target: string;
    LinkCausesNewWindow: boolean;
    Title: string;
    Description: string;
    MediaFileName: string;

    FileInfo: FileInfo;

    // determined when visible (normal: HtmlColor, hover: HtmlHoverColor)
    BackgroundColor: string;

    constructor(data: any | null) {
        if (data) {
            Object.assign(this, data);
        }
    }

    get isMedia(): boolean {
        return this.TargetType.toLowerCase() === "media";
    }

    get isURL(): boolean {
        return this.TargetType.toLowerCase() === "url";
    }

    get isNone(): boolean {
        return this.TargetType.toLowerCase() === "none";
    }
}
