import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environment';
import { JobListing } from '@models/job-listing';
import { JobService } from '@services/job.service';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
    selector: 'job-listing',
    templateUrl: './job-listing.component.html',
    styleUrls: ['./job-listing.component.less'],
})
export class JobListingComponent extends BaseComponent implements OnInit {
    localImageLocation: string = environment.LocalImageLocation;

    listings: JobListing[];
    languageCode: string;

    constructor(private jobService: JobService) {
        super();
    }

    ngOnInit(): void {
        this.jobService.getListings().subscribe({
            next: (listings: JobListing[]) => {
                this.listings = listings;
            },
            error: (error: any) => {
                this.handleError(error);
            }
        });
    }

    protected getDescription(description: string): string {
        if (description.length > 100) {
            return description.substring(0, 100) + '...';
        }

        return description;
    }

    navigateToJobs(): void {
        window.open("https://woodtech.rec.pro.ukg.net/VEC1002MIDCO/JobBoard/0e81673e-c740-4862-8d4c-d5c670fd46b9", "_blank");
    }
}
