import { Component } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "product-summary",
    templateUrl: "./product-summary.component.html",
    styleUrls: ["./product-summary.component.less"]
})
export class ProductSummaryComponent {
    description: SafeHtml;
    descriptionPresent: boolean;

    constructor(private sanitizer: DomSanitizer) { }

    setDescription(description: string): void {
        if (description === "") {
            this.descriptionPresent = false;
        } else {
            this.description = this.sanitizer.bypassSecurityTrustHtml(description);
            this.descriptionPresent = true;
        }
    }
}
