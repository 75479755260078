import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTreeModule } from "@angular/material/tree";
import { RouterModule } from "@angular/router";

import { SortService } from "../data-query/sortable-column/sort.service";
import { DataQueryResultsGridComponent } from "./data-query-results-grid/data-query-results-grid.component";
import { DataQueryResultsHeadingComponent } from "./data-query-results-heading/data-query-results-heading.component";
import { DataQueryResultsListComponent } from "./data-query-results-list/data-query-results-list.component";
import { DataQueryResultsComponent } from "./data-query-results/data-query-results.component";
import { SortableColumnComponent } from "./sortable-column/sortable-column.component";
import { SortableTableDirective } from "./sortable-column/sortable-table.directive";
import { ImageLinkComponent } from "./image-link/image-link.component";
import { ImageComponent } from "./image/image.component";
import { IOStringComponent } from "./iostring/iostring.component";
import { AnchorComponent } from "./anchor/anchor.component";
import { LinkBaseComponent } from './link-base/link-base.component';

@NgModule({
    declarations: [
        DataQueryResultsComponent,
        DataQueryResultsHeadingComponent,
        DataQueryResultsGridComponent,
        DataQueryResultsListComponent,
        SortableColumnComponent,
        SortableTableDirective,
        IOStringComponent,
        ImageComponent,
        ImageLinkComponent,
        AnchorComponent,
        LinkBaseComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatTreeModule
    ],
    exports: [
        DataQueryResultsComponent
    ],
    providers: [
        SortService
    ]
})
export class DataQueryModule { }
