import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
// import { MyDatePickerModule } from "mydatepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { NgxCaptchaModule } from "ngx-captcha";
import { MatIconModule } from "@angular/material/icon";
import { MatTreeModule } from "@angular/material/tree";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CookieService } from 'ngx-cookie-service';

import { SharedService } from "@services/shared.service";
import { CarouselComponent } from "./carousel/carousel.component";
import { GeneralFeaturesComponent } from "./general-features/general-features.component";
import { FormDetailComponent } from "./form-detail/form-detail.component";
import { StandardFeaturesComponent } from "./standard-features/standard-features.component";
import { DynamicFormComponent } from "./dynamic-form/dynamic-form.component";
import { FormService } from "@services/form.service";
import { ModalComponent } from "./modal/modal.component";
import { ModalService } from "@services/modal.service";
import { DynamicFormFieldComponent } from "./dynamic-form-field/dynamic-form-field.component";
import { FieldErrorDisplayComponent } from "./field-error-display/field-error-display.component";
import { UploadFileTypeValidatorDirective } from "./upload-file-type-validator.directive";
import { VideoContainerComponent } from "./video-container/video-container.component";
import { DataQueryModule } from "../data-query/data-query.module";
import { ModalResultsDialogComponent } from "./modal-results-dialog/modal-results-dialog.component";
import { MobileSharedHeaderComponent } from './shared-headers/mobile-shared-header/mobile-shared-header.component';
import { SharedHeaderComponent } from './shared-headers/shared-header/shared-header.component';
import { PrivacyAlertCheckComponent } from './privacy-alert/privacy-alert-check/privacy-alert-check.component';
import { PrivacyAlertDialogComponent } from './privacy-alert/privacy-alert-dialog/privacy-alert-dialog.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // NgxCaptchaModule,
        FormsModule,
        RecaptchaV3Module,
        ReactiveFormsModule,
        // MyDatePickerModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatTreeModule,
        DataQueryModule,
        DragDropModule,
    ],
    providers: [
        SharedService,
        FormService,
        ModalService,
        [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LdiLUAiAAAAAI-0CNSKOTLLDRWNyyFyy6gqc5yU" }]
    ],
    declarations: [
        SharedHeaderComponent,
        CarouselComponent,
        GeneralFeaturesComponent,
        FormDetailComponent,
        StandardFeaturesComponent,
        DynamicFormComponent,
        ModalComponent,
        DynamicFormFieldComponent,
        FieldErrorDisplayComponent,
        UploadFileTypeValidatorDirective,
        VideoContainerComponent,
        ModalResultsDialogComponent,
        MobileSharedHeaderComponent,
        PrivacyAlertCheckComponent,
        PrivacyAlertDialogComponent,
    ],
    exports: [
        GeneralFeaturesComponent,
        FormDetailComponent,
        StandardFeaturesComponent,
        VideoContainerComponent,
        SharedHeaderComponent,
        MobileSharedHeaderComponent,
        PrivacyAlertCheckComponent
    ]
})
export class SharedModule { }
