import { Component } from '@angular/core';

import { environment } from '@environment';

@Component({
    selector: 'page-content-item',
    templateUrl: './page-content-item.component.html',
    styleUrls: ['./page-content-item.component.less'],
})
export class PageContentItemComponent {
    imageLocation: string = environment.ImageLocation;

    id: string;
    title: string;
    description: string;

    languageCode: string;
}
