<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="formTable">
            <div *ngIf="responseMessage && responseMessage !== ''" class="formRow" [ngStyle]="{ 'color': getMessageStyle() }">
                <strong>{{responseMessage}}</strong><br/>
            </div>
            <div *ngFor="let field of fields" class="formRow">
                <dynamic-form-field *ngIf="field.controlType==='buttonSubmitAndClear'" [field]="field" [form]="form" [submitAttempt]="formSubmitAttempt" (clearForm)="onClear()"></dynamic-form-field>
                <dynamic-form-field *ngIf="field.controlType==='buttonAttachFile' || field.controlType==='buttonAttachImage'" [field]="field" [form]="form" [submitAttempt]="formSubmitAttempt" (uploadingFile)="disableForm($event)"></dynamic-form-field>
                <dynamic-form-field *ngIf="field.controlType!=='buttonSubmitAndClear' && field.controlType!=='buttonAttachFile' && field.controlType!=='buttonAttachImage' && field.controlType!=='msgFailure'"
                    [field]="field" [form]="form" [submitAttempt]="formSubmitAttempt"></dynamic-form-field>
            </div>
        </div>
    </form>
    <img *ngIf="busy" src="{{localImageLocation}}ajax-loader.gif" class="form-busy" alt="loading">
    <data-query-results [data]="dataQueryResults" (refreshPage)="onRefreshDataQuery()" (showModalResults)="onShowResultsInModal($event)"></data-query-results>
    <modal title="Confirmation" [blocking]="false" [modalId]="successModalId" (closed)="onModalClosed()">
        <div style="text-align: center">{{responseMessage}}</div>
    </modal>
</div>
