<div>
    <div class="d-sm-none d-md-block">
        <toolbar></toolbar>
    </div>
    <div class="usnrPage">
        <header class="d-sm-none d-md-block"></header>
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
        <footer class="d-sm-none d-md-block"></footer>
    </div>
</div>
