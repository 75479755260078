import { Component } from "@angular/core";

import { environment } from "@environment";

@Component({
    selector: "content-content-feature",
    templateUrl: "./content-content-feature.component.html",
    styleUrls: ["./content-content-feature.component.less"]
})
export class ContentContentFeatureComponent {
    imageLocation: string = environment.ImageLocation;

    id: string;
    mediaFileName: string;
    title: string;
    description: string;
    other: string;
    targetId: string;

    languageCode: string;
}
