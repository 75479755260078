import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { environment } from "@environment";
import { GlobalService } from "@services/global.service";
import { UserService } from "@services/user.service";
import { StandardFeature } from "@models/standard-feature";

// TODO: make internal links act like general features (router.navigate) rather than href urls
@Component({
    selector: "standard-features",
    templateUrl: "./standard-features.component.html",
    styleUrls: ["./standard-features.component.less"]
})
export class StandardFeaturesComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private userSubscription: Subscription;
    private routeSubscription: Subscription;

    @Input() id: string;

    features: StandardFeature[] = [];
    imageLocation: string = environment.ImageLocation;

    languageCode: string;

    constructor(
        private globalService: GlobalService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private userService: UserService
    ) {
        this.languageSubscription = globalService.languageId$.subscribe(
            () => { this.ngOnInit(); }
        );

        this.userSubscription = this.userService.currentUser$.subscribe(
            () => this.ngOnInit()
        );
    }

    setHoverColor(feature: StandardFeature): void {
        feature.BackgroundColor = feature.HtmlHoverColor;
    }

    setBackgroundColor(feature: StandardFeature): void {
        feature.BackgroundColor = feature.HtmlColor;
    }

    ngOnInit(): void {
        this.languageCode = this.globalService.getLanguageCode();

        if (this.id === undefined || this.id === null || this.id === "") {
            this.routeSubscription = this.route.params.subscribe(
                (params: Params) => {
                    this.globalService.getStandardFeatures(params["id"]).subscribe(
                        (features: StandardFeature[]) => this.processFeatures(features));
                },
                (error: any) => {
                    this.handleError(error);
                }
            );
        } else {
            this.routeSubscription = this.globalService.getStandardFeatures(this.id).subscribe(
                (features: StandardFeature[]) => { this.processFeatures(features); },
                (error: any) => { this.handleError(error); }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    private processFeatures(features: StandardFeature[]) {
        if (features) {
            features.forEach((f: StandardFeature) => {
                // TEMP FOR TESTING
                // if (f.Target.toLowerCase() === "applyform") {
                //     f.LinkCausesNewWindow = false;
                // }
                f.BackgroundColor = f.HtmlColor;
            });
        }
        this.features = features;
    }

    getField(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    getCurrentPageId(): string {
        const id: string = this.route.snapshot.params["id"];
        if (id) {
            return id;
        }

        return "home";
    }

    getCurrentPageType(): string {
        if (this.route.snapshot.url.length > 2) {
            return this.route.snapshot.url[1].path;
        }

        return "page";
    }

    private handleError(error: any): void {
        console.error("An error occurred", error);
    }
}
