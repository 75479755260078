import { Component } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { environment } from '@environment';
import { PageDetail } from '@models/page-detail';

@Component({
    selector: 'page-image',
    templateUrl: './page-image.component.html',
    styleUrls: ['./page-image.component.less'],
})
export class PageImageComponent {
    imageLocation: string = environment.ImageLocation;

    detail: PageDetail;

    constructor(private sanitizer: DomSanitizer) {}

    protected imageWidth(): number {
        if (
            (this.detail.DetailType === 'Inline Image Right' ||
                this.detail.DetailType === 'Inline Image Left') &&
            this.detail.FileInfo
        ) {
            return this.detail.FileInfo.Width > 300 ? 300 : this.detail.FileInfo.Width;
        }

        return 540;
    }

    protected description(): SafeHtml {
        return this.detail.Description === '' ? '' : this.sanitizer.bypassSecurityTrustHtml(this.detail.Description);
    }
}
