import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";

import { ContentMenu } from "@models/menu-item";
import { GlobalService } from "@services/global.service";
import { ContentService } from "@services/content.service";

@Component({
    selector: "content-menu",
    templateUrl: "./content-menu.component.html",
    styleUrls: ["./content-menu.component.less"]
})
export class ContentMenuComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private routeSubscription: Subscription;

    menu: ContentMenu[];
    id: string;
    selectedMenu: ContentMenu;

    languageCode: string;

    constructor(private globalService: GlobalService, private contentService: ContentService, private route: ActivatedRoute) {
        this.subscription = globalService.languageId$.subscribe(() => { this.ngOnInit(); } );
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe(
            (params: Params) => {
                this.id = params["id"];

                this.contentService.getMenu(params["id"])
                    .subscribe(menu => {
                        this.menu = menu;
                        if (menu !== null && menu !== undefined) {
                            this.selectedMenu = menu.find(content => content.Id.toLowerCase() === this.id.toLowerCase());
                        }
                    });
            }
        );

        this.languageCode = this.globalService.getLanguageCode();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    getClass(item: ContentMenu) {
        let cssClass: any;

        if (item.Id.toLowerCase() === this.selectedMenu.Id.toLowerCase()) {
            cssClass = { "menuSelected": true, "menuChild": false, "menuParent": false, "menuSibling": false };
        } else if (item.Level < this.selectedMenu.Level) {
            cssClass = { "menuSelected": false, "menuChild": false, "menuParent": true, "menuSibling": false };
        } else if (item.Level > this.selectedMenu.Level) {
            cssClass = { "menuSelected": false, "menuChild": true, "menuParent": false, "menuSibling": false };
        } else {    // item.Level === this.selectedMenu.Level
            cssClass = { "menuSelected": false, "menuChild": false, "menuParent": false, "menuSibling": true };
        }

        return cssClass;
    }

    getCurrentPageId(): string {
        const id: string = this.route.snapshot.params["id"];
        if (id) {
            return id;
        }

        return "home";
    }

    getCurrentPageType(): string {
        if (this.route.snapshot.url.length > 2) {
            return this.route.snapshot.url[1].path;
        }

        return "page";
    }
}
