import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@environment";
import { GlobalService } from "./global.service";
import { FooterInfo } from "@models/footer-info";

@Injectable()
export class FooterService {
    private baseUrl: string = environment.ApiBase + "master/";

    constructor(private http: HttpClient, private globalService: GlobalService) {}

    getFooter(): Observable<FooterInfo> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });

        return this.http.get<FooterInfo>(this.baseUrl + "footer", { params: params });
    }
}
