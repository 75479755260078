<div *ngIf="result">
    <p class="searchResultsHeader">{{result.ResultsTitle}}...&apos;{{result.OriginalCriteria}}&apos;&nbsp;({{result.Items.length}})</p>
</div>
<div class="searchResults">
    <table *ngIf="result">
        <tr *ngFor="let item of result.Items | filterByCategory: filter" style="padding-bottom: 10px;">
            <td class="searchResultThumbnail">
                <a [routerLink]="['/' + languageCode, item.ObjectType.toLowerCase(), item.ObjectId]" [queryParams]="{ dt: 1 }">
                    <img src="{{imageLocation}}{{item.ThumbnailFileName}}" alt="{{item.Title}}" height="89" width="120" />
                </a>
            </td>
            <td class="searchResultText">
                <a [routerLink]="['/' + languageCode, item.ObjectType.toLowerCase(), item.ObjectId]" [queryParams]="{ dt: 1 }">
                    <div class="searchResultTitle">{{item.Title}}</div>
                </a>
                <div class="searchResultDescription">{{item.Description}}</div>
            </td>
        </tr>
    </table>
    <span *ngIf="!result || result.Items.length === 0" class="searchResultText">{{result.NoResultsTitle}}</span>
</div>
