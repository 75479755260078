import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

import { GlobalService } from "./global.service";

@Injectable()
export class SEOService {
    constructor(private metaService: Meta, private router: Router, private globalService: GlobalService) { }

    public addSEOData(): void {
        this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
            let root = this.router.routerState.snapshot.root;
            while (root) {
                if (root.children && root.children.length) {
                    root = root.children[0];
                } else {
                    this.metaService.updateTag({ "http-equiv": "content-language", content: this.globalService.getLanguageCode() },
                        "http-equiv='content-language'" );
                    return;
                }
            }
        });
    }
}
