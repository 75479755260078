import { Component, Input } from "@angular/core";

import { SearchResult } from "@models/search-result";
import { environment } from "@environment";
import { GlobalService } from "@services/global.service";

@Component({
    selector: "search-result-details",
    templateUrl: "./search-result-details.component.html",
    styleUrls: ["./search-result-details.component.less"]
})
export class SearchResultDetailsComponent {
    @Input() result: SearchResult;
    @Input() filter: string;

    languageCode: string;

    imageLocation: string = environment.ImageLocation;

    constructor(private globalService: GlobalService) {
        this.languageCode = this.globalService.getLanguageCode();
    }
}
