<div class="d-none d-sm-none d-md-block">
    <privacy-alert-check></privacy-alert-check>
    <shared-header pageType="page"></shared-header>
    <div class="pageMenu">
        <page-menu></page-menu>
        <general-features featureType="local"></general-features>
    </div>
    <div class="pageDetail">
        <div class="pageDetailContent">
            <page-detail [details]="details"></page-detail>
        </div>
    </div>
    <div class="pageFeatures">
        <standard-features></standard-features>
    </div>
</div>
<!-- <div class="d-block d-sm-block d-md-none">
    <mobile-toolbar (toggleMainMenu)="onToggleMenu()"></mobile-toolbar>
    <mat-sidenav-container class="mobile-sidenav-container mat-drawer-container mat-sidenav-container">
        <div class="mat-drawer-backdrop ng-star-inserted"></div>
        <mat-sidenav class="mobile-main-menu" mode="over" #mainMenu position="start" [(opened)]="mainMenu.opened">
            <a href="javascript: void(0);" style="height: 0x;"></a>
            <mobile-main-menu></mobile-main-menu>
        </mat-sidenav>
        <mat-sidenav-content class="mobile-sidenavContent">
            <mobile-shared-header [menu]="menu"></mobile-shared-header>
            <div style="margin: 0 10px;">
                <page-detail [details]="details"></page-detail>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <mobile-footer></mobile-footer>
</div> -->
