<div style="clear: both;"></div>
<a [routerLink]="['/' + languageCode, 'content', targetId]" [queryParams]="{ dt: 1 }" style="text-decoration: none;">
    <div class="contentContentFeatureContainer">
        <img width="120px" class="contentimage" src="{{imageLocation}}{{mediaFileName}}" alt="{{title}}" />
        <div>
            <span *ngIf="title !== ''" class="detailstextlink">{{title}}<br /></span>
            <span *ngIf="other !== ''" class="descrtextlink">{{other}}<br /></span>
            <span *ngIf="description !== ''" class="descrtext">{{description}}<br /></span>
        </div>
        <div style="clear: both;"></div>
    </div>
</a>
