import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MatToolbarModule } from '@angular/material/toolbar'
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { MsalModule, MsalService } from '@azure/msal-angular';
import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentModule } from './content/content.module';
import { HeaderComponent } from './headers/header/header.component';
import { IsSecureGuard } from './is-secure.guard';
import { JobModule } from './job/job.module';
import { PageModule } from './page/page.module';
import { ProductModule } from './product/product.module';
import { SearchModule } from './search/search.module';
import { FooterService } from '@services/footer.service';
import { GlobalService } from '@services/global.service';
import { HeaderService } from '@services/header.service';
import { LinkService } from '@services/link.service';
import { SEOService } from '@services/seo.service';
import { ToolbarService } from '@services/toolbar.service';
import { UserService } from '@services/user.service';
import { SharedModule } from '@shared/shared.module';
import { MobileHeaderComponent } from './headers/mobile-header/mobile-header.component';
import { MobileBaseComponent } from './mobile-base/mobile-base.component';
import { BaseComponent } from './base/base.component';
import { ToolbarsModule } from './toolbars/toolbars.module';
import { FootersModule } from './footers/footers.module';
import { AuthInterceptor } from '@services/auth-interceptor.service';

const cookieConsentConfig : NgcCookieConsentConfig = {
    cookie: {
        domain: 'localhost' // 'www.usnr.com'
    },
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#f1d600'
        }
    },
    theme: 'edgeless',
    type: 'info'
};

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        MobileHeaderComponent,
        MobileBaseComponent,
        BaseComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        PageModule,
        ContentModule,
        ProductModule,
        JobModule,
        SearchModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // NgMarqueeModule,
        ToolbarsModule,
        MatToolbarModule,
        FootersModule,
        NgcCookieConsentModule.forRoot(cookieConsentConfig),
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: '89de6df0-8462-4106-a6dc-390a8a707145',
                authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
                redirectUri: window.location.origin + '/dtsi',
                postLogoutRedirectUri: window.location.origin
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            },
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Verbose,
                    piiLoggingEnabled: false
                }
            }
        }),
        {
            interactionType: InteractionType.Popup
        }, null)
    ],
    providers: [
        Title,
        SEOService,
        ToolbarService,
        GlobalService,
        UserService,
        HeaderService,
        FooterService,
        IsSecureGuard,
        LinkService,
        NgxImageCompressService,
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
