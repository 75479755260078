import { Component, AfterViewChecked, Output, EventEmitter, Input } from "@angular/core";

import { ProductQuickLink } from "@models/product-quick-link";
import { environment } from "@environment";

@Component({
    selector: "image-view-maximized",
    templateUrl: "./image-view-maximized.component.html",
    styleUrls: ["./image-view-maximized.component.less"]
})
export class ImageViewMaximizedComponent implements AfterViewChecked {
    @Output() imageMaximized: EventEmitter<boolean> = new EventEmitter<boolean>();
    maximizedImageFocuser: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() image: ProductQuickLink;

    imageLocation: string = environment.ImageLocation;

    ngAfterViewChecked(): void {
        this.maximizedImageFocuser.emit(true);
    }

    hideMaximizedImage(): void {
        this.imageMaximized.emit(false);
    }

    encode(uriPart: string) {
        return encodeURIComponent(uriPart);
    }
}
