import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { MobileFooterComponent } from './mobile-footer/mobile-footer.component';

@NgModule({
    declarations: [FooterComponent, MobileFooterComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        RouterModule
    ],
    exports: [
        FooterComponent,
        MobileFooterComponent
    ]
})
export class FootersModule {}
