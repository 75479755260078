<div>
    <div class="header_bar">
        <a [routerLink]="['/' + languageCode, 'page', 'home']" [queryParams]="{ dt: 1 }" class="headerHomeImage">
            <img *ngIf="info" src="{{imageLocation}}{{info.HeaderImageLink}}" alt="Home"/>
        </a>
        <div class="headerMenuContainer">
            <div class="headerUserNav">
                <div *ngIf="currentUser && info">
                    <span>{{info.UserWelcomeText}}&nbsp;<a [routerLink]="['/' + languageCode, 'page', info.UserPortalPageId]" [queryParams]="{ dt: 1 }" class="headerUserPortalLink">{{currentUser.Name}}</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp;<span (click)="signOutUser()" class="headerSignOutLink">{{info.SignOutText}}</span>
                    </span>
                    <div class="headerUserAccount">
                        <span *ngIf="currentAccount">
                            <a [routerLink]="['/' + languageCode, 'page', customerAccountPage]" [queryParams]="{ dt: 1 }" class="headerCustomerAccountLink">{{currentAccount.Id}}</a>&nbsp;&nbsp;|&nbsp;&nbsp;{{currentAccount.Name}}&nbsp;&nbsp;|&nbsp;&nbsp;{{currentAccount.Location}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="headerMenuNav">
                <ul>
                    <li *ngFor="let item of menuItems">
                        <a [routerLink]="['/' + languageCode, item.Type.toLowerCase(), item.Id]" [queryParams]="{ dt: 1 }" class="headerMenuMainItem">{{item.Title}}</a>
                        <ul>
                            <li *ngFor="let subItem of item.Items">
                                <a [routerLink]="['/' + languageCode, subItem.Type.toLowerCase(), subItem.Id]" [queryParams]="{ dt: 1 }">{{subItem.Title}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
