import { Component, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Router, Event, NavigationEnd, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET } from '@angular/router';

import { environment } from '@environment';
import { Menu } from '@models/menu';
import { Language } from '@models/language';
import { ToolbarService } from '@services/toolbar.service';
import { GlobalService } from '@services/global.service';
import { UserService } from '@services/user.service';
import { Toolbar } from '@models/toolbar';
import { GeneralFeature } from '@models/general-feature';
import { BaseComponent } from 'src/app/base/base.component';

export class MarqueeMessage {
    Title: string;
    Content: string;
    LinkTarget: string;
}

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.less'],
})
export class ToolbarComponent extends BaseComponent implements OnInit, OnDestroy {
    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;

    menuItems: Menu[];
    languages: Language[];
    searchTitle: string;
    searchCriteria: string;
    languageCode: string;

    marqueeBackground: string;
    marqueeColor: string;

    marqueeMessages: MarqueeMessage[] = [];

    // private _routeSubscription: Subscription;

    get router(): Router {
        return this._router;
    }

    constructor(
        private toolbarService: ToolbarService,
        private globalService: GlobalService,
        private _router: Router,
        private userService: UserService,
        private _elementRef: ElementRef,
        private _renderer: Renderer2
    ) {
        super();

        this.subscriptions.push(globalService.languageId$.subscribe(() =>
            this.ngOnInit()
        ));

        this.subscriptions.push(this.userService.currentUser$.subscribe(() =>
            this.ngOnInit()
        ));
    }

    ngOnInit() {
        this.languageCode = this.globalService.getLanguageCode();

        this.toolbarService.getToolbar().subscribe({
            next: (toolbar: Toolbar) => {
                this.initializeToolbar(toolbar);
            },
            error: (error: any) => {
                this.handleError(error);
            }
        });

        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const tree: UrlTree = this._router.parseUrl(event.url);

                const group: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
                const id: string = group.segments[2].path;
                this.getMarquee(id);
            }
        });
    }

    ngOnDestroy() {
        // if (this.languageSubscription) {
        //     this.languageSubscription.unsubscribe();
        // }
        // if (this.userSubscription) {
        //     this.userSubscription.unsubscribe();
        // }
        // if (this._routeSubscription) {
        //     this._routeSubscription.unsubscribe();
        // }
    }

    private getMarquee(id: string): void {
        this.globalService
            .getGeneralFeatures(id, 'SysTicker')
            .subscribe((features: GeneralFeature[]) => {
                if (features && features.length > 0) {
                    this.marqueeColor = features[0].Color;

                    this.marqueeMessages = [];
                    features.forEach((feature: GeneralFeature) => {
                        const message: MarqueeMessage = new MarqueeMessage();
                        message.Title = feature.Title;
                        message.Content = feature.Description;
                        if (feature.Target !== '') {
                            if (feature.TargetType === 'URL') {
                                message.LinkTarget = feature.Target;
                            } else {
                                message.LinkTarget = `/${this.languageCode}/${feature.TargetType}/${feature.Target}&dt=1`;
                            }
                        }
                        this.marqueeMessages.push(message);
                    });

                    // const marquee = this._elementRef.nativeElement.querySelector(`ng-marquee`);
                    // if (marquee) {
                    //     this._renderer.setStyle(marquee, 'hidden', 'false');
                    // }
                    // const marqueeDiv = this._elementRef.nativeElement.querySelector(`.ng-marquee`);
                    // if (marqueeDiv) {
                    //     this._renderer.setStyle(marqueeDiv, 'height', '33px');
                    //     this._renderer.setStyle(marqueeDiv, 'hidden', 'false');
                    //     this._renderer.setStyle(marqueeDiv, 'color', this.marqueeColor);
                    //     this._renderer.setStyle(marqueeDiv, 'background-color', this.marqueeColor);
                    // }
                } else {
                    // const marqueeDiv = this._elementRef.nativeElement.querySelector(`.ng-marquee`);
                    // if (marqueeDiv) {
                    //     this._renderer.setStyle(marqueeDiv, 'hidden', 'true');
                    // }
                }
            });
    }

    private initializeToolbar(toolbar: Toolbar): void {
        if (toolbar) {
            this.menuItems = toolbar.Menu;
            this.languages = toolbar.Languages;
            this.searchTitle = toolbar.SearchTitle;
        }
    }

    onSelectLanguage(newLanguage: Language): void {
        this.globalService.setLanguage(newLanguage.Id);
    }

    search(): void {
        if (this.searchCriteria !== '') {
            this.router.navigate([this.languageCode, 'search', this.searchCriteria],{ queryParams: { dt: 1 } });
        }
    }
}
