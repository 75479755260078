import { Component, Input, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import {
    trigger,
    state,
    style,
    transition,
    animate
} from "@angular/animations";

import { CarouselItem } from "@models/carousel-item";
import { environment } from "@environment";
import { GlobalService } from "@services/global.service";

@Component({
    selector: "carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.less"],
    animations: [
        trigger("imageState", [
            state("fadeOut", style({ opacity: 0 })),
            state("fadeIn", style({ opacity: 1 })),
            transition("* => fadeOut", [style({ opacity: 1}), animate("200ms", style({opacity: 0}))]),
            transition("* => fadeIn", [style({opacity: 0}), animate("200ms 210ms", style({opacity: 1}))])
        ])
    ]
})
export class CarouselComponent implements AfterViewChecked {
    @Input() images: CarouselItem[] = [];
    @Input() interval: number = 2000;
    @Input() autoPlay: boolean = true;

    private currentImage: number = 0;
    private isPlaying: boolean = false;
    private imageLocation: string = environment.ImageLocation;
    private timerIntervalId: number;

    languageCode: string;

    constructor(private cdr: ChangeDetectorRef, private globalService: GlobalService) {
        this.languageCode = this.globalService.getLanguageCode();
    }

    ngAfterViewChecked(): void {
        if (this.autoPlay && !this.isPlaying) {
            this.currentImage = 0;
            this.showImage(this.currentImage, "fadeIn");
            this.cdr.detectChanges();
            this.updateImageShown();

            this.timerIntervalId = window.setInterval(this.nextImage.bind(this), this.interval);
            this.isPlaying = true;
        }
    }

    private nextImage(): void {
        let nextImage: number = this.currentImage + 1;
        if (nextImage >= this.images.length) {
            nextImage = 0;
        }
        this.setImage(nextImage);
    }

    private hideImage(imageIndex: number, transitionState: string): void {
        if (this.images.length > 0) {
            this.images[imageIndex].transition = transitionState;
        }
    }

    private showImage(imageIndex: number, transitionState: string): void {
        if (this.images.length > 0) {
            this.images[imageIndex].transition = transitionState;
        }
    }

    private selectImage(imageIndex: number): void {
        clearInterval(this.timerIntervalId);
        this.timerIntervalId = window.setInterval(this.nextImage.bind(this), this.interval);
        this.setImage(imageIndex);
    }

    private setImage(imageIndex: number): void {
        this.hideImage(this.currentImage, "fadeOut");
        this.currentImage = imageIndex;
        this.showImage(this.currentImage, "fadeIn");
        this.updateImageShown();
    }

    private updateImageShown(): void {
        setTimeout(() => {
            this.images[this.currentImage].active = true;
            this.images.forEach((image) => {
                if (image !== this.images[this.currentImage]) {
                    image.active = false;
                }
            });
        }, 200);
    }

    pauseAnimation(pause: boolean): void {
        if (pause) {
            clearInterval(this.timerIntervalId);
        } else {
            this.timerIntervalId = window.setInterval(this.nextImage.bind(this), this.interval);
        }
    }
}
