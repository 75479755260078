import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { environment } from '@environment';
import { SharedHeader } from '@models/shared-header';
import { MobileHeaderInfo } from '@models/mobile-header-info';
import { GlobalService } from '@services/global.service';
import { HeaderService } from '@services/header.service';
import { UserService } from '@services/user.service';
import { SharedService } from '@services/shared.service';

@Component({
    selector: 'm-mobile-base',
    template: '',
})
export class MobileBaseComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private headerSubscription: Subscription;
    protected routeSubscription: Subscription;
    protected routeParamSubscription: Subscription;
    protected detailsSubscription: Subscription;

    languageCode: string;
    headerInfo: MobileHeaderInfo;
    header: SharedHeader;
    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;

    @ViewChild('mainMenu') mainMenu: MatSidenav;

    menusClosed: boolean = true;

    constructor(
        protected headerService: HeaderService,
        protected globalService: GlobalService,
        protected userService: UserService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected sharedService: SharedService
    ) {
        this.languageSubscription = this.globalService.languageId$.subscribe(
            () => {
                this.closeMenu();
                this.ngOnInit();
                this.refreshData();
            }
        );

        this.router.events.subscribe(() => {
            this.closeMenu();
        });
    }

    ngOnInit() {
        this.routeSubscription = this.route.params.subscribe(
            (params: Params) => {
                this.sharedService
                    .getMobileHeader(params['id'])
                    .subscribe((header: SharedHeader) =>
                        this.setupSharedHeader(header)
                    );
            }
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe(this.languageSubscription);
        this.unsubscribe(this.routeSubscription);
        this.unsubscribe(this.headerSubscription);
        this.unsubscribe(this.detailsSubscription);
        this.unsubscribe(this.routeParamSubscription);
    }

    protected unsubscribe(subscription: Subscription): void {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    protected refreshData(): void {}

    protected setupHeaders(): void {
        this.headerSubscription = this.headerService.getHeaderInfo().subscribe({
            next: (info: MobileHeaderInfo) => {
                this.headerInfo = info;
            },
            error: (error: any) => {
                this.handleError(error);
            }
    });

        this.languageCode = this.globalService.getLanguageCode();
        this.globalService.addMobileLinkTags(this.route.snapshot.url.slice(1));
    }

    protected setupSharedHeader(header: SharedHeader) {
        this.header = header;

        this.globalService.setTitle(`USNR - ${this.header.Title}`);
    }

    toggleMenu(): void {
        if (this.mainMenu.opened) {
            this.mainMenu.close();
        } else {
            this.mainMenu.open();
        }
    }

    closeMenu(): void {
        this.mainMenu.close();
    }

    protected handleError(error: any): void {
        console.error('An error occurred', error);
    }
}
