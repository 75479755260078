import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { JobResolverService } from "./job-resolver.service";
import { IsSecureGuard } from "../is-secure.guard";
import { JobComponent } from "./job/job.component";

const routes: Routes = [
    { path: ":languageId/job/:id", component: JobComponent, resolve: { details: JobResolverService }, canActivate: [ IsSecureGuard ]  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class JobRoutingModule { }
