import { Component, OnInit } from "@angular/core";

import { ProductDetail } from "@models/product-detail";
import { environment } from "@environment";

@Component({
    selector: "product-children",
    templateUrl: "./product-children.component.html",
    styleUrls: ["./product-children.component.less"]
})
export class ProductChildrenComponent {
    imageLocation: string = environment.ImageLocation;
    childRows: ProductDetail[][];

    languageCode: string;

    setChildren(children: ProductDetail[]): void {
        const rowCount: number = Math.trunc((children.length + 3) / 4);

        if (this.childRows !== undefined) {
            this.childRows.length = 0;
        }

        this.childRows = [];
        for (let i: number = 0; i < rowCount; ++i) {
            for (let j = 0; j < 4; ++j) {
                if ((i * 4) + j >= children.length) {
                    break;
                }

                if (this.childRows[i] === undefined) {
                    this.childRows[i] = [];
                }

                this.childRows[i][j] = children[(i * 4) + j];
            }
        }
    }
}
