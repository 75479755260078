import { Component } from '@angular/core';

@Component({
    selector: 'page-manual-link',
    templateUrl: './page-manual-link.component.html',
    styleUrls: ['./page-manual-link.component.less'],
})
export class PageManualLinkComponent {
    title: string;
    description: string;
    other: string;
}
