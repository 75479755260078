import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { PageService } from '@services/page.service';
import { GlobalService } from '@services/global.service';
import { UserService } from '@services/user.service';
import { PageDetail } from '@models/page-detail';
import { firstValueFrom } from 'rxjs';
import { PageSummary } from '@models/page-summary';

@Injectable()
export class PageResolverService  {
    constructor(
        private pageService: PageService,
        private globalService: GlobalService,
        private router: Router,
        private userService: UserService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<PageDetail[]> {
        // this.adalService.handleWindowCallback();

        const pageId: string = route.params['id'] as string;
        if (pageId === null) {
            this.router.navigate(
                [this.globalService.getLanguageCode(), 'page', 'error'],
                { queryParams: { returnUrl: state.url } }
            );
        }

        let languageId = route.paramMap.get('languageId');
        if (languageId && languageId !== '') {
            this.globalService.setLanguage(languageId);
        } else {
            languageId = this.globalService.getLanguage();
            if (!languageId) {
                this.globalService.setLanguage('en-us');
            }
        }
        return this.resolvePage(pageId, state);
    }

    async resolvePage(
        pageId: string,
        state: RouterStateSnapshot
    ): Promise<PageDetail[]> {
        if (state.url.startsWith('/cp/i')) {
            this.router.navigate([this.globalService.getLanguageCode(), 'page', 'cpItemDetails'], { queryParams: { ItemId: pageId }});
            return;
        }
        return await firstValueFrom(this.pageService
            .getDetails(pageId))
            .then((summary: PageSummary) => {
                const languageCode: string = this.globalService.getLanguageCode();

                if (summary.LoginRequired) {
                    if (summary.Details && summary.Details.length > 0) {
                        return summary.Details;
                    } else {
                        sessionStorage.setItem('returnUrl', state.url);
                        this.router.navigate([languageCode, 'page', 'login'])
                    }
                } else {
                    if (summary.Details && summary.Details.length > 0) {
                        return summary.Details;
                    } else {
                        this.router.navigate([languageCode, 'page', 'error']);
                    }
                }
            });
    }
}
