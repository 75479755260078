import { Component } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "m-content-feature",
    templateUrl: "./content-feature.component.html",
    styleUrls: ["./content-feature.component.less"]
})
export class ContentFeatureComponent {
    title: string;
    description: string;

    constructor(private sanitizer: DomSanitizer) {}

    get _description(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.description);
    }
}
