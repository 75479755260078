<img *ngIf="busy" src="{{_localImageLocation}}ajax-loader.gif" class="form-busy" alt="loading">
<div *ngIf="showAccounts">
    <div *ngIf="accounts.Searchable" class="accountSearch">
        <input type="text" [(ngModel)]="searchCriteria" [placeholder]="searchHelpText" style="width: 200px;" (keyup.enter)="searchAccounts()" />&nbsp;&nbsp;<button (click)="searchAccounts()">{{searchButtonTitle}}</button>
    </div>
    <div>
        <table id="accountTable">
            <tr *ngFor="let account of pagedAccounts[currentPage]; let odd=odd;" [style.background-color]="odd ? '#eeeeee' : '#ffffff'">
                <td><a href="javascript:void(0)" (click)="accountSelected(account)">{{account.Id}}</a></td>
                <td>{{account.Name}}</td>
                <td>{{account.Location}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="maxPages > 1">
        <div class="pageChanges">
            <img src="{{_localImageLocation}}FirstRecord.png" (click)="setPage(0)" alt="first" />
            <img src="{{_localImageLocation}}PreviousRecord.png" (click)="incrementPage(-1)" alt="previous" />
            <span>{{currentPage + 1}}&nbsp;/&nbsp;{{maxPages}}&nbsp;&nbsp;</span>
            <img src="{{_localImageLocation}}NextRecord.png" (click)="incrementPage(1)" alt="next" />
            <img src="{{_localImageLocation}}LastRecord.png" (click)="setPage(maxPages - 1)" alt="last" />
        </div>
    </div>
</div>
