import { Component } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "content-description-heading",
    templateUrl: "./content-description-heading.component.html",
    styleUrls: ["./content-description-heading.component.less"]
})
export class ContentDescriptionHeadingComponent {
    heading: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {}

    setHeading(heading: string): void {
        this.heading = this.sanitizer.bypassSecurityTrustHtml(heading);
    }
}
