import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Params } from '@angular/router';

import { environment } from '@environment';
import { MenuItem } from '@models/menu-item';
import { MobileHeaderInfo } from '@models/mobile-header-info';
import { SharedHeader } from '@models/shared-header';
import { GlobalService } from '@services/global.service';
import { HeaderService } from '@services/header.service';
import { SharedService } from '@services/shared.service';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
    selector: 'mobile-shared-header',
    templateUrl: './mobile-shared-header.component.html',
    styleUrls: ['./mobile-shared-header.component.less'],
})
export class MobileSharedHeaderComponent extends BaseComponent implements OnInit {
    imageLocation: string = environment.ImageLocation;
    languageCode: string;
    hasChildren: boolean = false;
    titleMatch: boolean = false;

    header: SharedHeader;
    headerInfo: MobileHeaderInfo;

    public get childrenOpen(): boolean {
        return this.childrenPanel?.expanded;
    }

    title: string;
    imageLink: string;
    @Input() menu: MenuItem[];

    @ViewChild("childrenPanel") childrenPanel: MatExpansionPanel;

    constructor(
        private globalService: GlobalService,
        private route: ActivatedRoute,
        private sharedService: SharedService,
        private headerService: HeaderService
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.push(this.route.params.subscribe(
            (params: Params) => {
                this.setupHeader(params['id']);
                this.globalService.addLanguageTags(this.route.snapshot.url.slice(1));
            })
        );
    }

    private setupHeader(id: string): void {
        this.languageCode = this.globalService.getLanguageCode();

        this.sharedService.getMobileHeader(id).subscribe((header: SharedHeader) => {
            this.header = header;
            this.globalService.setTitle(`USNR - ${this.header.Title}`);

            this.imageLink = this.header.ImageLink;
            this.title = this.header.Title;
        });

        this.subscriptions.push(this.headerService.getHeaderInfo().subscribe({
            next: (info: MobileHeaderInfo) => {
                this.headerInfo = info;
            },
            error: (error: any) => { this.handleError(error); }
        }));
    }

    expanded() {
        if (this.menu) {
            for (const item of this.menu) {
                if (item.Level === 2) {
                    if (item.Title === this.title) {
                        this.titleMatch = true;
                    }
                    this.hasChildren = true;
                    return true;
                }
            }
        }
    }
}
