export const environment = {
    production: true,

    // ApiBase: "https://www.usnr.com/Usnr.API/usnrapi/",
    ApiBase: "./Usnr.API/usnrapi/",

    ImageLocation: "assets/images/",
    LocalImageLocation: "assets/local-images/",

    DocumentLocation: "assets/documents/",
};
