<div class="formDetailContainer">
    <dynamic-form
        [formId]="formId"
        [pageId]="pageId"
        [fields]="fields"
        [returnUrl]="returnUrl"
        [dataQueryId]="dataQueryId"
        [autoRunDataQuery]="autoRunDataQuery"
        [isLoginForm]="isLoginForm"
        [isAuthenticationForm]="isAuthenticationForm"
    >
    </dynamic-form>
</div>

