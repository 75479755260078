<div style="clear: both;"></div>
<!-- <div style="min-height: 1000px;">
    <iframe name="resumator-job-frame"
        #resumator_job_frame
        id="resumator-job-frame"
        class="resumator-advanced-widget"
        src="//usnr.applytojob.com/apply/jobs/"
        width="100%"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        >
    </iframe>
</div> -->
<!-- <div (click)="navigateToJobs()">View all jobs</div> -->

<!-- <a class="jobsLink" href="javascript:void(0)" (click)="navigateToJobs()">
    <div><span style="padding-right: 3px;">View all jobs</span></div>
</a>
<div style="clear:both"></div> -->

<!-- <script type="text/javascript" src="https://app.jazz.co/widgets/basic/create/usnr" charset="utf-8"></script> -->
<!-- <ng-container *ngFor="let job of listings">
    <p *ngIf="job.IsHeading" class="listingHeading">
        <span class="location" *ngIf="job.ContentId != ''" ><a [routerLink]="['/' + languageCode, 'content', job.ContentId]">{{job.Title}}</a></span>
        <span *ngIf="job.ContentId == ''">{{job.Title}}</span>
    </p>
    <div *ngIf="!job.IsHeading" class="listingMain">
        <a [routerLink]="['/' + languageCode, 'job', job.Id]">
            <img src="{{localImageLocation}}Arrows_12x16_CCC.png" alt=""/>
            <div class="listingItem">{{job.Title}}</div>
        </a>
        <p style="padding-bottom: 5px;">
            <span *ngIf="job.Description && job.Description !== ''">&nbsp;-&nbsp;{{getDescription(job.Description)}}</span>
            <span *ngIf="!job.Description || job.Description === ''">&nbsp;</span>
        </p>
    </div>
    <div style="clear: both;"></div>
</ng-container> -->
