<div class="testimonialBoxShadow">
    <div class="testimonialBoxContainer">
        <div class="testimonialBoxImageDiv">
            <img src="{{imageLocation}}{{detail.MediaFileName}}" alt="{{detail.Other}}" width="104px;"/>
        </div>
        <div class="testimonialQuoteContainer">
            <div class="testimonialQuoteSymbol"></div>
            <div class="testimonialQuote">
                <span *ngIf="detail.Description !== ''"><i>{{detail.Description}}</i><br /></span>
                <div class="testimonialQuoteSource">
                    <span *ngIf="detail.Other !== ''">{{detail.Other}}<br /></span>
                    <span *ngIf="detail.Title !== ''">{{detail.Title}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
