import {
    Component,
    OnDestroy,
    OnChanges,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    SimpleChanges,
    ComponentFactory,
    ComponentRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { JobDetail } from "@models/job-detail";
import { GlobalService } from "@services/global.service";
import { JobService } from "@services/job.service";
import { JobSectionHeadingComponent } from "../job-section-heading/job-section-heading.component";
import { JobDescriptionComponent } from "../job-description/job-description.component";
import { JobInstructionComponent } from "../job-instruction/job-instruction.component";
import { JobDetailListComponent } from "../job-detail-list/job-detail-list.component";
import { JobLocationComponent } from "../job-location/job-location.component";

@Component({
    selector: "job-detail",
    templateUrl: "./job-detail.component.html",
    styleUrls: ["./job-detail.component.less"]
})
export class JobDetailComponent implements OnDestroy, OnChanges {
    private subscription: Subscription;
    @Input() details: JobDetail[];

    constructor(
        private globalService: GlobalService,
        private jobService: JobService,
        private route: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {
        this.subscription = globalService.languageId$.subscribe(
            () => { this.ngOnChanges(null); }
        );
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.processDetails();
    }

    private processDetails(): void {
        this.viewContainerRef.clear();
        const languageCode: string = this.globalService.getLanguageCode();
        if (this.details !== undefined) {
            for (const detail of this.details) {
                if (detail.IsHeading) {
                    this.showHeading(detail.Text);
                } else {
                    switch (detail.DetailType) {
                    case "Description":
                        this.showDescription(detail.Text);
                        break;
                    case "Instruction":
                        this.showInstruction(detail.Text);
                        break;
                    case "Job Title":
                        break;
                    case "Qualification":
                    case "Responsibility":
                        this.showDetailList(detail.Text);
                        break;
                    case "Location":
                        this.showLocation(detail.ContentId, detail.Text, languageCode);
                        break;
                    }
                }
            }
        }
    }

    private showHeading(text: string): void {
        const factory: ComponentFactory<JobSectionHeadingComponent> =
            this.componentFactoryResolver.resolveComponentFactory(JobSectionHeadingComponent);
        const ref: ComponentRef<JobSectionHeadingComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setText(text);
        ref.changeDetectorRef.detectChanges();
    }

    private showDescription(description: string): void {
        const factory: ComponentFactory<JobDescriptionComponent> =
            this.componentFactoryResolver.resolveComponentFactory(JobDescriptionComponent);
        const ref: ComponentRef<JobDescriptionComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setDescription(description);
        ref.changeDetectorRef.detectChanges();
    }

    private showInstruction(text: string): void {
        const factory: ComponentFactory<JobInstructionComponent> =
            this.componentFactoryResolver.resolveComponentFactory(JobInstructionComponent);
        const ref: ComponentRef<JobInstructionComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setText(text);
        ref.changeDetectorRef.detectChanges();
    }

    private showDetailList(text: string): void {
        const factory: ComponentFactory<JobDetailListComponent> =
            this.componentFactoryResolver.resolveComponentFactory(JobDetailListComponent);
        const ref: ComponentRef<JobDetailListComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setText(text);
        ref.changeDetectorRef.detectChanges();
    }

    private showLocation(title: string, text: string, languageCode: string): void {
        const factory: ComponentFactory<JobLocationComponent> =
            this.componentFactoryResolver.resolveComponentFactory(JobLocationComponent);
        const ref: ComponentRef<JobLocationComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.title = title;
        ref.instance.text = text;
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }
}
