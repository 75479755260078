import { Component, OnInit, Input, Output, HostListener, EventEmitter } from "@angular/core";

import { ModalService } from "@services/modal.service";

@Component({
    selector: "modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.less"]
})
export class ModalComponent implements OnInit {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() blocking = false;

    @Output() closed: EventEmitter<null> = new EventEmitter<null>();
    isOpen = false;

    @HostListener("keyup") onMouseEnter(event) {
        this.keyup(event);
    }

    constructor(private modalService: ModalService) {}

    ngOnInit() {
      this.modalService.registerModal(this);
    }

    close(checkBlocking = false): void {
        this.modalService.close(this.modalId, checkBlocking);
        if (!this.isOpen) {
            this.closed.emit();
        }
    }

    private keyup(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.modalService.close(this.modalId, true);
        }
    }
}
