import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlSegment } from '@angular/router';
import { Subject, Observable, lastValueFrom, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { environment } from '@environment';
import { LinkService } from './link.service';
import { Language } from '@models/language';
import { MainMenuItem } from '@models/main-menu-item';
import { GeneralFeature } from '@models/general-feature';
import { StandardFeature } from '@models/standard-feature';
import { UsnrDomain } from '@models/usnr-domain';
import { MobileLanguages } from '@models/mobile-languages';
import { MobileMasterMenu } from '@models/mobile-master-menu';
import { PrivacyMessageData } from '@shared/privacy-alert/privacy-alert-dialog/privacy-alert-dialog.component';
import { CookieService } from 'ngx-cookie-service';

declare global {
    interface Window { cdAnalytics: any }
}

declare var clickdimensions: any;
declare var cdScore: any;

@Injectable()
export class GlobalService {
    static PRIVACY_ALERT: string = 'privacyAlert';
    private baseUrl: string = environment.ApiBase + 'master/';

    private languageList: Language[];

    private usnrLanguage: Subject<string> = new Subject<string>();
    languageId$: Observable<string> = this.usnrLanguage.asObservable();

    constructor(
        private http: HttpClient,
        private linkService: LinkService,
        private titleService: Title,
        private _cookieService: CookieService
    ) {}

    setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    addLanguageTags(segments: UrlSegment[]): void {
        if (environment.production) {
            let urlPart: string = '';
            segments.forEach((s: UrlSegment) => {
                urlPart += '/' + s;
            });

            this.getLanguages().subscribe((languages: Language[]) => {
                this.linkService.removeTag('rel=alternate');
                languages.forEach((l: Language) => {
                    this.linkService.addTag({ rel: 'alternate', hreflang: l.ISOCode, href: `https://www.usnr.com/${l.ISOCode}${urlPart}` });
                });
            });
        }
    }

    addMobileLinkTags(segments: UrlSegment[]): void {
        if (environment.production) {
            let urlPart: string = '';
            segments.forEach((s: UrlSegment) => {
                urlPart += '/' + s;
            });

            this.linkService.removeTag('rel="canonical"');
            this.linkService.addTag({ rel: 'canonical', href: `https://www.usnr.com/en${urlPart}` });

            this.getMobileLanguages().subscribe((language: MobileLanguages) => {
                this.linkService.removeTag('rel="alternate"');
                language.Languages.forEach((l: Language) => {
                    this.linkService.addTag({ rel: 'alternate', hreflang: l.ISOCode, href: `https://m.usnr.com/${l.ISOCode}${urlPart}` });
                });
            });
        }
    }

    setLanguage(newLanguage: string) {
        return this.getLanguages().subscribe((l: Language[]) => {
            this.languageList = l;
            const language: Language = this.getLanguageFromList(newLanguage);

            const currentLanguageId: string = this.getLanguage();
            if (language.Id !== currentLanguageId) {
                localStorage.setItem('currentLanguageId', language.Id);
                localStorage.setItem('currentLanguageCode', language.ISOCode);
                this.usnrLanguage.next(language.Id);
            }
        });
    }

    getLanguageFromList(languageCode: string): Language {
        const languages: Language[] = this.languageList.filter((l: Language) => l.Id === languageCode || l.ISOCode === languageCode);
        if (languages && languages.length > 0) {
            return languages[0];
        }

        return null;
    }

    getLanguage(): string {
        const language = localStorage.getItem('currentLanguageId');
        return language === null || language === undefined ? 'en-us' : language;
    }

    getLanguageCode(): string {
        const language = localStorage.getItem('currentLanguageCode');
        return language === null || language === undefined ? 'en' : language;
    }

    getLanguages(): Observable<Language[]> {
        return this.http.get<Language[]>(this.baseUrl + 'languages', { params: this.createParams() });
    }

    getMobileLanguages(): Observable<MobileLanguages> {
        return this.http.get<MobileLanguages>(this.baseUrl + 'languages/mobile', { params: this.createParams() });
    }

    getDetailSectionSize(): Observable<number> {
        return this.http.get<number>(this.baseUrl + 'detailSectionSize');
    }

    getDomain(domain: string): Observable<UsnrDomain> {
        domain = domain.replace('https', '').replace('http', '').replace('://', '').replace('www.', '');

        const params: HttpParams = new HttpParams({
            fromObject: {
                id: domain,
            },
        });

        return this.http.get<UsnrDomain>(this.baseUrl + 'domain', { params: params });
    }

    async getDomainList(): Promise<UsnrDomain[]> {
        return await firstValueFrom(this.http.get<UsnrDomain[]>(this.baseUrl + 'domains'));
    }

    async isInternalDomain(emailAddress: string): Promise<boolean> {
        let domains: UsnrDomain[] = await this.getDomainList();

        emailAddress = emailAddress.toLowerCase();
        const domainList: UsnrDomain[] = domains.filter(
            (domain: UsnrDomain) => {
                if (emailAddress.endsWith('@' + domain.Id.toLowerCase())) {
                    return domain;
                }
                return null;
            }
        );

        return domainList.length > 0;
    }

    getMainMenuItems(): Observable<MainMenuItem[]> {
        return this.http.get<MainMenuItem[]>(this.baseUrl + 'menu', { params: this.createParams() });
    }

    getMobileMainMenu(): Observable<MobileMasterMenu> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.getLanguage() } });
        return this.http.get<MobileMasterMenu>(this.baseUrl + 'menu/mobile', { params: params });
    }

    getStandardFeatures(id: string): Observable<StandardFeature[]> {
        if (!id || id === '') {
            return null;
        }

        return this.http.get<StandardFeature[]>(`${this.baseUrl}features/${id}/standard`, { params: this.createParams() }).pipe(
            map((response: any[]) => {
                if (response) {
                    return response.map((data) => new StandardFeature(data));
                } else {
                    return null;
                }
            })
        );
    }

    getGeneralFeatures(id: string, type: string): Observable<GeneralFeature[]> {
        if (!id || id === '') {
            return null;
        }

        const params: HttpParams = new HttpParams({
            fromObject: {
                language: this.getLanguage(),
                type: type,
            },
        });

        const url: string = this.baseUrl + 'features/' + id + '/general';

        return this.http.get<GeneralFeature[]>(url, { params: params }).pipe(
            map((response: any[]) => {
                if (response) {
                    return response.map((data) => new GeneralFeature(data));
                } else {
                    return null;
                }
            })
        );
    }

    async getPrivacyMessageData(): Promise<PrivacyMessageData> {
        const url: string = this.baseUrl + 'features/privacy';
        return lastValueFrom(this.http.get<PrivacyMessageData>(url, { params: this.createParams() }));
    }

    setupClickDimensions(): void {
        window.cdAnalytics = new clickdimensions.Analytics('analytics.clickdimensions.com');
        window.cdAnalytics.setAccountKey('aT4p1R2WGAEeq1zs5bJqDq');
        window.cdAnalytics.setDomain('usnr.com');
        window.cdAnalytics.setScore(typeof(cdScore) == 'undefined' ? 0 : (cdScore == 0 ? null : cdScore));
        window.cdAnalytics.trackPage();
    }

    cookiesEnabled(): boolean {
        return this._cookieService.get(GlobalService.PRIVACY_ALERT).toLowerCase() === 'y';
    }

    enableCookies(): void {
        this._cookieService.set(GlobalService.PRIVACY_ALERT, 'y',
            new Date().getDate() + 365, '/', null, null, 'Strict');
    }

    private createParams() {
        return new HttpParams({ fromObject: { language: this.getLanguage() } });
    }
}
