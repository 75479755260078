import { Component } from "@angular/core";

import { ProductDetailGroup } from "@models/product-detail-group";

@Component({
    selector: "product-detail-tab-container",
    templateUrl: "./product-detail-tab-container.component.html",
    styleUrls: ["./product-detail-tab-container.component.less"]
})
export class ProductDetailTabContainerComponent {
    tab: ProductDetailGroup;
    productId: string;
    isShowing: string = "none";

    show(): void {
        this.isShowing = "block";
    }

    hide(): void {
        this.isShowing = "none";
    }
}
