import {
    Component,
    OnInit,
    ComponentFactoryResolver,
    ViewContainerRef,
    ElementRef,
    AfterViewInit,
    ComponentFactory,
    ComponentRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { ProductDetailGroup } from "@models/product-detail-group";
import { ProductDetail } from "@models/product-detail";
import { ProductDetailTabContainerComponent } from "../product-detail-tab-container/product-detail-tab-container.component";

@Component({
    selector: "product-tab-headers",
    templateUrl: "./product-tab-headers.component.html",
    styleUrls: ["./product-tab-headers.component.less"]
})
export class ProductTabHeadersComponent implements OnInit, AfterViewInit {
    tabs: ProductDetailGroup[] = [];
    details: ProductDetail[] = [];
    tabComponents: ProductDetailTabContainerComponent[] = [];
    activeTabId: string;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.tabComponents = [];
        for (const tab of this.tabs) {
            this.createTab(tab);
        }

        if (this.tabs.length > 0) {
            this.activeTabId = this.tabs[0].Id;
            this.tabComponents[0].show();
        }
    }

    ngAfterViewInit(): void {
        // enableClickDimensions();
        // // const trackingScript = document.createElement("script");
        // // trackingScript.type = "text/javascript";
        // // trackingScript.innerText =
        // //     "alert('click dimensions called'); " +
        // //     "var cdAnalytics = new clickdimensions.Analytics('analytics.clickdimensions.com'); " +
        // //     "cdAnalytics.setAccountKey('aT4p1R2WGAEeq1zs5bJqDq'); " +
        // //     "cdAnalytics.setDomain('usnr.com'); " +
        // //     "cdAnalytics.setScore(typeof(cdScore) == 'undefined' ? 0 : (cdScore == 0 ? null : cdScore)); " +
        // //     "cdAnalytics.trackPage();";
        // // this.elementRef.nativeElement.appendChild(trackingScript);
    }

    private createTab(tab: ProductDetailGroup): void {
        const factory: ComponentFactory<ProductDetailTabContainerComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductDetailTabContainerComponent);
        const ref: ComponentRef<ProductDetailTabContainerComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.tab = tab;
        ref.instance.productId = this.route.snapshot.params["id"];

        this.tabComponents.push(ref.instance);
        ref.changeDetectorRef.detectChanges();
    }

    private showTab(id: string) {
        for (const component of this.tabComponents) {
            if (component.tab.Id === id) {
                component.show();
            } else {
                component.hide();
            }
        }
        this.activeTabId = id;
    }
}
