import {throwError as observableThrowError,  Subscription } from "rxjs";
import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

declare var enableClickDimensions: any;

import { GlobalService } from "@services/global.service";
import { ContentDetail } from "@models/content-detail";
import { ContentService } from "@services/content.service";

@Component({
    selector: "content",
    templateUrl: "./content.component.html",
    styleUrls: ["./content.component.less"]
})
export class ContentComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription: Subscription;
    private detailsSubscription: Subscription;

    details: ContentDetail[] = [];

    constructor(
        private globalService: GlobalService,
        private contentService: ContentService,
        private route: ActivatedRoute
    ) {
        this.subscription = this.globalService.languageId$.subscribe(
            () => { this.getDetails(); }
        );
    }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { details: ContentDetail[] }) => this.details = data.details);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.detailsSubscription) {
            this.detailsSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.globalService.cookiesEnabled()) {
            enableClickDimensions();
        }
    }

    private getDetails(): void {
        const pageId: string = this.route.snapshot.params["id"];
        if (pageId === null) {
            return null;
        }

        this.detailsSubscription = this.contentService.getDetails(pageId).subscribe(
            (details: ContentDetail[]) => { this.details = details; },
            (error: any) => { this.handleError(error); }
        );
    }

    private handleError(error: any): void {
        console.error("An error occurred", error);
        observableThrowError(error);
    }
}
