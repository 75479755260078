import { Component, Input, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { ProductQuickLink } from "@models/product-quick-link";
import { environment } from "@environment";

@Component({
    selector: "video-view",
    templateUrl: "./video-view.component.html",
    styleUrls: ["./video-view.component.less"]
})
export class VideoViewComponent implements AfterViewChecked {
    @Input() videos: ProductQuickLink[] = [];

    videoIndex: number = 0;
    localImageLocation: string = environment.LocalImageLocation;

    constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detach();
    }

    getVideo(): SafeResourceUrl {
        let url: string = "";
        if (this.videoIndex < 0 || this.videoIndex > this.videos.length) {
            url = "";
        } else if (this.videos && this.videos.length > 0) {
            url = this.videos[this.videoIndex].VideoUrl;
            if (url === null || url === "") {
                url = "";
            }
        }

        return  this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    private setVideo(increment: number): void {

        this.videoIndex += increment;
        if (this.videoIndex < 0) {
            this.videoIndex = 0;
        }

        if (this.videoIndex >= this.videos.length) {
            this.videoIndex = this.videos.length - 1;
        }

        this.changeDetectorRef.detectChanges();
    }

    private selectVideo(index: number): void {
        this.videoIndex = index;
        this.changeDetectorRef.detectChanges();
    }
}
