import { DataQueryDetail } from "./data-query-detail";
import { DataQueryResultRow } from "./data-query-result-row";
import { TreeNode } from "./tree-node";

export class DataQueryResult {
    Id: string;
    DataSetIndex: number;
    OutputType: string;
    Heading: string;
    ListType: string;
    HeaderRow: boolean;
    TotalRow: boolean;
    BandedRows: boolean;
    FirstColumn: boolean;
    LastColumn: boolean;
    BandedColumns: boolean;
    NoResultsText: string;
    ShowHeaderWhenNoResults: boolean;
    ShowNoResultsMessage: boolean;
    FilestreamTypeId: string;
    FilestreamType: string;

    Details: DataQueryDetail[];
    QueryResults: DataQueryResultRow[];
    QueryResultsTree: TreeNode;
}

export class DataQueryOutputRow {
    columns: DataQueryOutputColumn[];
}

export class DataQueryOutputColumn {
    alignment: string;
    value: any;
    linkTarget: string;
    destinationRoute: string;
    destinationRouteParams: any;
    isModal: boolean = false;
    specialType: string;
    imageWidth: number;
    imageHeight: number;
    width: number;
    name: string;
}

export class DataQueryOutputLinkTarget {
    route: string;
    params: any;
    streamType: string;
    isModal: boolean;
}
