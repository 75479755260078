
import {throwError as observableThrowError } from "rxjs";
import { Component, OnInit } from "@angular/core";

import { environment } from "@environment";
import { JobListing } from "@models/job-listing";
import { JobService } from "@services/job.service";

@Component({
  selector: "content-job-listing",
  templateUrl: "./content-job-listing.component.html",
  styleUrls: ["./content-job-listing.component.less"]
})
export class ContentJobListingComponent implements OnInit {

  localImageLocation: string = environment.LocalImageLocation;

  listings: JobListing[];
  languageCode: string;
  contentId: string;

  constructor(private jobService: JobService) { }

  ngOnInit(): void {
      this.jobService.getListings(this.contentId).subscribe(
          (listings: JobListing[]) => { this.listings = listings; },
          (error: any) => { this.handleError(error); }
      );
  }

  private getDescription(description: string): string {
      if (description.length > 100) {
          return description.substring(0, 100) + "...";
      }

      return description;
  }

  private handleError(error: any): void {
      console.error("An error occurred", error);
      observableThrowError(error);
  }

}
