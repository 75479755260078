import { Directive, Input, EventEmitter, ElementRef, OnInit, Renderer2 } from "@angular/core";

@Directive({
    selector: "[focus]"
})
export class FocusDirective implements OnInit {
    @Input("focus") focus: EventEmitter<boolean>;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit(): void {
        this.focus.subscribe(() => {
            this.el.nativeElement.focus();
        });
    }
}
