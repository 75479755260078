import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { IsSecureGuard } from "../is-secure.guard";
import { SearchResultsComponent } from "./search-results/search-results.component";
import { SearchResultResolverService } from "./search-result-resolver.service";

const routes: Routes = [
    {
        path: ":languageId/search/:searchCriteria",
        component: SearchResultsComponent,
        resolve: { searchResults: SearchResultResolverService },
        canActivate: [ IsSecureGuard ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SearchRoutingModule { }
