export class CarouselItem {
    imageUrl: string;
    title: string;
    target: string;
    targetType: string;
    linkCausesNewWindow: boolean;

    transition: string;
    active: boolean;
}
