import { Component } from "@angular/core";

import { environment } from "@environment";
import { ContentDetail } from "@models/content-detail";

@Component({
    selector: "content-testimonial",
    templateUrl: "./content-testimonial.component.html",
    styleUrls: ["./content-testimonial.component.less"]
})
export class ContentTestimonialComponent {
    imageLocation: string = environment.ImageLocation;

    detail: ContentDetail;
}
