import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { PageDetail } from '@models/page-detail';
import { environment } from '@environment';

@Component({
    selector: 'page-video',
    templateUrl: './page-video.component.html',
    styleUrls: ['./page-video.component.less'],
})
export class PageVideoComponent {
    detail: PageDetail;
    localImageLocation: string = environment.LocalImageLocation;

    showDownloadUrl: boolean = false;

    constructor(private sanitizer: DomSanitizer) {}

    protected getVideo(): SafeHtml {
        if (this.detail) {
            let url: string = this.detail.VideoUrl;
            if (url.indexOf('?') > -1) {
                url += '&autoplay=0';
            } else {
                url += '?autoplay=0';
            }
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }

        return '';
    }

    showDownloadLink(show: boolean): void {
        this.showDownloadUrl = show;
    }
}
