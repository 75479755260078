import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@environment";
import { GlobalService } from "./global.service";
import { JobListing } from "@models/job-listing";
import { JobDetail } from "@models/job-detail";
import { JobMenu } from "@models/menu-item";

@Injectable()
export class JobService {
    private basePageUrl: string = environment.ApiBase + "page/";
    private baseJobUrl: string = environment.ApiBase + "job/";

    constructor(private http: HttpClient, private globalService: GlobalService) { }

    getListings(contentId: string = ""): Observable<JobListing[]> {
        const params: HttpParams = this.setupParams().set("contentId", contentId);
        return this.http.get<JobListing[]>(this.baseJobUrl + "listings", { params: params });
    }

    getDetails(id: string): Observable<JobDetail[]> {
        const params: HttpParams = this.setupParams();

        return this.http.get<JobDetail[]>(`${this.baseJobUrl}detail/${id}`, { params: this.setupParams() });
    }

    getMenu(id: string): Observable<JobMenu[]> {
        return this.http.get<JobMenu[]>(`${this.basePageUrl}menu/${id}`, { params: this.setupParams() });
    }

    private setupParams(): HttpParams {
        return new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });
    }
}
