<privacy-alert-check></privacy-alert-check>
<shared-header pageType="product"></shared-header>
<div class="productMenu">
    <product-menu></product-menu>
    <general-features featureType="local"></general-features>
</div>
<div class="productDetail">
    <div class="productDetailContent">
        <product-detail [tabs]="tabs"></product-detail>
    </div>
</div>
<div class="productFeatures">
    <product-quick-link></product-quick-link>
    <standard-features></standard-features>
</div>
