import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ProductComponent } from "./product/product.component";
import { ProductResolverService } from "./product-resolver.service";
import { IsSecureGuard } from "../is-secure.guard";

const routes: Routes = [
    {
        path: ":languageId/product/:id",
        component: ProductComponent,
        resolve: { tabs: ProductResolverService },
        canActivate: [ IsSecureGuard ]
    },
    {
        path: ":languageId/product/:id/videos",
        component: ProductComponent,
        resolve: { tabs: ProductResolverService },
        canActivate: [ IsSecureGuard ]
    },
    {
        path: ":languageId/product/:id/video",
        component: ProductComponent,
        resolve: { tabs: ProductResolverService },
        canActivate: [IsSecureGuard]
    },
    {
        path: ":languageId/product/:id/image",
        component: ProductComponent,
        resolve: { tabs: ProductResolverService },
        canActivate: [IsSecureGuard]
    },
    {
        path: ":languageId/product/:id/images",
        component: ProductComponent,
        resolve: { tabs: ProductResolverService},
        canActivate: [ IsSecureGuard ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductRoutingModule { }
