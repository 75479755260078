import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

declare var enableClickDimensions: any;

import { ProductDetailGroup } from "@models/product-detail-group";
import { ProductService } from "@services/product.service";
import { GlobalService } from "@services/global.service";

@Component({
    selector: "product",
    templateUrl: "./product.component.html",
    styleUrls: ["./product.component.less"]
})
export class ProductComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription: Subscription;
    private tabSubscription: Subscription;

    tabs: ProductDetailGroup[] = [];

    constructor(
        private productService: ProductService,
        private globalService: GlobalService,
        private route: ActivatedRoute
    ) {
        this.subscription = this.globalService.languageId$.subscribe(
            () => { this.getTabs(); }
        );
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: { tabs: ProductDetailGroup[] }) => this.tabs = data.tabs);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.tabSubscription) {
            this.tabSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.globalService.cookiesEnabled()) {
            enableClickDimensions();
        }
    }

    private getTabs(): void {
        const productId: string = this.route.snapshot.params["id"];
        this.tabSubscription = this.productService.getTabs(productId).subscribe((tabs: ProductDetailGroup[]) => { this.tabs = tabs ; });
    }
}
