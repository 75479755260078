import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { DOCUMENT, PlatformLocation } from "@angular/common";

import { GlobalService } from "@services/global.service";
import { UserService } from "@services/user.service";
import { UsnrDomain } from "@models/usnr-domain";
import { ValidatedUser } from "@models/validated-user";
import { environment } from "@environment";

@Injectable()
export class DomainAndUserGuard  {
    constructor(
        @Inject(DOCUMENT) private document: any,
        private platformLocation: PlatformLocation,
        private router: Router,
        private globalService: GlobalService,
        private userService: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser: ValidatedUser = this.userService.getCurrentUser();
        if (currentUser) {
            this.userService.validateCurrentUser(currentUser);
        } else if (this.userService.isAuthenticated()) {
            this.userService.createValidatedUser();
        }

        // STILL NEEDED???
        // let returnUrl: string = sessionStorage.getItem("returnUrl");
        // if (returnUrl && returnUrl !== "" && returnUrl !== "/") {
        //     sessionStorage.removeItem("returnUrl");
        //     returnUrl = `${this.globalService.getLanguageCode()}/${returnUrl}`;
        //     this.router.navigate([ returnUrl ]);
        // }

        if (environment.production) {
            let urlOrigin: string = this.document.location.hostname;
            console.log(urlOrigin);
            // const urlOrigin: string = "http://www.usnr.ru";
            // const urlOrigin: string = "http://www.ventek-inc.com";
            // const urlOrigin: string = "http://www.usnr.com";
            // const urlOrigin: string = "http://www.se-saws.com";
            const wwwIndex = urlOrigin.indexOf("www.");
            if (wwwIndex > -1) {
                urlOrigin = urlOrigin.substring(wwwIndex + 4);
            }
            return this.globalService.getDomain(urlOrigin)
                .toPromise()
                .then((domain: UsnrDomain) => {
                    if (domain) {
                        //// NOT USED SINCE ROUTES GET TRANSLATED IN IIS FROM www.usnr.ru to www.usnr.com/ru, etc.
                        //// -caused by only having SSL for usnr.com
                        // if (domain.LanguageId !== "") {
                        //     this.globalService.setLanguage(domain.LanguageId);
                        // }

                        if (domain.TargetType !== "") {
                            this.router.navigate([
                                this.globalService.getLanguageCode(),
                                domain.TargetType.toLowerCase(),
                                domain.Target.toLowerCase()
                            ],
                            { queryParams: { dt: 1 }});
                        }
                    }

                    return true;
                });
        } else {
            return true;
        }
    }
}
