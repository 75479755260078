import { Component } from "@angular/core";

import { environment } from "@environment";
import { ProductDetail } from "@models/product-detail";

@Component({
    selector: "product-related",
    templateUrl: "./product-related.component.html",
    styleUrls: ["./product-related.component.less"]
})
export class ProductRelatedComponent {
    private imagesPerRow: number = 3;
    imageLocation: string = environment.ImageLocation;

    relatedRows: ProductDetail[][];

    languageCode: string;

    setRelated(relatedProducts: ProductDetail[]): void {
        const rowCount: number = Math.trunc((relatedProducts.length + (this.imagesPerRow - 1)) / this.imagesPerRow);

        if (this.relatedRows !== undefined) {
            this.relatedRows.length = 0;
        }

        this.relatedRows = [];
        for (let i: number = 0; i < rowCount; ++i) {
            for (let j: number = 0; j < this.imagesPerRow; ++j) {
                if ((i * this.imagesPerRow) + j >= relatedProducts.length) {
                    break;
                }

                if (this.relatedRows[i] === undefined) {
                    this.relatedRows[i] = [];
                }

                this.relatedRows[i][j] = relatedProducts[(i * this.imagesPerRow) + j];
            }
        }
    }
}
