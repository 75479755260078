import { Component, AfterViewInit, Input, ChangeDetectorRef } from "@angular/core";

@Component({
    selector: "video-container",
    templateUrl: "./video-container.component.html",
    styleUrls: ["./video-container.component.less"]
})
export class VideoContainerComponent implements AfterViewInit {
    @Input() height: number;
    @Input() width: number;
    @Input() src: string;

    constructor(private _changeDetecterRef: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this._changeDetecterRef.detach();
    }
}
