<shared-header pageType="search"></shared-header>
<div class="resultCategories">
    <search-result-categories [result]="searchResults" (onFilterResults)="onFilterResults($event)"></search-result-categories>
</div>
<div class="resultDetails">
    <div class="resultDetailsContent">
        <search-result-details [result]="searchResults" [filter]="filterBy"></search-result-details>
    </div>
</div>
<div class="searchFeatures">
    <standard-features id="search"></standard-features>
</div>
