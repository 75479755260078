<link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">

<ng-container *ngIf="isTreeData">
    <table style="border-spacing: 0; border-width: 0;">
        <thead class="gridRowHeading">
            <th style="width: 25px">&nbsp;</th>
            <th *ngFor="let header of headers; let idx = index" [style.width]="fieldWidth(idx, null)" [style.text-align]="header.alignment" style="vertical-align: middle; margin-right: 3px;">
                {{header.text}}
            </th>
        </thead>
    </table>
    <mat-tree *ngIf="isTreeData" #dataQueryTree [dataSource]="treeDataSource" [treeControl]="treeControl" class="dataQuery-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
                <button mat-icon-button disabled></button>
                <table>
                    <tr>
                        <td *ngFor="let column of node.columns; let idx = index" [style.width]="fieldWidth(idx, node)" [style.text-align]="fieldAlignment(idx)">
                            <ng-container *ngIf="column.specialType === 'iostring' || column.specialType === 'iostringrequired'">
                                <dq-iostring [value]="column.value" [size]="column.width / 10" [id]="column.name + '_' + idx" [required]="column.specialType === 'iostringrequired'"></dq-iostring>
                            </ng-container>
                            <ng-container *ngIf="(!column.linkTarget || column.linkTarget === '') && column.specialType?.substring(0,8) !== 'iostring' && column.imageWidth === -1">
                                <span>{{column.value}}</span>
                            </ng-container>
                            <ng-container *ngIf="(!column.linkTarget || column.linkTarget === '') && column.specialType?.substring(0,8) !== 'iostring' && column.imageWidth > -1">
                                <dq-image [src]="column.value && column.value!=='' ? imageLocation + column.value : ''" [width]="column.imageWidth"></dq-image>
                            </ng-container>
                            <ng-container *ngIf="column.linkTarget && column.linkTarget !== ''">
                                <dq-image-link *ngIf="column.linkTarget === 'image'" [src]="imageLocation + column.value" [width]="column.imageWidth" [height]="column.imageHeight" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>
                                <dq-image-link *ngIf="column.linkTarget === 'imageUrl'" [src]="imageLocation + column.value" [width]="column.imageWidth" [height]="column.imageHeight" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>

                                <dq-anchor *ngIf="column.linkTarget === 'anchor'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>
                                <dq-anchor *ngIf="column.linkTarget === 'anchorUrl'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>

                                <dq-anchor *ngIf="column.linkTarget === 'filestream/string'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>
                                <dq-anchor *ngIf="column.linkTarget === 'dataquery/string'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>

                                <dq-image-link *ngIf="column.linkTarget === 'dataquery/image'" [src]="imageLocation + column.value" [width]="column.imageWidth" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>
                                <dq-image-link *ngIf="column.linkTarget === 'filestream/image'" [src]="imageLocation + column.value" [width]="column.imageWidth" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>
                            </ng-container>
                    </td>
                    </tr>
                </table>
            </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when treeNodeHasChild">
            <li>
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle>
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <table>
                        <tr>
                            <td *ngFor="let column of node.columns; let idx = index" [style.width]="fieldWidth(idx, node)" [style.text-align]="fieldAlignment(idx)">
                                <ng-container *ngIf="column.specialType === 'iostring' || column.specialType === 'iostringrequired'">
                                    <dq-iostring [value]="column.value" [size]="column.width / 10" [id]="column.name + '_' + idx" [required]="column.specialType === 'iostringrequired'"></dq-iostring>
                                </ng-container>
                                <ng-container *ngIf="(!column.linkTarget || column.linkTarget === '') && column.specialType?.substring(0,8) !== 'iostring' && column.imageWidth === -1">
                                    <span>{{column.value}}</span>
                                </ng-container>
                                <ng-container *ngIf="(!column.linkTarget || column.linkTarget === '') && column.specialType?.substring(0,8) !== 'iostring' && column.imageWidth > -1">
                                    <dq-image [src]="column.value && column.value!=='' ? imageLocation + column.value : ''" [width]="column.imageWidth"></dq-image>
                                </ng-container>
                                <ng-container *ngIf="column.linkTarget && column.linkTarget !== ''">
                                    <dq-image-link *ngIf="column.linkTarget === 'image'" [src]="imageLocation + column.value" [width]="column.imageWidth" [height]="column.imageHeight" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>
                                    <dq-image-link *ngIf="column.linkTarget === 'imageUrl'" [src]="imageLocation + column.value" [width]="column.imageWidth" [height]="column.imageHeight" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>

                                    <dq-anchor *ngIf="column.linkTarget === 'anchor'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>
                                    <dq-anchor *ngIf="column.linkTarget === 'anchorUrl'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>

                                    <dq-anchor *ngIf="column.linkTarget === 'filestream/string'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>
                                    <dq-anchor *ngIf="column.linkTarget === 'dataquery/string'" [width]="column.width" (select)="executeLink(column, idx, $event)" [value]="column.value" [allowLink]="showBusy"></dq-anchor>

                                    <dq-image-link *ngIf="column.linkTarget === 'dataquery/image'" [src]="imageLocation + column.value" [width]="column.imageWidth" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>
                                    <dq-image-link *ngIf="column.linkTarget === 'filestream/image'" [src]="imageLocation + column.value" [width]="column.imageWidth" (select)="executeLink(column, idx, $event)" [allowLink]="showBusy"></dq-image-link>
                                </ng-container>
                            </td>
                        </tr>
                    </table>
                </div>
                <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>
</ng-container>

<ng-container *ngIf="!isTreeData">
    <table *ngIf="rows.length > 0 || showHeader" sortable-table (sorted)="onSorted(id, datasetId, $event)" class="resultsGrid">
        <thead *ngIf="showHeader" class="gridRowHeading">
            <ng-container *ngFor="let header of headers; let index = index">
                    <th *ngIf="header.sortable" [sortable-column]="index" [table-id]="datasetId" [query-id]="id" [style.width.px]="header.width" [style.text-align]="header.alignment" style="padding: 3px; vertical-align: middle;">
                        {{header.text}}<span *ngIf="header.requiredField" style="margin-left: 3px;">*</span>
                    </th>
                    <th *ngIf="!header.sortable" [style.width.px]="header.width" [style.text-align]="header.alignment" [style.padding]="'3px'" style="padding: 3px; vertical-align: middle;">
                        {{header.text}}<span *ngIf="header.requiredField" style="margin-left: 3px;">*</span>
                    </th>
            </ng-container>
        </thead>

        <tr *ngFor="let row of rows; let isOdd = odd; let idx = index;" [class.gridBandedRow]="bandedRows && isOdd">
            <td *ngFor="let column of row.columns; let first = first; let last = last; let isEven = even;" [style.text-align]="column.alignment"
                    [class.gridBandedColumn]="isEven && bandedColumns" [class.gridFirstColumn]="first && highlightFirst" [class.gridLastColumn]="last && highlightLast">
                <ng-container *ngIf="column.specialType === 'image'; else nonFilestream">
                    <img [src]="'data:image/gif;base64,' + column.value" alt=""
                        [ngStyle]="{'height': column.imageHeight > 0 ? column.imageHeight + 'px' : -1,
                                    'width': column.imageWidth > 0 ? column.imageWidth + 'px' : -1}" />
                </ng-container>
                <ng-template #nonFilestream>
                    <ng-container *ngIf="column.specialType === 'iostring' || column.specialType === 'iostringrequired'">
                        <dq-iostring [value]="column.value" [size]="column.width / 10" [id]="column.name + '_' + idx" [required]="column.specialType === 'iostringrequired'"></dq-iostring>
                    </ng-container>
                    <ng-container *ngIf="(!column.linkTarget || column.linkTarget === '') && column.specialType?.substring(0,8) !== 'iostring' && column.imageWidth === -1">
                        <span>{{column.value}}</span>
                    </ng-container>
                    <ng-container *ngIf="(!column.linkTarget || column.linkTarget === '') && column.specialType?.substring(0,8) !== 'iostring' && column.imageWidth > -1">
                        <dq-image [src]="column.value && column.value!=='' ? imageLocation + column.value : ''" [width]="column.imageWidth"></dq-image>
                    </ng-container>
                    <ng-container *ngIf="column.linkTarget && column.linkTarget !== ''">
                        <dq-image-link *ngIf="column.linkTarget === 'image'" [src]="imageLocation + column.value" [width]="column.imageWidth" [height]="column.imageHeight" (click)="executeLink(column, idx)"></dq-image-link>
                        <dq-image-link *ngIf="column.linkTarget === 'imageUrl'" [src]="imageLocation + column.value" [width]="column.imageWidth" [height]="column.imageHeight" (click)="executeLink(column, idx)"></dq-image-link>

                        <dq-anchor *ngIf="column.linkTarget === 'anchor'" [width]="column.width" (click)="executeLink(column, idx)" [value]="column.value"></dq-anchor>
                        <dq-anchor *ngIf="column.linkTarget === 'anchorUrl'" [width]="column.width" (click)="executeLink(column, idx)" [value]="column.value"></dq-anchor>

                        <dq-anchor *ngIf="column.linkTarget === 'filestream/string'" [width]="column.width" (click)="executeLink(column, idx)" [value]="column.value"></dq-anchor>
                        <dq-anchor *ngIf="column.linkTarget === 'dataquery/string'" [width]="column.width" (click)="executeLink(column, idx)" [value]="column.value"></dq-anchor>

                        <dq-image-link *ngIf="column.linkTarget === 'dataquery/image'" [src]="imageLocation + column.value" [width]="column.imageWidth" (click)="executeLink(column, idx)"></dq-image-link>
                        <dq-image-link *ngIf="column.linkTarget === 'filestream/image'" [src]="imageLocation + column.value" [width]="column.imageWidth" (click)="executeLink(column, idx)"></dq-image-link>
                    </ng-container>
                </ng-template>
            </td>
        </tr>
        <tfoot *ngIf="showTotal" class="gridFooter">
            <td *ngFor="let footerColumn of footer.columns" [style.text-align]="footerColumn.alignment"><span [innerHtml]="footerColumn.value"></span></td>
        </tfoot>
    </table>
</ng-container>
<ng-container *ngIf="rows.length === 0 && showNoResultsMessage">
    <span>{{noResultsText}}</span>
</ng-container>
