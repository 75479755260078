<div *ngIf="detail.DetailType === 'Testimonial Left'" class="contentTestimonialLeft">
    <div class="contentTestimonialBoxContainerLeft">
        <div *ngIf="detail.MediaFileName && detail.MediaFileName !== ''" class="contentTestimonialBoxImageDiv">
            <img src="{{imageLocation}}{{detail.MediaFileName}}" alt="{{detail.Other}}" width="104px;"/>
        </div>
        <div class="contentTestimonialQuoteContainer">
            <div class="contentTestimonialQuoteSymbol"></div>
            <div class="contentTestimonialQuote">
                <span *ngIf="detail.Description !== ''"><i>{{detail.Description}}</i><br /></span>
                <div class="contentTestimonialQuoteSource">
                    <span *ngIf="detail.Other !== ''">{{detail.Other}}<br /></span>
                    <span *ngIf="detail.Title !== ''">{{detail.Title}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="detail.DetailType !== 'Testimonial Left'" class="contentTestimonialRight">
    <div class="contentTestimonialBoxContainerRight">
        <div *ngIf="detail.MediaFileName && detail.MediaFileName !== ''" class="contentTestimonialBoxImageDiv">
            <img src="{{imageLocation}}{{detail.MediaFileName}}" alt="{{detail.Other}}" width="104px;"/>
        </div>
        <div class="contentTestimonialQuoteContainer">
            <div class="contentTestimonialQuoteSymbol"></div>
            <div class="contentTestimonialQuote">
                <span *ngIf="detail.Description !== ''"><i>{{detail.Description}}</i><br /></span>
                <div class="contentTestimonialQuoteSource">
                    <span *ngIf="detail.Other !== ''">{{detail.Other}}<br /></span>
                    <span *ngIf="detail.Title !== ''">{{detail.Title}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
