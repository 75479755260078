<div>
    <div class="footer">
        <div class="footerMainMenuItem" *ngFor="let item of menuItems">
            <a [routerLink]="['/' + languageCode, item.Type.toLowerCase(), item.Id]" [queryParams]="{ dt: 1 }">{{item.Title}}</a>
            <div *ngFor="let subItem of item.Items" style="clear: both; line-height: 16px;">
                <a [routerLink]="['/' + languageCode, subItem.Type.toLowerCase(), subItem.Id]" [queryParams]="{ dt: 1 }">
                    <img src="{{localImageLocation}}Arrows.png" alt="{{subItem.Title}}" />
                </a>
                <a [routerLink]="['/' + languageCode, subItem.Type.toLowerCase(), subItem.Id]" [queryParams]="{ dt: 1 }">{{subItem.Title}}</a>
            </div>
        </div>
        <div class="footerMainMenuItem">
            <span *ngIf="footerInfo">{{footerInfo.SelectLanguageText}}</span>
            <div *ngFor="let language of languages" style="clear: both; line-height: 16px;">
                <a href="javascript:void(0)" (click)="onSelectLanguage(language)"><img src="{{localImageLocation}}Arrows.png" alt="{{language.Description}}" /></a>
                <a href="javascript:void(0)" (click)="onSelectLanguage(language)">{{language.Description}}</a>
            </div>
        </div>
        <div style="clear: both;"></div>
        <div class="footerCopyright">
            <span *ngIf="footerInfo">{{footerInfo.Copyright}}</span>
        </div>
        <div class="footerImage">
            <img *ngIf="footerInfo" src="{{imageLocation}}{{footerInfo.FooterImageLink}}" alt="{{footerInfo.Copyright}}" />
        </div>
    </div>
</div>
