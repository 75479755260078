import { Component, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "product-detail-heading",
    templateUrl: "./product-detail-heading.component.html",
    styleUrls: ["./product-detail-heading.component.less"]
})
export class ProductDetailHeadingComponent {
    heading: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    setHeading(heading: string): void {
        this.heading = this.sanitizer.bypassSecurityTrustHtml(heading);
    }
}
