import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "dq-iostring",
    templateUrl: "./iostring.component.html",
    styleUrls: ["./iostring.component.less"]
})
export class IOStringComponent implements OnInit {
    @Input() value: string;
    @Input() size: number;
    @Input() id: string;
    @Input() index: number;
    @Input() required: boolean;

    constructor() { }

    ngOnInit(): void {
    }
}
