import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'page-feature',
    templateUrl: './page-feature.component.html',
    styleUrls: ['./page-feature.component.less'],
})
export class PageFeatureComponent {
    title: string;
    description: string;
}
