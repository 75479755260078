import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { JobDetail } from "@models/job-detail";
import { GlobalService } from "@services/global.service";
import { JobService } from "@services/job.service";

@Injectable()
export class JobResolverService  {
    constructor(private jobService: JobService, private globalService: GlobalService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<JobDetail[]> {
        const id: string = route.params["id"] as string;
        if (id === null) {
            this.router.navigate([this.globalService.getLanguageCode(), "page", "error"], { queryParams: { dt: 1, returnUrl: state.url } });
        }

        let languageId = route.paramMap.get("languageId");
        if (languageId && languageId !== "") {
            this.globalService.setLanguage(languageId);
        } else {
            languageId = this.globalService.getLanguage();
            if (!languageId) {
                this.globalService.setLanguage("en-us");
            }
        }
        return this.resolveJob(id);
    }

    private resolveJob(id: string): Promise<JobDetail[]> {
        return this.jobService.getDetails(id)
            .toPromise()
            .then(details => {
                if (details === undefined || details === null && id !== "error") {
                    this.router.navigate([this.globalService.getLanguageCode(), "page", "error"], { queryParams: { dt: 1 }});
                    return null;
                }

                return details;
            });
    }
}
