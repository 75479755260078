import { Component, Input } from "@angular/core";

@Component({
    selector: "field-error-display",
    templateUrl: "./field-error-display.component.html",
    styleUrls: ["./field-error-display.component.less"]
})
export class FieldErrorDisplayComponent {
    @Input() errorMessage: string;
    @Input() displayError: boolean;
}
