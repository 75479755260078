import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";

import { JobMenu } from "@models/menu-item";
import { GlobalService } from "@services/global.service";
import { JobService } from "@services/job.service";

@Component({
    selector: "job-menu",
    templateUrl: "./job-menu.component.html",
    styleUrls: ["./job-menu.component.less"]
})
export class JobMenuComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private routeSubscription: Subscription;

    menu: JobMenu[];
    jobId: string;
    selectedMenu: JobMenu;
    languageCode: string;

    constructor(private globalService: GlobalService, private jobService: JobService, private route: ActivatedRoute) {
        this.subscription = globalService.languageId$.subscribe(() => { this.ngOnInit(); });
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe(
            (params: Params) => {
                this.jobId = params["id"];

                this.jobService.getMenu(params["id"]).subscribe(
                    (menu: JobMenu[]) => {
                        this.menu = menu;
                        this.selectedMenu =
                            (menu === null || menu === undefined)
                                ? null
                                : menu.find(page => page.Id.toLowerCase() === this.jobId.toLowerCase());
                    }
                );
            }
        );

        this.languageCode = this.globalService.getLanguageCode();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    getClass(item: JobMenu) {
        let cssClass: any;

        if (item.Id.toLowerCase() === this.selectedMenu.Id.toLowerCase()) {
            cssClass = { "menuSelected": true, "menuChild": false, "menuParent": false, "menuSibling": false };
        } else if (item.Level < this.selectedMenu.Level) {
            cssClass = { "menuSelected": false, "menuChild": false, "menuParent": true, "menuSibling": false };
        } else if (item.Level > this.selectedMenu.Level) {
            cssClass = { "menuSelected": false, "menuChild": true, "menuParent": false, "menuSibling": false };
        } else {    // item.Level === this.selectedMenu.Level
            cssClass = { "menuSelected": false, "menuChild": false, "menuParent": false, "menuSibling": true };
        }

        return cssClass;
    }

    getCurrentPageId(): string {
        const id: string = this.route.snapshot.params["id"];
        if (id) {
            return id;
        }

        return "home";
    }

    getCurrentPageType(): string {
        if (this.route.snapshot.url.length > 2) {
            return this.route.snapshot.url[1].path;
        }

        return "page";
    }
}
