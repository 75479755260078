import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';

import { DomainAndUserGuard } from './domain-and-user.guard';
import { LowerCaseUrlSerializer } from './lower-case-url-serializer';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
    { path: "about/more/ip.asp", redirectTo: "/en/page/ip", pathMatch: "full" },
    { path: "ip", redirectTo: "/en/page/ip", pathMatch: "full" },
    { path: "careers", redirectTo: "/en/page/careers", pathMatch: "full" },
    { path: "legal", redirectTo: "/en/page/legal", pathMatch: "full" },
    { path: "support", redirectTo: "/en/page/support", pathMatch: "full" },
    { path: "returns", redirectTo: "/en/page/returns", pathMatch: "full" },
    { path: "dtsi", redirectTo: "/en/page/home", pathMatch: "full" },
    { path: "", redirectTo: "/en/page/home", pathMatch: "full" },
    { path: "**", redirectTo: "/en/page/error", pathMatch: "full" }
];

@NgModule({
        // RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes or popups
            initialNavigation:
              !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
                ? "enabledNonBlocking"
                : "disabled", // Set to enabledBlocking to use Angular Universal
        })
    ],
    providers: [
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
        DomainAndUserGuard
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
