import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@services/user.service';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { CustomerAccount } from '@models/customer-account';
import { GlobalService } from '@services/global.service';
import { environment } from '@environment';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
    selector: 'customer-account-selector',
    templateUrl: './customer-account-selector.component.html',
    styleUrls: ['./customer-account-selector.component.less'],
})
export class CustomerAccountSelectorComponent extends BaseComponent implements OnInit
{
    protected _localImageLocation: string = environment.LocalImageLocation;

    busy: boolean = false;

    get showAccounts(): boolean {
        return (
            this.accounts &&
            this.accounts.Accounts &&
            (this.accounts.Accounts.length > 0 ||
                (this.accounts.Accounts.length === 1 &&
                    this.searchCriteria !== ''))
        );
    }

    accounts: UserCustomerAccounts;

    searchHelpText: string;
    searchButtonTitle: string;

    searchCriteria: string = '';
    pagedAccounts: CustomerAccount[][];
    currentPage: number = 0;
    maxPages: number = 0;

    constructor(
        private userService: UserService,
        private globalService: GlobalService,
        private router: Router
    ) {
        super();

        this.subscriptions.push(globalService.languageId$.subscribe(() => { this.ngOnInit(); }));
    }

    ngOnInit() {
        this.searchAccounts();
    }

    searchAccounts(): void {
        this.busy = true;
        this.userService
            .getCustomerAccountOptions(this.searchCriteria)
            .subscribe({
                next: (accounts: UserCustomerAccounts) => {
                    this.busy = false;
                    this.accounts = accounts;

                    if (accounts && accounts.Accounts && accounts.Accounts.length > 0 ) {
                        if (accounts.Accounts.length === 1 && this.searchCriteria === '') {
                            this.pagedAccounts = [];
                            this.accountSelected(accounts.Accounts[0]);
                        } else {
                            let startIdx: number = 0;
                            let page: number = 0;
                            this.pagedAccounts = [];
                            do {
                                this.pagedAccounts[page] = accounts.Accounts.slice(startIdx, startIdx + 20);
                                startIdx += 20;
                                page++;
                            } while (startIdx < accounts.Accounts.length);
                            this.maxPages = page;
                            this.currentPage = 0;
                        }
                    }
                },
                error: (error: any) => {
                    this.busy = false;
                    this.handleError(error);
                }
            });
    }

    accountSelected(account: CustomerAccount): void {
        this.userService.setCustomerAccount(account, this.accounts.CustomerAccountSelectedPageId);
        this.router.navigate(
            [ this.globalService.getLanguageCode(), 'page', this.accounts.CustomerAccountSelectedPageId ]
        );
    }

    setPage(page: number): void {
        this.currentPage = page;
    }

    incrementPage(increment: number): void {
        this.currentPage += increment;
        if (this.currentPage > this.maxPages - 1) {
            this.currentPage = this.maxPages - 1;
        } else if (this.currentPage < 0) {
            this.currentPage = 0;
        }
    }
}
