import { Component, OnInit, AfterViewChecked, EventEmitter, Input, Output } from "@angular/core";

import { ProductQuickLink } from "@models/product-quick-link";
import { environment } from "@environment";

@Component({
    selector: "image-viewer",
    templateUrl: "./image-viewer.component.html",
    styleUrls: ["./image-viewer.component.less"]
})
export class ImageViewerComponent implements OnInit, AfterViewChecked {
    @Output() imageViewerClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() images: ProductQuickLink[];
    @Input() productTitle: string;
    @Input()
    set show(value: boolean) {
        if (value !== this.showImageViewer) {
            this.showImageViewer = value;
            if (!value) {
                this.imageViewerClosed.emit(true);
            } else {
                this.maxImageWidth = this.getMaxImageWidth();
            }
        }
    }
    get show(): boolean {
        return this.showImageViewer;
    }

    private showImageViewer: boolean;

    selectedImageIndex: number;
    shownImageIndex: number;
    localImageLocation: string = environment.LocalImageLocation;
    maxImageWidth: number;
    imageMaximized: boolean = false;
    maximizedImage: ProductQuickLink;

    ngOnInit(): void {
        if (this.images && this.images.length > 0) {
            this.maximizedImage = this.images[0];
        }
    }

    ngAfterViewChecked(): void {
        if (this.selectedImageIndex === null || this.selectedImageIndex === undefined) {
            this.selectedImageIndex = 0;
        }

        if (this.shownImageIndex === null || this.shownImageIndex === undefined) {
            this.shownImageIndex = 0;
        }
    }

    onSelectedImageChanged(index: number): void {
        this.selectedImageIndex = index;
    }

    onShownImageChanged(index: number): void {
        if (this.selectedImageIndex !== index) {
            this.selectedImageIndex = index;
        }

        this.shownImageIndex = index;
    }

    private getMaxImageWidth(): number {
        if (this.images.length > 0) {
            let proportionalWidth: number = 0;
            let maxWidth: number = 0;
            this.images.forEach((image: ProductQuickLink) => {
                if (image.FileInfo !== null && image.FileInfo.Height > 0) {
                    proportionalWidth = 400 / image.FileInfo.Height * image.FileInfo.Width;
                    if (proportionalWidth > maxWidth) {
                        maxWidth = proportionalWidth;
                    }
                }
            });

            return Math.floor(maxWidth + .5);
        }

        return 0;
    }

    private onShowMaximizedImage(showImage: boolean): void {
        if (showImage) {
            this.maximizedImage = this.images[this.selectedImageIndex];
        }
        this.imageMaximized = showImage;
    }

    private onKeyDown(key: any) {
        this.onShowMaximizedImage(false);
    }

    onBackgroundClick(): void {
        if (this.imageMaximized) {
            this.imageMaximized = false;
        }
    }
}
