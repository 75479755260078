import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { environment } from "@environment";
import { GeneralFeature } from "@models/general-feature";
import { GlobalService } from "@services/global.service";
import { UserService } from "@services/user.service";

@Component({
    selector: "general-features",
    templateUrl: "./general-features.component.html",
    styleUrls: ["./general-features.component.less"]
})
export class GeneralFeaturesComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private userSubscription: Subscription;
    private routeSubscription: Subscription;

    @Input() featureType: string;
    @Input() id: string;

    imageLocation: string = environment.ImageLocation;
    features: GeneralFeature[] = [];

    constructor(
        private globalService: GlobalService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private router: Router,
        private userService: UserService
    ) {
        this.languageSubscription = globalService.languageId$.subscribe(
            () => { this.ngOnInit(); }
        );

        this.userSubscription = this.userService.currentUser$.subscribe(
            () => this.ngOnInit()
        );
    }

    ngOnInit() {
        if (!this.id || this.id === "") {
            this.routeSubscription = this.route.params.subscribe(
                (params: Params) => {
                    this.globalService.getGeneralFeatures(params["id"], this.featureType).subscribe(
                        (features: GeneralFeature[]) => {
                            if (features) {
                                this.processFeatures(features);
                            } else {
                                this.features = null;
                            }
                        },
                        (error: any) => { this.handleError(error); }
                    );
                }
            );
        } else {
            this.routeSubscription = this.globalService.getGeneralFeatures(this.id, this.featureType).subscribe(
                (features: GeneralFeature[]) => {
                    if (features) {
                        this.processFeatures(features);
                    } else {
                        this.features = null;
                    }
                 },
                (error: any) => { this.handleError(error); }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    private processFeatures(features: GeneralFeature[]) {
        if (features) {
            features.forEach((f: GeneralFeature) => {
                f.BackgroundColor = f.HtmlColor;
            });
        }
        this.features = features;
    }

    getField(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    // private getCurrentPageId(): string {
    //     const id: string = this.route.snapshot.params["id"];
    //     if (id) {
    //         return id;
    //     }

    //     return "home";
    // }

    // private getCurrentPageType(): string {
    //     if (this.route.snapshot.url.length > 2) {
    //         return this.route.snapshot.url[1].path;
    //     }

    //     return "page";
    // }

    navigateToTarget(feature: GeneralFeature): void {
        const languageCode: string = this.globalService.getLanguageCode();
        switch (feature.TargetType.toLowerCase()) {
        case "page":
            this.router.navigate([languageCode, "page", feature.Target], { queryParams: { dt: 1 }});
            break;
        case "product":
            this.router.navigate([languageCode, "product", feature.Target], { queryParams: { dt: 1 }});
            break;
        case "content":
            this.router.navigate([languageCode, "content", feature.Target], { queryParams: { dt: 1 }});
            break;
        case "media":
            this.router.navigate([]).then(() => { window.open(environment.ImageLocation + feature.Target, "_blank"); });
            break;
        case "none":
            return;
        default:
            this.router.navigateByUrl(feature.Target);
        }
    }

    setHoverColor(feature: GeneralFeature): void {
        feature.BackgroundColor = feature.HtmlHoverColor;
    }

    setBackgroundColor(feature: GeneralFeature): void {
        feature.BackgroundColor = feature.HtmlColor;
    }

    private handleError(error: any): void {
        console.error("An error occurred", error);
    }
}
