<div>
    <table class="childProducts">
        <tr *ngFor="let row of childRows">
            <td>
                <a *ngIf="row.length > 0" [routerLink]="['/' + languageCode, 'product', row[0].TargetProductId]" [queryParams]="{ dt: 1 }">
                    <div class="image-container">
                        <img class="base" src="{{imageLocation}}{{row[0].MediaFileName}}" alt="{{row[0].Title}}" />
                        <img *ngIf="row[0].MediaWatermarkFileName && row[0].MediaWatermarkFileName!==''" class="watermark" src="{{imageLocation}}{{row[0].MediaWatermarkFileName}}" />
                    </div>
                    <div><span style="padding-right: 3px;">{{row[0].Title}}</span></div>
                </a>
            </td>
            <td>
                <a *ngIf="row.length > 1" [routerLink]="['/' + languageCode, 'product', row[1].TargetProductId]" [queryParams]="{ dt: 1 }">
                    <div class="image-container">
                        <img class="base" src="{{imageLocation}}{{row[1].MediaFileName}}" alt="{{row[1].Title}}" />
                        <img *ngIf="row[1].MediaWatermarkFileName && row[1].MediaWatermarkFileName!==''" class="watermark" src="{{imageLocation}}{{row[1].MediaWatermarkFileName}}"  />
                    </div>
                    <div><span style="padding-right: 3px;">{{row[1].Title}}</span></div>
                </a>
            </td>
            <td>
                <a *ngIf="row.length > 2" [routerLink]="['/' + languageCode, 'product', row[2].TargetProductId]" [queryParams]="{ dt: 1 }">
                    <div class="image-container">
                        <img class="base" src="{{imageLocation}}{{row[2].MediaFileName}}" alt="{{row[2].Title}}" />
                        <img *ngIf="row[2].MediaWatermarkFileName && row[2].MediaWatermarkFileName!==''" class="watermark" src="{{imageLocation}}{{row[2].MediaWatermarkFileName}}" />
                    </div>
                    <div><span style="padding-right: 3px;">{{row[2].Title}}</span></div>
                </a>
            </td>
            <td>
                <a *ngIf="row.length > 3" [routerLink]="['/' + languageCode, 'product', row[3].TargetProductId]" [queryParams]="{ dt: 1 }">
                    <div class="image-container">
                        <img class="base" src="{{imageLocation}}{{row[3].MediaFileName}}" alt="{{row[3].Title}}" />
                        <img *ngIf="row[3].MediaWatermarkFileName && row[3].MediaWatermarkFileName!==''" class="watermark" src="{{imageLocation}}{{row[3].MediaWatermarkFileName}}" />
                    </div>
                    <div><span style="padding-right: 3px;">{{row[3].Title}}</span></div>
                </a>
            </td>
        </tr>
    </table>
</div>
