import {
    Component,
    OnInit,
    ComponentFactoryResolver,
    ViewContainerRef,
    Input,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ComponentFactory,
    ComponentRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

import { ProductDetail } from "@models/product-detail";
import { ProductDetailGroup } from "@models/product-detail-group";
import { GlobalService } from "@services/global.service";
import { ProductService } from "@services/product.service";
import { ProductTabHeadersComponent } from "../product-tab-headers/product-tab-headers.component";

@Component({
    selector: "product-detail",
    templateUrl: "./product-detail.component.html",
    styleUrls: ["./product-detail.component.less"]
})
export class ProductDetailComponent implements OnDestroy, OnChanges {
    private subscription: Subscription;
    private tabsSubscription: Subscription;
    private details: ProductDetail[];
    @Input() tabs: ProductDetailGroup[];

    constructor(
        private globalService: GlobalService,
        private productService: ProductService,
        private route: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {
        this.subscription = globalService.languageId$.subscribe(
            () => { this.ngOnChanges(null); }
        );
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.processTabs();
    }

    private processTabs(): void {
        this.viewContainerRef.clear();
        if (this.tabs !== undefined) {
            this.showTabHeaders(this.tabs);
        }
    }

    private showTabHeaders(tabs: ProductDetailGroup[]): void {
        const factory: ComponentFactory<ProductTabHeadersComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductTabHeadersComponent);
        const ref: ComponentRef<ProductTabHeadersComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.tabs = tabs;
        ref.changeDetectorRef.detectChanges();
    }
}
