<div style="clear: both;"></div>
<div [ngClass]="{ 'inlineImageCenterFrame': detail.DetailType==='Inline Image Center'}">
    <ng-container *ngIf="detail.DetailType === 'Inline Image Left'">
        <p class="inlineImageLeft">
            <img src="{{imageLocation}}{{detail.MediaFileName}}" alt="{{detail.Description}}" [width]="imageWidth()"/>
            <i *ngIf="detail.Description !== '' && detail.Description.substring(0, 3) !== '<p>'"><span [innerHTML]="description()"></span></i>
        </p>
        <i *ngIf="detail.Description !== '' && detail.Description.substring(0, 3) === '<p>'"><span [innerHTML]="description()"></span></i>
    </ng-container>
    <ng-container *ngIf="detail.DetailType !== 'Inline Image Left'">
        <p [ngClass]="{ 'inlineImageRight': detail.DetailType==='Inline Image Right', 'inlineImageCenter': detail.DetailType==='Inline Image Center' }">
            <img src="{{imageLocation}}{{detail.MediaFileName}}" alt="{{detail.Description}}" [width]="imageWidth()"/>
            <i *ngIf="detail.Description !== '' && detail.Description.substring(0, 3) !== '<p>'"><span [innerHTML]="description()"></span></i>
        </p>
    </ng-container>
</div>
