import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { UserService } from './user.service';
import { GlobalService } from './global.service';
import { PageDetail } from '@models/page-detail';
import { ValidatedUser } from '@models/validated-user';
import { PageMenu } from '@models/menu-item';
import { PageSummary } from '@models/page-summary';

@Injectable()
export class PageService {
    private baseUrl: string = environment.ApiBase + 'page/';

    constructor(
        private http: HttpClient,
        private globalService: GlobalService,
        private userService: UserService
    ) {}

    getMenu(id: string): Observable<PageMenu[]> {
        const params: HttpParams = new HttpParams({
            fromObject: { language: this.globalService.getLanguage() },
        });
        return this.http.get<PageMenu[]>(`${this.baseUrl}menu/${id}`, {
            params: params,
        });
    }

    getDetails(id: string): Observable<PageSummary> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', this.globalService.getLanguage());

        const currentUser: ValidatedUser = this.userService.getCurrentUser();
        if (currentUser !== undefined && currentUser !== null) {
            params = params.append('userId', currentUser.UserId); // TODO: NOT NEEDED - USE JWT TOKEN VALUE
        }

        return this.http.get<PageSummary>(`${this.baseUrl}detail/${id}`, {
            params: params,
        });
    }
}
