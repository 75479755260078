<div *ngIf="show">
    <div class="imageViewerBackground" (click)="onBackgroundClick()"></div>
    <div class="imageViewerDisplay"  [style.display]="!imageMaximized ? 'block' : 'none'"
                                     [style.width.px]="maxImageWidth + 80 - 30"
                                     [style.margin-left.px]="-(maxImageWidth + 80 - 30) / 2"
                                     [style.margin-top.px]="-(540 + 20)/2">
        <div>
            <h2 class="imageViewHeader">{{productTitle}}</h2>
            <div class="imageViewerClose">
                <img src="{{localImageLocation}}close_btn.jpg" alt="Close" (click)="show = false;"/>
            </div>
        </div>
        <div style="clear: both"></div>
        <div class="imageView">
            <image-view [selectedImageIndex]="selectedImageIndex" [images]="images" [maxImageWidth]="maxImageWidth" (shownImageChanged)="onShownImageChanged($event)" (maximizeImage)="onShowMaximizedImage($event)"></image-view>
        </div>
        <div class="imageViewList">
            <thumbnail-list [images]="images" [maxImageWidth]="maxImageWidth" [shownImageIndex]="shownImageIndex" (selectedImageChanged)="onSelectedImageChanged($event)"></thumbnail-list>
        </div>
    </div>
    <div class="imageViewerMaximizedImage" [style.display]="imageMaximized ? 'block' : 'none'">
        <image-view-maximized [image]="maximizedImage" (imageMaximized)="onShowMaximizedImage($event)"></image-view-maximized>
    </div>
</div>
