import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.less'],
})
export class BaseComponent implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    constructor() {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((s: Subscription) => s.unsubscribe())
    }

    protected handleError(error: any): void {
        console.error('An error occurred', error);
    }
}
