<table class="contentTable">
    <tr *ngFor="let row of rows" [ngClass]="{ 'tableRowHeading' : row.DetailType==='Heading' }">
        <td *ngIf="row.DetailType==='Footnote' && row.Column1 !== '' && isLessThanFullWidth" [style.width.px]="row.Column1Width" [attr.colspan]="columnCount+1" style="font-size: 12px;" [innerHTML]="columnValue(row.Column1)"></td>
        <td *ngIf="row.DetailType==='Footnote' && row.Column1 !== '' && !isLessThanFullWidth" [attr.colspan]="row.NumberOfColumns" style="font-size: 12px;" [innerHTML]="columnValue(row.Column1)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Title !== '' && isLessThanFullWidth" [style.width.px]="row.TitleWidth" [ngClass]="getRowClass(row.DetailType)" class="tableTitle" [innerHTML]="columnValue(row.Title)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Title !== '' && !isLessThanFullWidth" [ngClass]="getRowClass(row.DetailType)" class="tableTitle" [innerHTML]="columnValue(row.Title)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && columnCount >= 1  && row.Title ===''" [ngClass]=" { 'tableValue': row.DetailType!=='Heading' }">&nbsp;</td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column1 !== '' && isLessThanFullWidth" [style.width.px]="row.Column1Width" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column1)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column1 !== '' && !isLessThanFullWidth" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column1)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && columnCount >= 2  && row.Column1 ===''" [ngClass]=" { 'tableValue': row.DetailType!=='Heading' }">&nbsp;</td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column2 !== '' && isLessThanFullWidth" [style.width.px]="row.Column2Width" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column2)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column2 !== '' && !isLessThanFullWidth" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column2)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && columnCount >= 3  && row.Column2 ===''" [ngClass]=" { 'tableValue': row.DetailType!=='Heading' }">&nbsp;</td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column3 !== '' && isLessThanFullWidth" [style.width.px]="row.Column3Width" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column3)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column3 !== '' && !isLessThanFullWidth" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column3)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && columnCount >= 4  && row.Column3 ===''" [ngClass]=" { 'tableValue': row.DetailType!=='Heading' }">&nbsp;</td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column4 !== '' && isLessThanFullWidth" [style.width.px]="row.Column4Width" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column4)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column4 !== '' && !isLessThanFullWidth" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column4)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && columnCount >= 5  && row.Column4 ===''" [ngClass]=" { 'tableValue': row.DetailType!=='Heading' }">&nbsp;</td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column5 !== '' && isLessThanFullWidth" [style.width.px]="row.Column5Width" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column5)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && row.Column5 !== '' && !isLessThanFullWidth" [ngClass]="getRowClass(row.DetailType)" [innerHTML]="columnValue(row.Column5)"></td>
        <td *ngIf="row.DetailType!=='Footnote' && columnCount >= 6  && row.Column5 ===''" [ngClass]=" { 'tableValue': row.DetailType!=='Heading' }">&nbsp;</td>
    </tr>
</table>
