import { Component, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "job-description",
    templateUrl: "./job-description.component.html",
    styleUrls: ["./job-description.component.less"]
})
export class JobDescriptionComponent {
    description: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    setDescription(description: string): void {
        this.description = this.sanitizer.bypassSecurityTrustHtml(description);
    }
}
