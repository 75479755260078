import { Component } from "@angular/core";

import { environment } from "@environment";

@Component({
    selector: "content-content-item",
    templateUrl: "./content-content-item.component.html",
    styleUrls: ["./content-content-item.component.less"]
})
export class ContentContentItemComponent {
    localImageLocation: string = environment.LocalImageLocation;

    id: string;
    title: string;
    description: string;

    languageCode: string;
}
