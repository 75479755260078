import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { GlobalService } from "@services/global.service";
import { SearchService } from "@services/search.service";
import { SearchResult } from "@models/search-result";

@Injectable()
export class SearchResultResolverService {
    constructor(private searchService: SearchService, private router: Router, private globalService: GlobalService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SearchResult> {
        const criteria = route.params["searchCriteria"];

        let languageId = route.paramMap.get("languageId");
        if (languageId && languageId !== "") {
            this.globalService.setLanguage(languageId);
        } else {
            languageId = this.globalService.getLanguage();
            if (!languageId) {
                this.globalService.setLanguage("en-us");
            }
        }

        return this.resolveSearch(criteria);
    }

    private resolveSearch(criteria: string): Promise<SearchResult> {
        return this.searchService.getResults(criteria)
            .toPromise()
            .then((result: SearchResult) => result);
    }
}
