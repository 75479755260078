import { Injectable } from "@angular/core";
import { Subject ,  Observable } from "rxjs";

@Injectable()
export class SortService {
    private columnSortedSource: Subject<ColumnSortedEvent> = new Subject<ColumnSortedEvent>();

    columnSorted$: Observable<ColumnSortedEvent> = this.columnSortedSource.asObservable();

    columnSorted(event: ColumnSortedEvent) {
        this.columnSortedSource.next(event);
    }
}

export interface ColumnSortedEvent {
    tableId: number;
    sortColumn: number;
    sortDirection: string;
    queryId: string;
}
