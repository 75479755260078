import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, shareReplay } from "rxjs";

import { environment } from "@environment";
import { GlobalService } from "./global.service";
import { HeaderInfo } from "@models/header-info";

@Injectable({ providedIn: 'root' })
export class HeaderService {
    private url: string = environment.ApiBase + "master/";

    private headerData: Observable<HeaderInfo>;

    constructor(private http: HttpClient, private globalService: GlobalService) { }

    getHeaderInfo(): Observable<HeaderInfo> {
        if (!this.headerData) {
            const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage(), } });
            this.headerData = this.http.get<HeaderInfo>(this.url + "headerinfo", { params: params}).pipe(shareReplay(1));
        }

        return this.headerData;
    }

    clearHeaderInfo(): void {
        this.headerData = null;
    }
}
