import { Component } from "@angular/core";

import { environment } from "@environment";

@Component({
    selector: "job-location",
    templateUrl: "./job-location.component.html",
    styleUrls: ["./job-location.component.less"]
})
export class JobLocationComponent {
    localImageLocation: string = environment.LocalImageLocation;

    title: string;
    text: string;

    languageCode: string;
}
