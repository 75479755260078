<table class="eventTable" *ngIf="detail !== undefined">
    <ng-container *ngFor="let event of detail.Events">
        <tr *ngIf="event.IsHeading" style="border-bottom: none !important"><td style="border-bottom: none !important">&nbsp;</td></tr>
        <tr *ngIf="event.IsHeading" class="eventHeading">
            <td *ngIf="event.IsHeading" colspan="4"><span [innerHTML]="getField(event.Title)"></span></td>
        </tr>
        <tr *ngIf="!event.IsHeading" class="eventDetail">
            <td>
                <a [routerLink]="['/' + languageCode, 'content', event.ContentId]" [queryParams]="{ dt: 1 }" class="eventLink">{{event.Title}}</a>
            </td>
            <td>
                <span [innerHTML]="getField(event.Name)"></span>
            </td>
            <td>
                {{event.Location}}<br/>
            </td>
            <td nowrap="nowrap">
                <span *ngIf="event.StartDate !== null">{{event.StartDate | date:'M/d/yyyy'}}</span>
                <span *ngIf="event.EndDate !== null">&nbsp;-&nbsp;{{event.EndDate | date:'M/d/yyyy'}}</span>
            </td>
        </tr>
    </ng-container>
</table>
