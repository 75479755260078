<div class="quicklinkContainer" *ngIf="links.length > 0">
    <div *ngIf="videoLinkExists()" style="width: 35px; float: left; margin-top: 8px !important; margin-bottom: 8px !important; margin-left: 8px !important; cursor: pointer !important">
        <a class="quicklinkVideo" [style.backgroundImage]="videoGalleryStyle" (mouseover)="hover('video')" (mouseout)="clear('video')" href="javascript:void(0)" (click)="onShowVideos()"></a>
    </div>
    <div *ngIf="imageLinkExists()" style="width: 35px; float: left; margin-top: 8px !important; margin-bottom: 8px !important; margin-left: 8px !important; cursor: pointer !important">
        <a class="quicklinkImage" [style.backgroundImage]="imageGalleryStyle" (mouseover)="hover('image')" (mouseout)="clear('image')" href="javascript:void(0)" (click)="onShowImages()"></a>
    </div>
</div>
<image-viewer [productTitle]="productTitle" [show]="showImages" [images]="images" (imageViewerClosed)="onImageViewerClosed($event)"></image-viewer>
<video-viewer [productTitle]="productTitle" [show]="showVideos" [videos]="videos" (videoViewerClosed)="onVideoViewerClosed($event)"></video-viewer>
