import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { IsSecureGuard } from "../is-secure.guard";
import { ContentComponent } from "./content/content.component";
import { ContentResolverService } from "./content-resolver.service";

const routes: Routes = [
    {
        path: ":languageId/content/:id",
        component: ContentComponent,
        resolve: { details: ContentResolverService },
        canActivate: [ IsSecureGuard ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContentRoutingModule { }
