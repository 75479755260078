import { Component, Input, OnInit } from "@angular/core";

import { environment } from "@environment";
import { LinkBaseComponent } from "../link-base/link-base.component";

@Component({
    selector: "dq-image-link",
    templateUrl: "./image-link.component.html",
    styleUrls: ["./image-link.component.less"]
})
export class ImageLinkComponent extends LinkBaseComponent implements OnInit {
    @Input() src: string;
    @Input() width: number;
    @Input() height: number;

    get isValidImage(): boolean {
        return  this.src && this.src !== '' &&
                this.src !== environment.ImageLocation &&
                this.src !== environment.LocalImageLocation;
    }

    constructor() { super(); }

    ngOnInit() {
        const x = this.src;
    }
}
