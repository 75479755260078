import {firstValueFrom, throwError as observableThrowError } from "rxjs";
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, AfterContentInit } from "@angular/core";
import { UntypedFormGroup, AbstractControl } from "@angular/forms";
// import { Ng2ImgMaxService } from "ng2-img-max";
import { NgxImageCompressService } from "ngx-image-compress";

import { FormFieldBase } from "@models/form-fields";
import { FormService } from "@services/form.service";
import { GlobalService } from "@services/global.service";
import { UserService } from "@services/user.service";
import { ExpenseCategory } from "@models/expense-category";
import { ExpenseCurrency } from "@models/expense-currency";
import { environment } from "@environment";
import { UsnrDomain } from "@models/usnr-domain";

@Component({
    selector: "dynamic-form-field",
    templateUrl: "./dynamic-form-field.component.html",
    styleUrls: ["./dynamic-form-field.component.less"]
})
export class DynamicFormFieldComponent implements OnInit, AfterViewInit, AfterContentInit {
    @Input() field: FormFieldBase<any>;
    @Input() form: UntypedFormGroup;
    @Input() submitAttempt: boolean;
    private requireFile: boolean;
    private captchaValid: boolean = false;
    private domainList: UsnrDomain[] = [];

    @Output() clearForm: EventEmitter<void> = new EventEmitter<void>();

    @Output() uploadingFile: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _uploadingFile: boolean = false;
    get isUploadingFile(): boolean {
        return this._uploadingFile;
    }

    dynamicChoices: any[];

    defaultExpenseCurrency: string = "USD";

    localImageLocation: string = environment.LocalImageLocation;

    constructor(
        private formService: FormService,
        private globalService: GlobalService,
        private userService: UserService,
        private imageService: NgxImageCompressService
        // private imageService: Ng2ImgMaxService
    ) {
    }

    ngOnInit(): void {
        if (this.field.formId.toLowerCase() === "expenseform") {
            if (this.field.key.toLowerCase() === "category") {
                this.formService.getExpenseCategories().subscribe(
                    (categories: ExpenseCategory[]) => { this.dynamicChoices = categories; },
                    (error: any) => { this.handleError(error); }
                );
            } else if (this.field.key.toLowerCase() === "currency") {
                this.formService.getExpenseCurrencies().subscribe(
                    (currencies: ExpenseCurrency[]) => { this.dynamicChoices = currencies; },
                    (error: any) => { this.handleError(error); }
                );
            }
        }

        if (this.field.type === "file") {
            this.requireFile = this.field.required;
        }

        this.uploadingFile.emit(false);
        this._uploadingFile = false;
    }

    ngAfterViewInit(): void {
        this.captchaValid = false;
    }

    ngAfterContentInit(): void {
        this.captchaValid = false;
    }

    isInvalid(): boolean {
        const control: AbstractControl = this.form.get(this.field.key);

        let returnValue: boolean;

        if (this._uploadingFile) {
            returnValue = true;
        } else if (this.requireFile) {
            returnValue = this.submitAttempt && (this.form.controls["AttachedFile"].value === "");
        } else {
            returnValue = (!control.valid && this.submitAttempt);
        }

        return returnValue;
    }

    onCaptcha($event: any): void {
        this.form.controls[this.field.key].setValue($event);
    }

    onClearForm(): void {
        this.clearForm.emit();
    }

    // async handleEmailAddress($event: any): Promise<boolean> {
    //     if ($event) {
    //         const isUsnr: boolean = await this.isUsnrDomain($event.target.value.toLowerCase());
    //         if (this.field.formId === "UserLogin" && isUsnr) {
    //             // if (!this.userService.isAuthenticated()) {
    //                 this.userService.loginUsnrUser();
    //             // }

    //             return true;
    //         }
    //     }
    //     return false;
    // }

    private async getDomainList(): Promise<void> {
        await this.globalService.getDomainList().then(
            (domainList: UsnrDomain[]) => { this.domainList = domainList; }
        );
    }

    private async isUsnrDomain(emailAddress: string): Promise<boolean> {
        if (!this.domainList.length) {
            await this.getDomainList();
        }

        const domainList: UsnrDomain[] = this.domainList.filter((domain: UsnrDomain) => {
            if (emailAddress.endsWith("@" + domain.Id)) {
                return domain;
            }
         });

         return domainList.length > 0;
    }

    attachFileChange($event: any): void {
        const fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            this.uploadingFile.emit(true);
            this._uploadingFile = true;

            const formData: FormData = new FormData();
            formData.append("file[]", fileList[0]);

            this.formService.uploadFile("", formData).subscribe(
                (fileInfo: any) => {
                    this.form.controls["AttachedFile"].setValue(fileInfo.FileName);
                    this.form.controls["AttachedFileOriginalName"].setValue(fileInfo.OriginalFileName);
                    this.form.controls["AttachedFileType"].setValue(fileInfo.FileType);
                    this.uploadingFile.emit(false);
                    this._uploadingFile = false;
                },
                (error: any) => {
                    this.uploadingFile.emit(false);
                    this._uploadingFile = false;
                    this.handleError(error);
                }
            );
        }
    }

    attachImageChange($event: any): void {
        const fileList: FileList = $event.target.files;
        let localUrl: string;
        if (fileList.length > 0) {
            this.uploadingFile.emit(true);
            this._uploadingFile = true;

            const file: File = fileList[0];
            const reader: FileReader = new FileReader();
            reader.onload = ((event: any) => {
                localUrl = event.target.result;
            });
            reader.readAsDataURL($event.target.files[0]);

            const imageSize: number = file.size;
            if (imageSize > (1024 * 1024)) {    // bigger than 1Mb
                this.compressAndUploadImage(localUrl, ((1024 * 1024) / imageSize) * 100, file);
            }

            // this.imageService.compressImage(fileList[0], 1).subscribe(
            //     (result) => {
            //         const formData: FormData = new FormData();
            //         const compressedFile: File = <File>result;
            //         formData.append("file[]", compressedFile);

            //         this.formService.uploadFile("", formData).subscribe(
            //             (fileInfo: any) => {
            //                 this.form.controls["AttachedFile"].setValue(fileInfo.FileName);
            //                 this.form.controls["AttachedFileOriginalName"].setValue(fileList[0].name);
            //                 this.form.controls["AttachedFileType"].setValue(fileInfo.FileType);
            //                 this.uploadingFile.emit(false);
            //                 this._uploadingFile = false;
            //             },
            //             (internalError: any) => { this.handleError(internalError); }
            //         );
            //     },
            //     (error: any) => {
            //         // handle error and send whole file?
            //         const formData: FormData = new FormData();
            //         formData.append("file[]", fileList[0]);

            //         this.formService.uploadFile("", formData).subscribe(
            //             (fileInfo: any) => {
            //                 this.form.controls["AttachedFile"].setValue(fileInfo.FileName);
            //                 this.form.controls["AttachedFileOriginalName"].setValue(fileList[0].name);
            //                 this.form.controls["AttachedFileType"].setValue(fileInfo.FileType);
            //                 this.uploadingFile.emit(false);
            //                 this._uploadingFile = false;
            //             },
            //             (internalError: any) => { this.handleError(internalError); }
            //         );
            //     }
            // );
        }
    }

    private compressAndUploadImage(localFile: string, compressRatio: number, file: File): void {
        this.imageService.compressFile(localFile, -1, compressRatio).then(result => {
            // const image: string = result.split(",")[1];
            // const imageBlob = this.dataUriToBlob(image, file.type);
            const compressedFile: File = new File([result], file.name, { type: file.type });

            const formData: FormData = new FormData();
            formData.append("file[]", compressedFile);

            this.formService.uploadFile("", formData).subscribe(
                (fileInfo: any) => {
                    this.form.controls["AttachedFile"].setValue(fileInfo.FileName);
                    this.form.controls["AttachedFileOriginalName"].setValue(file.name);
                    this.form.controls["AttachedFileType"].setValue(fileInfo.FileType);
                    this.uploadingFile.emit(false);
                    this._uploadingFile = false;
                },
                (internalError: any) => { this.handleError(internalError); }
            );
        });
    }

    private dataUriToBlob(uri: string, fileType: string): Blob {
        const byteString: string = window.atob(uri);
        const buffer: ArrayBuffer = new ArrayBuffer(byteString.length);
        const intArray: Uint8Array = new Uint8Array(buffer);
        for (let i: number = 0; i < byteString.length; ++i) {
            intArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([intArray], { type: fileType});
    }

    // setDefault(): void {
    //     if (this.field.formId.toLowerCase() === "expenseform") {
    //         if (this.field.key.toLowerCase() === "currency") {
    //             this.field.value = this.defaultExpenseCurrency;
    //         }
    //     }
    // }

    private handleError(error: any) {
        console.error("An error occurred", error);
        observableThrowError(error);
    }
}
