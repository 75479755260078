import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from '@environment';
import { MainMenuItem } from '@models/main-menu-item';
import { Language } from '@models/language';
import { FooterInfo } from '@models/footer-info';
import { GlobalService } from '@services/global.service';
import { UserService } from '@services/user.service';
import { FooterService } from '@services/footer.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private userSubscription: Subscription;
    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;

    menuItems: MainMenuItem[];
    languages: Language[];
    footerInfo: FooterInfo;
    languageCode: string;

    constructor(
        private globalService: GlobalService,
        private footerService: FooterService,
        private userService: UserService
    ) {
        this.languageSubscription = globalService.languageId$.subscribe(() =>
            this.ngOnInit()
        );

        this.userSubscription = this.userService.currentUser$.subscribe(() =>
            this.ngOnInit()
        );
    }

    ngOnInit() {
        this.footerService.getFooter().subscribe(
            (footer: FooterInfo) => {
                this.footerInfo = footer;
            },
            (error: any) => {
                this.handleError(error);
            }
        );

        this.globalService.getLanguages().subscribe(
            (languages: Language[]) => {
                this.languages = languages;
            },
            (error: any) => {
                this.handleError(error);
            }
        );

        this.globalService.getMainMenuItems().subscribe(
            (menu: MainMenuItem[]) => {
                this.menuItems = menu;
            },
            (error: any) => {
                this.handleError(error);
            }
        );

        this.languageCode = this.globalService.getLanguageCode();
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    protected onSelectLanguage(language: Language): void {
        this.globalService.setLanguage(language.Id);
    }

    private handleError(error: any): void {
        console.error('An error occurred', error);
    }
}
