<div class="headerContainer">
    <div *ngIf="carouselItems.length > 0; else staticImage">
        <carousel [autoPlay]="true" [interval]="5000" [images]="carouselItems"></carousel>
    </div>
    <ng-template #staticImage>
        <div *ngIf="header" class="headerImage">
            <div style="position: relative;">
                <img class="base" src="{{imageLocation}}{{header.ImageLink}}" alt="{{header.Title}}" />
                <img *ngIf="header.WatermarkLink && header.WatermarkLink !== ''" class="watermark" src="{{imageLocation}}{{header.WatermarkLink}}" />
            </div>
            <div style="clear: both"></div>
            <div class="headerImageContainer">
                <div class="headerImageTitle">
                    <table class="headerImageTitleTable">
                        <tbody>
                        <tr>
                            <td class="headerImageTitleColumn">
                                <h2>{{header.Title}}</h2>
                            </td>
                            <td class="socialLinkColumn">
                                <ul>
                                    <li *ngFor="let link of header.SocialLinks" class="socialLink">
                                        <a href="{{link.Url}}" class="socialLinkItem" [style.background-image]="socialLinkBackground(link)"></a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>
</div>
