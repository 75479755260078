import { Component } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { environment } from "@environment";
import { ContentDetail } from "@models/content-detail";

@Component({
    selector: "content-image",
    templateUrl: "./content-image.component.html",
    styleUrls: ["./content-image.component.less"]
})
export class ContentImageComponent {
    imageLocation: string = environment.ImageLocation;

    detail: ContentDetail;

    constructor(private sanitizer: DomSanitizer) { }

    private imageWidth(): number {
        if ((this.detail.DetailType === "Inline Image Right" || this.detail.DetailType === "Inline Image Left") &&
            (this.detail.FileInfo)) {
            return this.detail.FileInfo.Width > 300 ? 300 : this.detail.FileInfo.Width;
        }

        return 540;
    }

    private description(): SafeHtml {
        return this.detail.Description === "" ? "" : this.sanitizer.bypassSecurityTrustHtml(this.detail.Description);
    }
}
