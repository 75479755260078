import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, of } from 'rxjs';

import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private baseUserUrl: string = environment.ApiBase + "users/";

    constructor(private _http: HttpClient, private _userService: UserService) {}

    // isLoggedInAndAccountSelected(): Observable<boolean> {
    //     if (!this.isAccountSelected()) {
    //         return of(false);
    //     }

    //     return this._http.get<boolean>(`${this.baseUserUrl}signed-in`);
    // }

    isLoggedIn(): Observable<boolean> {
        return this._http.get<boolean>(`${this.baseUserUrl}signed-in`);
    }

    // isAccountSelected(): boolean {
    //     return (this._userService.getCustomerAccount() !== null);
    // }
}
