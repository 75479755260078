<privacy-alert-check></privacy-alert-check>
<shared-header pageType="page"></shared-header>
<div class="contentMenu">
    <content-menu></content-menu>
    <general-features featureType="local"></general-features>
</div>
<div class="contentDetail">
    <div class="contentDetailContent">
        <content-detail [details]="details"></content-detail>
    </div>
</div>
<div class="contentFeatures">
    <standard-features></standard-features>
</div>
