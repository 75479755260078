<p *ngIf="(!rows || rows.length === 0) && showNoResultsMessage">{{noResultsText}}</p>
<table class="resultsList">
    <tr *ngFor="let row of rows; let idx = index;">
        <ng-container *ngIf="listType !== 'Text'">
            <td class="resultsListTableLabel" [ngClass]="{'resultsListTableItem': (listType==='Table')}">
                {{row.label}}
            </td>
            <td [style.text-align]="row.alignment" [ngClass]="{'resultsListTableItem': (listType==='Table')}">
                <!--<span *ngIf="row.specialType !== 'image'">
                    {{row.value}}
                </span>
                <span *ngIf="row.specialType === 'image'">
                    <img [src]="'data:image/gif;base64,' + row.value" alt=""
                        [ngStyle]="{'height': row.imageHeight > 0 ? row.imageHeight + 'px' : -1,
                                    'width': row.imageWidth > 0 ? row.imageWidth + 'px' : -1}" />
                </span>-->
                <ng-container *ngIf="row.specialType === 'image'; else nonFilestream">
                    <img *ngIf="row.value && row.value !== ''" [src]="'data:image/gif;base64,' + row.value" alt="" [ngStyle]="{'height': row.imageHeight > 0 ? row.imageHeight + 'px' : -1,
                        'width': row.imageWidth > 0 ? row.imageWidth + 'px' : -1}" />
                </ng-container>
                <ng-template #nonFilestream>
                    <ng-container *ngIf="row.specialType === 'iostring'">
                        <span *ngIf="row.value !== ''">{{row.value}}</span>
                        <input *ngIf="!row.value && row.value === ''" [id]="row.name + '_'" type="text" />
                    </ng-container>
                    <ng-container *ngIf="(!row.linkTarget || row.linkTarget === '') && row.specialType !== 'iostring' && row.imageWidth === -1">
                        <span>{{row.value}}</span>
                    </ng-container>
                    <ng-container *ngIf="(!row.linkTarget || row.linkTarget === '') && row.specialType !== 'iostring' && row.imageWidth > -1">
                        <span *ngIf="row.value && row.value !== ''  ">
                            <img [src]="imageLocation + row.value" alt='' />
                        </span>
                    </ng-container>
                    <ng-container *ngIf="row.linkTarget && row.linkTarget !== ''">
                        <span *ngIf="row.linkTarget === 'image'">
                            <a [routerLink]="[ row.destinationRoute ]" [queryParams]="row.destinationRouteParams">
                                <img [src]="imageLocation + row.value" alt='' />
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'imageUrl'">
                            <a [href]="row.destinationRoute">
                                <img [src]="imageLocation + row.value" alt='' />
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'anchor'">
                            <a [routerLink]="[ row.destinationRoute ]" [queryParams]="row.destinationRouteParams">{{row.value}}</a>
                        </span>
                        <span *ngIf="row.linkTarget === 'anchorUrl'">
                            <a [href]="row.destinationRoute">{{row.value}}</a>
                        </span>
                        <span *ngIf="row.linkTarget === 'dataquery/image'">
                            <a href="javascript:void(0);" (click)="executeDataQuery(row.destinationRouteParams, idx)">
                                <img [src]="imageLocation + row.value" alt="" />
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'dataquery/string'">
                            <a href="javascript:void(0);" (click)="executeDataQuery(row.destinationRouteParams, idx)">
                                {{row.value}}
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'filestream/string'">
                            <a href="javascript:void(0);" (click)="getFilestream(row.destinationRoute, row.destinationRouteParams, row.specialType)">
                                {{row.value}}
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'filestream/image'">
                            <a href="javascript:void(0);">
                                <img [src]="sanitizer.bypassSecurityTrustResourceUrl(row.value)" alt='' />
                            </a>
                        </span>
                    </ng-container>
                </ng-template>
            </td>
        </ng-container>
        <ng-container *ngIf="listType === 'Text'">
            <td>
                <label class="resultsListTextLabel">{{row.label}}</label>
                <!-- <span *ngIf="row.specialType !== 'image'">
                    {{row.value}}
                </span> -->
                <!-- <span *ngIf="row.specialType === 'image'">
                    <img [src]="'data:image/gif;base64,' + row.value" alt=""
                        [ngStyle]="{'height': row.imageHeight > 0 ? row.imageHeight + 'px' : -1,
                                    'width': row.imageWidth > 0 ? row.imageWidth + 'px' : -1}" />
                </span> -->
                <ng-container *ngIf="row.specialType === 'image'; else nonFilestream">
                    <img [src]="'data:image/gif;base64,' + row.value" alt="" [ngStyle]="{'height': row.imageHeight > 0 ? row.imageHeight + 'px' : -1,
                                                            'width': row.imageWidth > 0 ? row.imageWidth + 'px' : -1}" />
                </ng-container>
                <ng-template #nonFilestream>
                    <ng-container *ngIf="!row.linkTarget || row.linkTarget === ''">
                        <span>{{row.value}}</span>
                    </ng-container>
                    <ng-container *ngIf="row.linkTarget && row.linkTarget !== ''">
                        <span *ngIf="row.linkTarget === 'image'">
                            <a [routerLink]="[ row.destinationRoute ]" [queryParams]="row.destinationRouteParams">
                                <img [src]="imageLocation + row.value" alt='' />
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'imageUrl'">
                            <a [href]="row.destinationRoute">
                                <img [src]="imageLocation + row.value" alt='' />
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'anchor'">
                            <a [routerLink]="[ row.destinationRoute ]" [queryParams]="row.destinationRouteParams">{{row.value}}</a>
                        </span>
                        <span *ngIf="row.linkTarget === 'anchorUrl'">
                            <a [href]="row.destinationRoute">{{row.value}}</a>
                        </span>
                        <span *ngIf="row.linkTarget === 'filestream/string'">
                            <a href="javascript:void(0);" (click)="getFilestream(row.destinationRoute, row.destinationRouteParams, row.specialType)">
                                {{row.value}}
                            </a>
                        </span>
                        <span *ngIf="row.linkTarget === 'filestream/image'">
                            <a href="javascript:void(0);" (click)="getFilestream(row.destinationRoute, row.destinationRouteParams, row.specialType)">
                                <img [src]="sanitizer.bypassSecurityTrustResourceUrl(row.value)" alt='' />
                            </a>
                        </span>
                    </ng-container>
                </ng-template>
            </td>
        </ng-container>
    </tr>
</table>
