import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver";

import { environment } from "@environment";
import { ProductDetail } from "@models/product-detail";
import { ProductService } from "@services/product.service";
import { UploadFileInfo } from "@models/upload-file-info";
import { FileInfo } from "@models/file-info";

@Component({
    selector: "product-ad",
    templateUrl: "./product-ad.component.html",
    styleUrls: ["./product-ad.component.less"]
})
export class ProductAdComponent implements OnInit {
    documentLocation: string = environment.DocumentLocation;
    details: ProductDetail[];

    sectionDetails: ProductDetail[];
    sectionHeader: ProductDetail;

    showUpload: boolean;

    uploadDocumentType: string;
    uploadDocumentFile: string;
    uploadDocumentDescription: string;

    uploadedFileName: string;
    uploadedFileType: string;
    uploadedOriginalFileName: string;

    constructor(private productService: ProductService) { }

    ngOnInit() {
        this.findHeader();
    }

    private findHeader(): void {
        if (this.details) {
            const header = this.details.filter(x => x.IsHeading);
            if (header) {
                this.sectionHeader = header[0];
            }

            this.sectionDetails = this.details.filter(x => !x.IsHeading);
        }
    }

    getFile(documentType: string, fileInfo: FileInfo): void {
        if (fileInfo) {
            this.productService.getFile(documentType, "InternalDoc", fileInfo.FileName).subscribe(
                (data: Blob) => { saveAs(data, fileInfo.FileName); }
            );
        }
    }

    toggleUpload(): void {
        this.showUpload = !this.showUpload;
    }

    fileChanged($event: any): void {
        const fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            const formData: FormData = new FormData();
            formData.append("file[]", fileList[0]);

            this.productService.uploadFile(formData).subscribe(
                (fileInfo: any) => {
                    this.uploadedFileName = fileInfo.FileName;
                    this.uploadedFileType = fileInfo.FileType;
                    this.uploadedOriginalFileName = fileInfo.OriginalFileName;
                }
            );
        }
    }

    uploadFile(): void {
        const fileData: UploadFileInfo = {
            detailType:  "Ad",
            documentType: "",
            documentDescription: this.uploadDocumentDescription,
            fileType: this.uploadedFileType,
            fileName: this.uploadedFileName,
            originalFileName: this.uploadedOriginalFileName
        };

        this.productService.newDocument(this.details[0].Id, fileData).subscribe(
            () => {
                this.showUpload = false;
                // trigger refresh of list...
                const detail: ProductDetail = new ProductDetail();
                detail.Other = this.uploadedFileType;
                detail.DocumentType = this.uploadDocumentType;
                detail.Title = this.uploadedOriginalFileName;

                this.details.push(detail);

                this.findHeader();
            }
        );
    }
}
