import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { PageDetail } from '@models/page-detail';

@Component({
    selector: 'events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.less'],
})
export class EventsComponent {
    detail: PageDetail;
    languageCode: string;

    constructor(private sanitizer: DomSanitizer) {}

    protected getField(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
