import { Component } from "@angular/core";

@Component({
    selector: "content-manual-link",
    templateUrl: "./content-manual-link.component.html",
    styleUrls: ["./content-manual-link.component.less"]
})
export class ContentManualLinkComponent {
    title: string;
    description: string;
    other: string;
}
