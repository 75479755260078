<div class="d-sm-none d-md-block">
    <div *ngIf="detail.VideoUrl !== ''" class="videoContainer" [style.height.px]="detail.VideoHeight" [style.width.px]="detail.VideoWidth" (mouseenter)="showDownloadLink(true)" (mouseleave)="showDownloadLink(false)">
        <div *ngIf="detail.VideoDownloadUrl && detail.VideoDownloadUrl !== ''" [hidden]="!showDownloadUrl" class="downloadButton">
            <a [href]="detail.VideoDownloadUrl">
                <img [src]="localImageLocation + 'download-36.png'" height="36px" />
            </a>
        </div>
        <video-container [width]="detail.VideoWidth" [height]="detail.VideoHeight" [src]="getVideo()"></video-container>
    </div>
</div>
<div class="d-sm-block d-md-none">
    <div class="mobileVideoContainer" *ngIf="detail.VideoUrl !== ''">
        <iframe class="mobileVideoFrame" [width]="detail.VideoWidth" [height]="detail.VideoHeight" [src]="getVideo()" frameborder="0"
            webkitallowfullscreen mozallowfullscreen allowfullscreen>
        </iframe>
      </div>
</div>
