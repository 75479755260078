import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { ProductDetailGroup } from "@models/product-detail-group";
import { ProductService } from "@services/product.service";
import { GlobalService } from "@services/global.service";

@Injectable()
export class ProductResolverService  {
    constructor(private productService: ProductService, private globalService: GlobalService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ProductDetailGroup[]> {
        const productId: string = route.params["id"] as string;
        if (productId === null) {
            this.router.navigate([this.globalService.getLanguageCode(), "page", "error"], { queryParams: { dt: 1, returnUrl: state.url } });
        }

        let languageId = route.paramMap.get("languageId");
        if (languageId && languageId !== "") {
            this.globalService.setLanguage(languageId);
        } else {
            languageId = this.globalService.getLanguage();
            if (!languageId) {
                this.globalService.setLanguage("en-us");
            }
        }

        return this.resolveProduct(productId);
    }

    private resolveProduct(productId: string): Promise<ProductDetailGroup[]> {
        return this.productService.getTabs(productId)
            .toPromise()
            .then(tabs => {
                if (tabs === undefined || tabs === null) {
                    this.router.navigate([this.globalService.getLanguageCode(), "page", "error"], {queryParams: { dt: 1 }});
                }

                return tabs;
            });
    }
}
