<div class="menuContainer">
    <table>
        <tr *ngFor="let menuItem of menu">
            <td [ngClass]="getClass(menuItem)">
                <a [routerLink]="['/' + languageCode, menuItem.DestinationType.toLowerCase(), menuItem.Id]" [queryParams]="{ dt: 1 }">
                    <span [ngStyle]="{ 'padding-left.px': ((menuItem.Level*10)+10) }">{{menuItem.Title}}</span>
                </a>
            </td>
        </tr>
    </table>
</div>
