import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";

import { ProductMenu } from "@models/menu-item";
import { GlobalService } from "@services/global.service";
import { ProductService } from "@services/product.service";

@Component({
    selector: "product-menu",
    templateUrl: "./product-menu.component.html",
    styleUrls: ["./product-menu.component.less"]
})
export class ProductMenuComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private routeSubscription: Subscription;

    menu: ProductMenu[];
    productId: string;
    selectedMenu: ProductMenu;

    languageCode: string;

    constructor(private globalService: GlobalService, private productService: ProductService, private route: ActivatedRoute) {
        this.subscription = globalService.languageId$.subscribe(
            () => { this.ngOnInit(); }
        );
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe(
            (params: Params) => {
                this.productId = params["id"];

                this.productService.getMenu(params["id"]).subscribe(
                    (menu: ProductMenu[]) => {
                        this.menu = menu;
                        if (menu) {
                            this.selectedMenu = menu.find(product => product.Id.toLowerCase() === this.productId.toLowerCase());
                        }
                    });
                }
            );

        this.languageCode = this.globalService.getLanguageCode();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    getClass(item: ProductMenu) {
        let cssClass: any;

        if (item.Id.toLowerCase() === this.selectedMenu.Id.toLowerCase()) {
            cssClass = { "menuSelected": true, "menuChild": false, "menuParent": false, "menuSibling": false };
        } else if (item.Level < this.selectedMenu.Level) {
            cssClass = { "menuSelected": false, "menuChild": false, "menuParent": true, "menuSibling": false };
        } else if (item.Level > this.selectedMenu.Level) {
            cssClass = { "menuSelected": false, "menuChild": true, "menuParent": false, "menuSibling": false };
        } else {    // item.Level === this.selectedMenu.Level
            cssClass = { "menuSelected": false, "menuChild": false, "menuParent": false, "menuSibling": true };
        }

        return cssClass;
    }
}
