import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environment";
import { GlobalService } from "./global.service";
import { ProductDetailGroup } from "@models/product-detail-group";
import { ProductDetail } from "@models/product-detail";
import { ProductInternalDocumentType } from "@models/product-internal-document-type";
import { ProductInternalDetails } from "@models/product-internal-details";
import { ProductQuickLink } from "@models/product-quick-link";
import { UploadFileInfo } from "@models/upload-file-info";
import { ProductMenu } from '@models/menu-item';

@Injectable()
export class ProductService {
    private baseUrl: string = environment.ApiBase + "product/";

    constructor(private http: HttpClient, private globalService: GlobalService) { }

    getTabs(id: string): Observable<ProductDetailGroup[]> {
        return this.http.get<ProductDetailGroup[]>(`${this.baseUrl}detail/${id}/groups`, { params: this.createParams() });
    }

    getDetails(id: string): Observable<ProductDetail[]> {
        return this.http.get<ProductDetail[]>(`${this.baseUrl}detail/${id}`, { params: this.createParams() });
    }

    getInternalDocumentTypes(): Observable<ProductInternalDocumentType[]> {
        return this.http.get<ProductInternalDocumentType[]>(this.baseUrl + "detail/internaldoctypes");
    }

    getChildren(id: string): Observable<ProductDetail[]> {
        return this.http.get<ProductDetail[]>(`${this.baseUrl}detail/${id}/children`, { params: this.createParams() });
    }

    getRelated(id: string): Observable<ProductDetail[]> {
        return this.http.get<ProductDetail[]>(`${this.baseUrl}detail/${id}/related`, { params: this.createParams() });
    }

    getInternalDetails(id: string): Observable<ProductInternalDetails> {
        return this.http.get<ProductInternalDetails>(`${this.baseUrl}detail/${id}/internal`, { params: this.createParams() });
    }

    getMenu(id: string): Observable<ProductMenu[]> {
        return this.http.get<ProductMenu[]>(`${this.baseUrl}menu/${id}`, { params: this.createParams() });
    }

    getQuicklinks(id: string): Observable<ProductQuickLink[]> {
        return this.http.get<ProductQuickLink[]>(`${this.baseUrl}quicklinks/${id}`, { params: this.createParams() });
    }

    getFile(fileType: string, documentSection: string, fileName: string): Observable<Blob> {
        const header: HttpHeaders = new HttpHeaders(
            { "Content-Type": "application/octet-stream" }
        );

        const params: HttpParams = new HttpParams({
            fromObject: {
                fileName: fileName
            }
        });

        return this.http.get(`${this.baseUrl}file/${documentSection}`, { params: params, responseType: "blob" });
    }

    uploadFile(data: FormData): Observable<Object> {
        return this.http.post(this.baseUrl + "file", data);
    }

    newDocument(productId: string, fileData: UploadFileInfo): Observable<Object> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        return this.http.post(`${this.baseUrl}upload/${productId}`, fileData, { headers: headers });
    }

    private createParams(): HttpParams {
        return new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });
    }

    getVideoThumbnails(videoId: string): Observable<string> {
        return this.http.get("https://vimeo.com/api/v2/video/" + videoId + ".json").pipe(map((res: any) => {
            return "<img src=\"" + res[0]["thumbnail_large"] + "\"/>";
        }));
    }
}
