import { Component } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { TableSpecification } from "@models/table-specification";

@Component({
    selector: "content-table",
    templateUrl: "./content-table.component.html",
    styleUrls: ["./content-table.component.less"]
})
export class ContentTableComponent {
    rows: TableSpecification[];
    isLessThanFullWidth: boolean;
    columnCount: number;

    constructor(private sanitizer: DomSanitizer) {}

    prepareTable(): void {
        if (this.rows !== undefined && this.rows.length > 0) {
            let totalWidth: number = 0;

            totalWidth += this.rows[0].TitleWidth;
            totalWidth += this.rows[0].Column1Width;
            totalWidth += this.rows[0].Column2Width;
            totalWidth += this.rows[0].Column3Width;
            totalWidth += this.rows[0].Column4Width;
            totalWidth += this.rows[0].Column5Width;

            this.isLessThanFullWidth = (totalWidth <= 540);

            this.columnCount = this.rows[0].NumberOfColumns + 1;
        }
    }

    private getRowClass(type: string): string {
        if (type === "Footnote") {
            return "tableFooter";
        }
        if (type === "Heading") {
            return "tableHeading";
        }

        return "tableValue";
    }

    private columnValue(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
