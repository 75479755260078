import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

declare var enableClickDimensions: any;

import { GlobalService } from "@services/global.service";
import { JobDetail } from "@models/job-detail";

@Component({
    selector: "job",
    templateUrl: "./job.component.html",
    styleUrls: ["./job.component.less"]
})
export class JobComponent implements OnInit, OnDestroy, AfterViewInit {
    private languageSubscription: Subscription;
    private routeSubscription: Subscription;
    details: JobDetail[] = [];

    constructor(private route: ActivatedRoute, private globalService: GlobalService) {
        this.languageSubscription = this.globalService.languageId$.subscribe(
            () => { this.ngOnInit(); }
        );
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.data
            .subscribe((data: { details: JobDetail[] }) => this.details = data.details);
    }

    ngOnDestroy(): void {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.globalService.cookiesEnabled()) {
            enableClickDimensions();
        }
    }
}
