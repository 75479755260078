import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { environment } from "@environment";
import { GlobalService } from "@services/global.service";
import { UserService } from "@services/user.service";
import { CarouselItem } from "@models/carousel-item";
import { SharedHeader } from "@models/shared-header";
import { SharedService } from "@services/shared.service";
import { SocialLink } from "@models/social-link";

@Component({
    selector: "shared-header",
    templateUrl: "./shared-header.component.html",
    styleUrls: ["./shared-header.component.less"]
})
export class SharedHeaderComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private userSubscription: Subscription;
    private routeSubscription: Subscription;
    private url: string;

    languageCode: string;
    imageLocation: string = environment.ImageLocation;
    carouselItems: CarouselItem[] = [];

    @Input() pageType: string;

    header: SharedHeader;

    constructor(
        private globalService: GlobalService,
        private sharedService: SharedService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private userService: UserService
    ) {
        this.languageSubscription = globalService.languageId$.subscribe(
            () => { this.ngOnInit(); }
        );

        this.userSubscription = this.userService.currentUser$.subscribe(
            () => this.ngOnInit()
        );
    }

    ngOnInit() {
        this.languageCode = this.globalService.getLanguageCode();

        const id: string = this.route.snapshot.params["id"];
        const searchCriteria: string = this.route.snapshot.params["searchCriteria"];
        if (searchCriteria) {
            this.routeSubscription = this.route.params.subscribe(
                (params: Params) => {
                    this.sharedService.getHeader("search")
                        .subscribe((header: SharedHeader) => this.setupHeader(header));
                }
            );
        } else {
            this.routeSubscription = this.route.params.subscribe(
                (params: Params) => {
                    this.sharedService.getHeader(params["id"])
                        .subscribe((header: SharedHeader) => this.setupHeader(header));

                    this.globalService.addLanguageTags(this.route.snapshot.url.slice(1));
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    private setupHeader(header: SharedHeader) {
        this.carouselItems = [];
        if (header && header.Features !== null && header.Features !== undefined && header.Features.length > 0) {
            header.Features.forEach((feature) => {
                const item: CarouselItem = new CarouselItem();

                item.title = feature.Title;
                item.imageUrl = this.imageLocation + feature.MediaFileName;
                item.targetType = feature.TargetType;
                item.target = feature.Target;
                item.linkCausesNewWindow = feature.LinkCausesNewWindow;

                this.carouselItems.push(item);
            });

            this.globalService.setTitle(`USNR - ${header.Title}`);
        } else {
            this.header = header;

            const url: string = window.location.href;

            this.header.SocialLinks.forEach(((link: SocialLink) => {
                link.Url = this.replaceString(link.Url, "{Title}", this.header.Title);
                link.Url = this.replaceString(link.Url, "{URL}", url);
            }));

            this.globalService.setTitle(`USNR - ${this.header.Title}`);
        }
    }

    private replaceString(original, pattern, value): string {
        return original.replace(new RegExp(pattern), value);
    }

    socialLinkBackground(link: SocialLink): SafeHtml {
        return this.sanitizer.bypassSecurityTrustStyle("url(" + environment.ImageLocation + link.ImageFileName + ")");
    }
}
