import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageDetail } from '@models/page-detail';
import { PageSummary } from '@models/page-summary';
import { GlobalService } from '@services/global.service';
import { PageService } from '@services/page.service';

export class PrivacyMessageData {
    Message: string;
    LinkText: string;
    ButtonText: string;
}

@Component({
    selector: 'privacy-alert-dialog',
    templateUrl: './privacy-alert-dialog.component.html',
    styleUrls: ['./privacy-alert-dialog.component.less'],
})
export class PrivacyAlertDialogComponent {
    privacyMessage: string = '';
    privacyLinkText: string = '';
    privacyButtonText: string = '';

    showLearnMore: boolean = false;
    learnMoreText: SafeHtml;

    get language(): string {
        return this._globalService.getLanguage();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PrivacyMessageData,
        private _dialogRef: MatDialogRef<PrivacyAlertDialogComponent>,
        private _globalService: GlobalService,
        private _pageService: PageService,
        private _sanitizer: DomSanitizer
    ) {
        this.privacyMessage = data.Message;
        this.privacyLinkText = data.LinkText;
        this.privacyButtonText = data.ButtonText;
    }

    onButtonClick(): void {
        this._dialogRef.close();
    }

    onShowLearnMore(): void {
        this.showLearnMore = true;
        let learnMoreText: string = '';
        this._pageService.getDetails('privacy').subscribe((summary: PageSummary) => {
                learnMoreText = `<p style="background: none repeat scroll 0 0 #EEEEEE; height: 18px; line-height: 29px; margin-bottom: 5px !important;
                    padding-left: 3px !important;padding-bottom: 10px;font: 14px Arial, Sans-Serif !important; padding-top: 5px; padding-bottom: 3px;"><span>${this.privacyLinkText}</span></p>`;
                summary.Details.forEach((detail: PageDetail) => {
                if (detail.DetailType.toLowerCase() === 'description') {
                    learnMoreText += `<p>${detail.Description}</p>`;
                } else if (detail.DetailType.toLowerCase() === 'summary') {
                    this.privacyLinkText = detail.Title;
                }
            });

            this.learnMoreText = this._sanitizer.bypassSecurityTrustHtml(learnMoreText);
        });
    }
}
