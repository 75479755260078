import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { trigger, state, style, transition, animate, stagger, keyframes, query, } from '@angular/animations';

import { UserService } from '@services/user.service';
import { GlobalService } from '@services/global.service';
import { StandardFeature } from '@models/standard-feature';
import { BaseComponent } from 'src/app/base/base.component';
import { HeaderService } from '@services/header.service';
import { HeaderInfo } from '@models/header-info';
import { environment } from '@environment';

@Component({
    selector: 'mobile-footer',
    templateUrl: './mobile-footer.component.html',
    styleUrls: ['./mobile-footer.component.less'],
    animations: [
        trigger('footerToggler', [
            state('inactive', style({ transform: 'rotate(0deg)' })),
            state('active', style({ transform: 'rotate(225deg)' })),
            transition('*=>*', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('speedDialStagger', [
            transition('*=>*', [
                query(':enter', style({ opacity: 0 }), { optional: true }),
                query(
                    ':enter',
                    stagger('40ms', [
                        animate(
                            '200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
                            keyframes([
                                style({ opacity: 0, transform: 'translateY(10px)' }),
                                style({ opacity: 1, transform: 'translateY(0)' }),
                            ])
                        ),
                    ]),
                    { optional: true }
                ),
                query(
                    ':leave',
                    animate(
                        '200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
                        keyframes([
                            style({ opacity: 1 }),
                            style({ opacity: 0 }),
                        ])
                    ),
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class MobileFooterComponent extends BaseComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private userSubscription: Subscription;
    private routeSubscription: Subscription;

    objectId: string;
    languageCode: string;
    featuresIconFile: string;

    features: StandardFeature[] = [];
    displayedFeatures: StandardFeature[] = [];
    headerInfo: HeaderInfo;
    imageLocation: string = environment.ImageLocation;

    featureState: string = 'inactive';

    constructor(
        private userService: UserService,
        private globalService: GlobalService,
        private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private headerService: HeaderService
    ) {
        super();

        this.subscriptions.push(this.userService.currentUser$.subscribe(() =>
            this.ngOnInit()
        ));
        this.subscriptions.push(this.globalService.languageId$.subscribe(
            () => this.ngOnInit()
        ));

        this.subscriptions.push(this.headerService.getHeaderInfo().subscribe({
                next: (info: HeaderInfo) => {
                    this.headerInfo = info;
                    this.featuresIconFile = this.headerInfo.FeaturesIconFileName;
                },
                error: (error: any) => {
                    this.handleError(error);
                },
            })
        );

        this.languageCode = this.globalService.getLanguageCode();
    }

    ngOnInit() {
        this.languageCode = this.globalService.getLanguageCode();

        this.routeSubscription = this.route.params.subscribe(
            (params: Params) => {
                this.objectId = params['id'];
                if (!this.objectId) {
                    if (params['searchCriteria']) {
                        this.objectId = 'search';
                    } else {
                        this.features = null;
                        return;
                    }
                }

                this.globalService.getStandardFeatures(this.objectId).subscribe(
                    (features: StandardFeature[]) => {
                        this.features = features;
                        this.initializeFeatures();
                    },
                    (error: any) => {
                        this.handleError(error);
                    }
                );
            }
        );
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    showFeatures(): void {
        this.featureState = 'active';
        this.displayedFeatures = this.features;
    }

    hideFeatures(): void {
        this.featureState = 'inactive';
        this.displayedFeatures = [];
    }

    toggleFeatures(): void {
        this.displayedFeatures.length
            ? this.hideFeatures()
            : this.showFeatures();
    }

    protected gotoInternal(feature: StandardFeature): void {
        this.router.navigate(
            ['/' + this.languageCode, feature.TargetType, feature.Target],
            {
                queryParams: {
                    referringPage: this.getCurrentPageId(),
                    referringType: this.getCurrentPageType(),
                },
            }
        );
    }

    protected gotoExternal(feature: StandardFeature): void {
        this.router.navigateByUrl(feature.Target);
    }

    protected getField(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    private getCurrentPageId(): string {
        const id: string = this.route.snapshot.params['id'];
        if (id) {
            return id;
        }

        return 'home';
    }

    private getCurrentPageType(): string {
        if (this.route.snapshot.url.length > 2) {
            return this.route.snapshot.url[1].path;
        }

        return 'page';
    }

    private initializeFeatures(): void {
        if (this.features) {
            this.features.forEach((feature: StandardFeature) => {
                feature.BackgroundColor = feature.HtmlColor;
            });
        }
    }
}
