import {
    Component,
    OnInit,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentRef,
    ComponentFactory,
    OnDestroy
} from "@angular/core";
import { Subscription } from "rxjs";

import { ProductService } from "@services/product.service";
import { GlobalService } from "@services/global.service";
import { ProductDetail } from "@models/product-detail";
// import { ProductDetailTabContainerComponent } from "../product-detail-tab-container/product-detail-tab-container.component";
import { ProductInternalDetails } from "@models/product-internal-details";
import { ProductDetailHeadingComponent } from "../product-detail-heading/product-detail-heading.component";
import { ProductChildrenComponent } from "../product-children/product-children.component";
import { ProductRelatedComponent } from "../product-related/product-related.component";
import { ProductSummaryComponent } from "../product-summary/product-summary.component";
import { ProductDescriptionComponent } from "../product-description/product-description.component";
import { ProductFeatureComponent } from "../product-feature/product-feature.component";
import { ProductTestimonialComponent } from "../product-testimonial/product-testimonial.component";
import { ProductTableComponent } from "../product-table/product-table.component";
import { ProductInternalDocComponent } from "../product-internal-doc/product-internal-doc.component";
import { ProductDirectMailingComponent } from "../product-direct-mailing/product-direct-mailing.component";
import { ProductAdComponent } from "../product-ad/product-ad.component";
import { ProductMediaLinkComponent } from "../product-media-link/product-media-link.component";

@Component({
    selector: "product-detail-tab",
    templateUrl: "./product-detail-tab.component.html",
    styleUrls: ["./product-detail-tab.component.less"]
})
export class ProductDetailTabComponent implements OnInit, OnDestroy {
    @Input() tabId: string;
    @Input() productId: string;

    private detailSubscription: Subscription;
    private childrenSubscription: Subscription;
    private relatedSubscription: Subscription;
    private internalsSubscription: Subscription;

    constructor(
        private productService: ProductService,
        private globalService: GlobalService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) { }

    ngOnInit(): void {
        this.detailSubscription = this.productService.getDetails(this.productId).subscribe(
            (response: ProductDetail[]) => { this.processDetails(response); }
        );
    }

    ngOnDestroy(): void {
        if (this.detailSubscription) {
            this.detailSubscription.unsubscribe();
        }
        if (this.childrenSubscription) {
            this.childrenSubscription.unsubscribe();
        }
        if (this.relatedSubscription) {
            this.relatedSubscription.unsubscribe();
        }
        if (this.internalsSubscription) {
            this.internalsSubscription.unsubscribe();
        }
    }

    private processDetails(details: ProductDetail[]) {
        const languageCode: string = this.globalService.getLanguageCode();
        if (details !== undefined && details !== null) {
            for (const detail of details.filter(d => String(d.CenterPage) === this.tabId)) {
                switch (detail.DetailType) {
                case "Summary":
                    this.showSummary(detail);
                    break;
                case "Description":
                    this.showDescription(detail);
                    break;
                case "Feature":
                    this.showFeature(detail);
                    break;
                case "Testimonial":
                    this.showTestimonial(detail);
                    break;
                case "Image":
                    break;
                case "Specification":
                    this.showTable(detail);
                    break;
                case "Media Link":
                    this.showMediaLink(detail);
                    break;
                case "Media Feature":
                    break;
                case "InternalDoc":
                    break;
                case "DirectMail":
                    break;
                default:
                    break;
                }
            }
        }

        this.childrenSubscription = this.productService.getChildren(this.productId).subscribe(
            (response: ProductDetail[]) => { this.processChildren(response, languageCode); }
        );

        this.relatedSubscription = this.productService.getRelated(this.productId).subscribe(
            (response: ProductDetail[]) => { this.processRelated(response, languageCode); }
        );

        this.internalsSubscription = this.productService.getInternalDetails(this.productId).subscribe(
            (response: ProductInternalDetails) => { this.processInternalDetails(response); }
        );
    }

    private processInternalDetails(internalDetails: ProductInternalDetails): void {
        if (internalDetails) {
            if (internalDetails.DocumentLibrary.length > 0) {
                if (String(internalDetails.DocumentLibrary[0].CenterPage) === this.tabId) {
                    this.showInternalDocs(internalDetails.DocumentLibrary);
                }
            }
            if (internalDetails.DirectMailings.length > 0) {
                if (String(internalDetails.DirectMailings[0].CenterPage) === this.tabId) {
                    this.showDirectMailings(internalDetails.DirectMailings);
                }
            }
            if (internalDetails.Ads.length > 0) {
                if (String(internalDetails.Ads[0].CenterPage) === this.tabId) {
                    this.showAds(internalDetails.Ads);
                }
            }
        }
    }

    private processChildren(children: ProductDetail[], languageCode: string): void {
        if (children !== undefined && children !== null && children.length > 0) {
            if (String(children[0].CenterPage) === this.tabId) {
                this.showChildren(children, languageCode);
            }
        }
    }

    private processRelated(relatedProducts: ProductDetail[], languageCode: string): void {
        if (relatedProducts !== undefined && relatedProducts !== null && relatedProducts.length > 0) {
            if (relatedProducts[0].IsHeading && relatedProducts.length === 1) {
                return;
            }

            if (String(relatedProducts[0].CenterPage) === this.tabId) {
                if (relatedProducts[0].IsHeading && relatedProducts.length > 1) {
                    const factory: ComponentFactory<ProductDetailHeadingComponent> =
                        this.componentFactoryResolver.resolveComponentFactory(ProductDetailHeadingComponent);
                    const ref: ComponentRef<ProductDetailHeadingComponent> = this.viewContainerRef.createComponent(factory);

                    ref.instance.setHeading(relatedProducts[0].Title);
                    ref.changeDetectorRef.detectChanges();

                    relatedProducts.splice(0, 1);
                }

                this.showRelated(relatedProducts, languageCode);
            }
        }
    }

    private showChildren(children: ProductDetail[], languageCode: string): void {
        const factory: ComponentFactory<ProductChildrenComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductChildrenComponent);
        const ref: ComponentRef<ProductChildrenComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setChildren(children);
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    private showRelated(relatedProducts: ProductDetail[], languageCode: string): void {
        const factory: ComponentFactory<ProductRelatedComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductRelatedComponent);
        const ref: ComponentRef<ProductRelatedComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setRelated(relatedProducts);
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    private showSummary(detail: ProductDetail): void {
        const factory: ComponentFactory<ProductSummaryComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductSummaryComponent);
        const ref: ComponentRef<ProductSummaryComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setDescription(detail.Description);
        ref.changeDetectorRef.detectChanges();
    }

    private showDescription(detail: ProductDetail): void {
        if (detail.IsHeading) {
            const factory: ComponentFactory<ProductDetailHeadingComponent> =
                this.componentFactoryResolver.resolveComponentFactory(ProductDetailHeadingComponent);
            const ref: ComponentRef<ProductDetailHeadingComponent> = this.viewContainerRef.createComponent(factory);

            ref.instance.setHeading(detail.Title);
            ref.changeDetectorRef.detectChanges();
        } else {
            const factory: ComponentFactory<ProductDescriptionComponent> =
                this.componentFactoryResolver.resolveComponentFactory(ProductDescriptionComponent);
            const ref: ComponentRef<ProductDescriptionComponent> = this.viewContainerRef.createComponent(factory);

            ref.instance.setDescription(detail.Description);
            ref.instance.setTitle(detail.Title);
            ref.changeDetectorRef.detectChanges();
        }
    }

    private showFeature(detail: ProductDetail): void {
        if (detail.IsHeading) {
            const factory: ComponentFactory<ProductDetailHeadingComponent> =
                this.componentFactoryResolver.resolveComponentFactory(ProductDetailHeadingComponent);
            const ref: ComponentRef<ProductDetailHeadingComponent> = this.viewContainerRef.createComponent(factory);

            ref.instance.setHeading(detail.Title);
            ref.changeDetectorRef.detectChanges();
        } else {
            const factory: ComponentFactory<ProductFeatureComponent> =
                this.componentFactoryResolver.resolveComponentFactory(ProductFeatureComponent);
            const ref: ComponentRef<ProductFeatureComponent> = this.viewContainerRef.createComponent(factory);

            ref.instance.title = detail.Title;
            ref.instance.description = detail.Description;
            ref.changeDetectorRef.detectChanges();
        }
    }

    private showTestimonial(detail: ProductDetail): void {
        const factory: ComponentFactory<ProductTestimonialComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductTestimonialComponent);
        const ref: ComponentRef<ProductTestimonialComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.detail = detail;
        ref.changeDetectorRef.detectChanges();
    }

    showTable(detail: ProductDetail): void {
        const factory: ComponentFactory<ProductTableComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductTableComponent);
        const ref: ComponentRef<ProductTableComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.rows = detail.TableSpecifications;
        ref.instance.prepareTable();
        ref.changeDetectorRef.detectChanges();
    }

    showMediaLink(detail: ProductDetail): void {
        const factory: ComponentFactory<ProductMediaLinkComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ProductMediaLinkComponent);
        const ref: ComponentRef<ProductMediaLinkComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.instance.mediaFileName = detail.MediaFileName;
        ref.changeDetectorRef.detectChanges();
    }

    private showInternalDocs(details: ProductDetail[]): void {
        const factory: ComponentFactory<ProductInternalDocComponent> =
        this.componentFactoryResolver.resolveComponentFactory(ProductInternalDocComponent);
        const ref: ComponentRef<ProductInternalDocComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.details = details;
        ref.changeDetectorRef.detectChanges();
    }

    private showDirectMailings(details: ProductDetail[]): void {
        const factory: ComponentFactory<ProductDirectMailingComponent> =
        this.componentFactoryResolver.resolveComponentFactory(ProductDirectMailingComponent);
        const ref: ComponentRef<ProductDirectMailingComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.details = details;
        ref.changeDetectorRef.detectChanges();
    }

    private showAds(details: ProductDetail[]): void {
        const factory: ComponentFactory<ProductAdComponent> =
        this.componentFactoryResolver.resolveComponentFactory(ProductAdComponent);
        const ref: ComponentRef<ProductAdComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.details = details;
        ref.changeDetectorRef.detectChanges();
    }
}
