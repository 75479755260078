import { Component, Input, Output, EventEmitter } from "@angular/core";

import { ProductQuickLink } from "@models/product-quick-link";
import { environment } from "@environment";

@Component({
    selector: "video-viewer",
    templateUrl: "./video-viewer.component.html",
    styleUrls: ["./video-viewer.component.less"]
})
export class VideoViewerComponent {
    @Output() videoViewerClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() productTitle: string;
    @Input() videos: ProductQuickLink[] = [];
    @Input()
    set show(value: boolean) {
        if (value !== this.showVideoViewer) {
            this.showVideoViewer = value;
            if (!value) {
                this.videoViewerClosed.emit(true);
            }
        }
    }
    get show(): boolean {
        return this.showVideoViewer;
    }

    private showVideoViewer: boolean;
    localImageLocation: string = environment.LocalImageLocation;
}
