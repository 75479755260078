import { Injectable, isDevMode } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class IsSecureGuard  {
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!(isDevMode()) && (location.protocol !== "https:")) {
            location.href = "https:" + window.location.href.substring(window.location.protocol.length);
            return false;
        }

        return true;
    }
}
