import { Component } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "data-query-results-heading",
    templateUrl: "./data-query-results-heading.component.html",
    styleUrls: ["./data-query-results-heading.component.less"]
})
export class DataQueryResultsHeadingComponent {
    heading: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    setHeading(heading: string): void {
        this.heading = this.sanitizer.bypassSecurityTrustHtml(heading);
    }
}
