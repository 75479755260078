<div *ngIf="videoIndex >= 0">
    <div class="video">
        <div class="previousVideoBackground" *ngIf="videoIndex > 0" (click)="setVideo(-1)">
            <div class="previousVideo"></div>
        </div>
        <div class="videoContainer">
            <!-- <div *ngIf="videos[videoIndex].VideoDownloadUrl !== ''" class="downloadButton">
                <a [href]="videos[videoIndex].VideoDownloadUrl">
                    <img [src]="localImageLocation + 'download.png'" height="30px" />
                </a>
            </div> -->
            <iframe width="640" height="360" [src]="getVideo()" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
            </iframe>
        </div>
        <div class="nextVideoBackground" *ngIf="videoIndex < (videos.length-1) && videos.length > 0" (click)="setVideo(1)">
            <div class="nextVideo"></div>
        </div>
    </div>
    <p *ngIf="videos.length > 0" class="imageViewCaption">{{videos[videoIndex].Caption}}</p><br />
    <div *ngIf="videos[videoIndex].VideoDownloadUrl !== ''" style="float: right;">
        <a [href]="videos[videoIndex].VideoDownloadUrl">
            <img [src]="localImageLocation + 'download.png'" height="20px" />
        </a>
    </div>
    <div *ngIf="videos.length > 1" class="videoSelect">
        <span class="videoLink">{{videoIndex + 1}} of {{videos.length}}</span>
    </div>
    <div style="clear: both"></div>
</div>
