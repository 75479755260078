import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { GlobalService } from '@services/global.service';
import { PermissionService } from '@services/permission.service';
import { UserService } from '@services/user.service';
import { filter, firstValueFrom, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly _destroying$: Subject<void> = new Subject<void>();

    isIframe: boolean = window !== window.parent && !window.opener;

    constructor(
        private _authService: MsalService,
        private _userService: UserService,
        private _permissionService: PermissionService,
        private _globalService: GlobalService,
        private _router: Router,
        private _msalBroadcastService: MsalBroadcastService
    ) { }

    async ngOnInit(): Promise<void> {
        await firstValueFrom(this._authService.initialize()).then();

        this._msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this._destroying$))
            .subscribe((msg: EventMessage) => {
                const result: AuthenticationResult = msg.payload as AuthenticationResult;
                this.SetupUser(result);
            });

        var user = this._userService.getCurrentUser();
        if (user) {
            var isLoggedIn = await firstValueFrom(this._permissionService.isLoggedIn());
            if (isLoggedIn) {
                // user is present w/ valid token
                user.Token = localStorage.getItem('token');
                this._userService.setCurrentUser(user);
            } else {
                // user is present w/ invalid token, try to get refreshed token
                if (await this._globalService.isInternalDomain(user.UserId)) {
                    // internal user - try to get refreshed token
                    await this._userService.loginAuthenticatedUser().then();
                } else {
                    // external user - force login
                    await this._userService.signOutUser();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }

    private async SetupUser(result: AuthenticationResult): Promise<void> {
        console.log('SetupUser start');
        await this._userService.setInternalAccount(result.account, result.idToken);
        this._router.navigate(["en/page/cpHome"]);
    }
}
