import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { environment } from "@environment";
import { GlobalService } from "@services/global.service";
import { UserService } from "@services/user.service";
import { ValidatedUser } from "@models/validated-user";
import { HeaderInfo } from "@models/header-info";
import { MainMenuItem } from "@models/main-menu-item";
import { HeaderService } from "@services/header.service";
import { CustomerAccount } from "@models/customer-account";
import { SEOService } from "@services/seo.service";

@Component({
    selector: "header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.less"]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private languageSubscription: Subscription;
    private userSubscription: Subscription;
    private accountSubscription: Subscription;

    imageLocation: string = environment.ImageLocation;
    info: HeaderInfo;
    menuItems: MainMenuItem[];
    currentUser: ValidatedUser = null;
    languageCode: string;
    currentAccount: CustomerAccount;
    customerAccountPage: string;


    constructor(
        private globalService: GlobalService,
        private userService: UserService,
        private headerService: HeaderService,
        private router: Router,
        private seo: SEOService
    ) {
        this.languageSubscription = this.globalService.languageId$.subscribe(() => {
            this.headerService.clearHeaderInfo();
            this.ngOnInit()
        });

        this.userSubscription = this.userService.currentUser$.subscribe(
            () => this.ngOnInit()
        );

        this.accountSubscription = this.userService.currentAccount$.subscribe(
            () => this.ngOnInit()
        );

        this.seo.addSEOData();
    }

    ngOnInit() {
        this.globalService.getMainMenuItems().subscribe({
            next: (menus: MainMenuItem[]) => { this.menuItems = menus; },
            error: (error: any) => { this.handleError(error); }
        });

        this.headerService.getHeaderInfo().subscribe({
            next: (info: HeaderInfo) => { this.info = info; },
            error: (error: any) => { this.handleError(error); }
        });

        this.currentUser = this.userService.getCurrentUser();
        this.currentAccount = this.userService.getCustomerAccount();
        this.customerAccountPage = this.userService.getCustomerAccountPage();
        if (!this.customerAccountPage) {
            this.customerAccountPage = "";
        }

        this.languageCode = this.globalService.getLanguageCode();
    }

    ngOnDestroy(): void {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.accountSubscription) {
            this.accountSubscription.unsubscribe();
        }
    }

    private signOutUser(): void {
        if (this.userService.isAuthenticated()) {
            this.userService.signOutUser();
        } else {
            this.userService.signOutUser();
            this.router.navigate([this.languageCode, "page", "home"], { queryParams: { dt: 1 }});
        }
    }

    private handleError(error: any): void {
        console.error("An error occurred", error);
    }
}
