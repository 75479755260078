<div class="carouselContainer">
    <div class="carouselImageContainer">
        <div *ngFor="let image of images" [@imageState]="image.transition" class="carouselImage" [class.active]="image.active" (mouseenter)="pauseAnimation(true)" (mouseleave)="pauseAnimation(false)">
            <a *ngIf="image.targetType !== 'URL'" [routerLink]="[ '/' + languageCode, image.targetType, image.target]" [queryParams]="{ dt: 1 }" [target]="image.linkCausesNewWindow ? '_blank' : ''">
                <img [src]="image.imageUrl" [alt]="image.title"/>
            </a>
            <a *ngIf="image.targetType === 'URL'" href="{{image.target}}" [target]="image.linkCausesNewWindow ? '_blank' : ''">
                <img [src]="image.imageUrl" [alt]="image.title"/>
            </a>
        </div>
    </div>
</div>
<ul class="carouselImageButtons">
    <li *ngFor="let image of images; let idx = index" cursor="pointer" (click)="selectImage(idx)" [class.active]="image.active"></li>
</ul>
