import {
    Component,
    OnChanges,
    Input,
    ViewContainerRef,
    SimpleChanges,
    ComponentRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PageDetail } from '@models/page-detail';
import { GlobalService } from '@services/global.service';
import { FormDetailComponent } from '@shared/form-detail/form-detail.component';
import { Form } from '@models/form';
import { PageDescriptionHeadingComponent } from '../page-description-heading/page-description-heading.component';
import { PageDescriptionComponent } from '../page-description/page-description.component';
import { PageTableComponent } from '../page-table/page-table.component';
import { PageImageComponent } from '../page-image/page-image.component';
import { PageFeatureComponent } from '../page-feature/page-feature.component';
import { PageContentFeatureComponent } from '../page-content-feature/page-content-feature.component';
import { PageContentItemComponent } from '../page-content-item/page-content-item.component';
import { PageManualLinkComponent } from '../page-manual-link/page-manual-link.component';
import { PageMediaLinkComponent } from '../page-media-link/page-media-link.component';
import { JobListingComponent } from '../job-listing/job-listing.component';
import { EventsComponent } from '../events/events.component';
import { CustomerAccountSelectorComponent } from '../customer-account-selector/customer-account-selector.component';
import { PageVideoComponent } from '../page-video/page-video.component';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
    selector: 'page-detail',
    templateUrl: './page-detail.component.html',
    styleUrls: ['./page-detail.component.less'],
})
export class PageDetailComponent extends BaseComponent implements OnChanges {
    @Input() details: PageDetail[] = [];

    private returnUrl: string = '';

    constructor(
        private globalService: GlobalService,
        private route: ActivatedRoute,
        private viewContainerRef: ViewContainerRef
    ) {
        super();

        this.returnUrl = this.route.snapshot.queryParams.returnurl || '/';
        if (this.returnUrl === '/') {
            const refType: string = this.route.snapshot.queryParams.referringtype || '';
            const refId: string = this.route.snapshot.queryParams.referringid || '';
            this.returnUrl = refType + '/' + refId;
        }
        this.subscriptions.push(globalService.languageId$.subscribe(() => {
            this.ngOnChanges(null);
        }));
    }

    ngOnChanges(_: SimpleChanges): void {
        this.processDetails();
    }

    private processDetails() {
        this.viewContainerRef.clear();
        const languageCode: string = this.globalService.getLanguageCode();
        if (this.details) {
            for (const detail of this.details) {
                if (detail.IsHeading) {
                    this.showHeading(detail.Title);
                } else {
                    switch (detail.DetailType) {
                        case 'Content Feature':
                            this.showContentFeature(detail, languageCode);
                            break;
                        case 'Content Item':
                            this.showContentItem(detail, languageCode);
                            break;
                        case 'CustAccount Selector':
                            this.showCustomerAccountSelector(detail);
                            break;
                        case 'Description':
                            this.showDescription(detail);
                            break;
                        case 'Event List':
                            this.showEvents(detail, languageCode);
                            break;
                        case 'Feature':
                            this.showFeature(detail);
                            break;
                        case 'Form':
                            this.showForm(detail);
                            break;
                        case 'Header Image':
                            break;
                        case 'Inline Image Left':
                        case 'Inline Image Right':
                        case 'Inline Image Center':
                            this.showImage(detail);
                            break;
                        case 'Inline Video':
                            this.showVideo(detail);
                            break;
                        case 'Job List':
                            this.showJobListings(detail, languageCode);
                            break;
                        case 'Manual Link':
                            this.showManualLink(detail);
                            break;
                        case 'Media Link':
                            this.showMediaLink(detail);
                            break;
                        case 'Summary':
                            break;
                        case 'Table':
                            this.showTable(detail);
                            break;
                    }
                }
            }
        }
    }

    private showForm(detail: PageDetail) {
        const ref: ComponentRef<FormDetailComponent> =
            this.viewContainerRef.createComponent(FormDetailComponent);

        ref.instance.formId = detail.FormId;
        ref.instance.pageId = detail.Id;
        ref.instance.returnUrl = this.returnUrl;
        ref.instance.fields = new Form().convertToFormFields(detail.FormFieldDefinitions);
        ref.instance.dataQueryId = detail.DataQueryId;
        ref.instance.autoRunDataQuery = detail.AutoRunDataQuery;
        ref.instance.isLoginForm = detail.IsLoginForm;
        ref.instance.isAuthenticationForm = detail.IsAuthenticationForm;
        ref.changeDetectorRef.detectChanges();
    }

    private showCustomerAccountSelector(detail: PageDetail): void {
        const ref: ComponentRef<CustomerAccountSelectorComponent> =
            this.viewContainerRef.createComponent(CustomerAccountSelectorComponent);

        ref.instance.searchHelpText = detail.Title;
        ref.instance.searchButtonTitle = detail.Other;
        ref.changeDetectorRef.detectChanges();
    }

    showHeading(heading: string): void {
        const ref: ComponentRef<PageDescriptionHeadingComponent> =
            this.viewContainerRef.createComponent(PageDescriptionHeadingComponent);

        ref.instance.setHeading(heading);
        ref.changeDetectorRef.detectChanges();
    }

    showDescription(detail: PageDetail): void {
        const ref: ComponentRef<PageDescriptionComponent> =
            this.viewContainerRef.createComponent(PageDescriptionComponent);

        ref.instance.setDescription(detail.Description);
        ref.instance.setTitle(detail.Title);
        ref.instance.setOther(detail.Other);
        ref.changeDetectorRef.detectChanges();
    }

    showTable(detail: PageDetail): void {
        const ref: ComponentRef<PageTableComponent> =
            this.viewContainerRef.createComponent(PageTableComponent);

        ref.instance.rows = detail.TableSpecifications;
        ref.instance.prepareTable();
        ref.changeDetectorRef.detectChanges();
    }

    showImage(detail: PageDetail): void {
        const ref: ComponentRef<PageImageComponent> =
            this.viewContainerRef.createComponent(PageImageComponent);

        ref.instance.detail = detail;
        ref.changeDetectorRef.detectChanges();
    }

    showFeature(detail: PageDetail): void {
        const ref: ComponentRef<PageFeatureComponent> =
            this.viewContainerRef.createComponent(PageFeatureComponent);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.changeDetectorRef.detectChanges();
    }

    private showVideo(detail: PageDetail): void {
        const ref: ComponentRef<PageVideoComponent> =
            this.viewContainerRef.createComponent(PageVideoComponent);

        ref.instance.detail = detail;
        ref.changeDetectorRef.detectChanges();
    }

    showContentFeature(detail: PageDetail, languageCode: string): void {
        const ref: ComponentRef<PageContentFeatureComponent> =
            this.viewContainerRef.createComponent(PageContentFeatureComponent);

        ref.instance.id = detail.ContentId;
        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.instance.mediaFileName = detail.MediaFileName;
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    showContentItem(detail: PageDetail, languageCode: string): void {
        const ref: ComponentRef<PageContentItemComponent> =
            this.viewContainerRef.createComponent(PageContentItemComponent);

        ref.instance.id = detail.ContentId;
        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    showManualLink(detail: PageDetail): void {
        const ref: ComponentRef<PageManualLinkComponent> =
            this.viewContainerRef.createComponent(PageManualLinkComponent);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.changeDetectorRef.detectChanges();
    }

    showMediaLink(detail: PageDetail): void {
        const ref: ComponentRef<PageMediaLinkComponent> =
            this.viewContainerRef.createComponent(PageMediaLinkComponent);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.instance.mediaFileName = detail.MediaFileName;
        ref.changeDetectorRef.detectChanges();
    }

    private showJobListings(detail: PageDetail, languageCode: string): void {
        const ref: ComponentRef<JobListingComponent> =
            this.viewContainerRef.createComponent(JobListingComponent);

        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    private showEvents(detail: PageDetail, languageCode: string): void {
        if (detail.Events !== undefined && detail.Events !== null) {
            const ref: ComponentRef<EventsComponent> =
                this.viewContainerRef.createComponent(EventsComponent);

            ref.instance.detail = detail;
            ref.instance.languageCode = languageCode;
            ref.changeDetectorRef.detectChanges();
        }
    }
}
