import { Component } from "@angular/core";

@Component({
    selector: "product-feature",
    templateUrl: "./product-feature.component.html",
    styleUrls: ["./product-feature.component.less"]
})
export class ProductFeatureComponent {
    title: string;
    description: string;
}
