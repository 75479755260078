<ng-template #marqueeContent>
    <ng-container *ngFor="let message of marqueeMessages">
        <mark style="margin-right: 50px;">
            <span style="margin-right: 50px;">{{message.Title}}</span>
            <ng-container *ngIf="message.LinkTarget !== ''" else noLink>
                <a href="{{message.LinkTarget}}" class="marqueeLink">{{message.Content}}</a>
            </ng-container>
            <ng-template #noLink>
                {{message.Content}}
            </ng-template>
        </mark>
    </ng-container>
</ng-template>

<!-- <div>
    <ng-marquee duration="30s" [stopOnHover]="true" id="usnr-marquee">
        <ng-container *ngTemplateOutlet="marqueeContent"></ng-container>
    </ng-marquee>
</div> -->
<div class="toolbarFrame">
    <div class="toolbar">
        <div class="toolbarMenus">
            <div *ngFor="let menuItem of menuItems">
                <div class="toolbarDivider">|</div>
                <div *ngIf="menuItem.TargetType === 'URL'" class="toolbarMenuItem">
                    <a href="{{menuItem.Target}}" [target]="menuItem.LinkCausesNewWindow ? '_blank' : ''">
                        {{menuItem.Title}}
                    </a>
                </div>
                <div *ngIf="menuItem.TargetType === 'Page'" class="toolbarMenuItem">
                    <div *ngIf="menuItem.Target === 'LoginForm'">
                        <a [routerLink]="['/' + languageCode, 'page', menuItem.Target]" [queryParams]="{ dt: 1, returnUrl: router.url }" [target]="menuItem.LinkCausesNewWindow ? '_blank' : ''">
                            {{menuItem.Title}}
                        </a>
                    </div>
                    <div *ngIf="menuItem.Target !== 'LoginForm'">
                        <a [routerLink]="['/' + languageCode, 'page', menuItem.Target ]" [queryParams]="{ dt: 1 }" [target]="menuItem.LinkCausesNewWindow ? '_blank' : ''">
                            {{menuItem.Title}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="toolbarDivider">|</div>
            <div class="toolbarLanguages">
                <div *ngFor="let language of languages" class="toolbarLanguage">
                    <img class="toolbarLanguageIcon" src="{{imageLocation}}{{language.ImageFileName}}" (click)="onSelectLanguage(language)" alt="{{language.Description}}" />
                </div>
            </div>
        </div>
        <div class="toolbarSearch">
            <input type="text" class="toolbarSearchBox" placeholder="{{searchTitle}}" [(ngModel)]="searchCriteria" (keyup.enter)="search()" />
            <button class="toolbarSearchButton" (click)="search()">
                <img src="{{localImageLocation}}search.png" alt="Search" />
            </button>
        </div>
    </div>
</div>
