<div *ngIf="viewImageIndex >= 0">
    <div class="image">
        <div class="previousImageBackground" *ngIf="viewImageIndex > 0" (click)="setImage(-1)" [style.width.px]="getArrowWidth(images[viewImageIndex])">
            <div class="previousImage"></div>
        </div>
        <div style="height: 400px; width: auto;">
            <div *ngFor="let image of viewImages" [@imageState]="image.transition" class="viewImage" [class.active]="image.active"
                 [style.background-image]="'url(' + imageLocation + encode(image.product.FileInfo.FileName) + ')'" (click)="showMaximizedImage()">
            </div>
        </div>
        <div class="nextImageBackground" *ngIf="viewImageIndex < (images.length-1) && images.length > 0" (click)="setImage(1)" [style.width.px]="getArrowWidth(images[viewImageIndex])">
            <div class="nextImage"></div>
        </div>
    </div>
    <p class="imageViewCaption" *ngIf="viewImageIndex > -1">{{viewImages[viewImageIndex].product.Caption}}</p><br/>
    <div style="clear: both"></div>
</div>
