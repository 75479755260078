import {
    Component,
    OnChanges,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentFactory,
    ComponentRef,
    Output,
    EventEmitter,
} from "@angular/core";

import { DataQueryResult } from "@models/data-query-result";
import { DataQueryResultsGridComponent } from "../data-query-results-grid/data-query-results-grid.component";
import { DataQueryResultsHeadingComponent } from "../data-query-results-heading/data-query-results-heading.component";
import { DataQueryResultsListComponent } from "../data-query-results-list/data-query-results-list.component";
import { ModalDataQueryConfigs } from "@models/modal-data-query-configs";

@Component({
    selector: "data-query-results",
    templateUrl: "./data-query-results.component.html",
    styleUrls: ["./data-query-results.component.less"]
})
export class DataQueryResultsComponent implements OnChanges {
    @Input() data: DataQueryResult[] = [];
    @Output() refreshPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showModalResults: EventEmitter<ModalDataQueryConfigs> = new EventEmitter<ModalDataQueryConfigs>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {
    }

    ngOnChanges(): void {
        if (this.data) {
            this.processQueryResults();
        }
    }

    processQueryResults(): void {
        this.viewContainerRef.clear();
        if (this.data) {
            for (const resultSet of this.data) {
                if (resultSet.Heading && resultSet.Heading !== "") {
                    this.showHeading(resultSet.Heading);
                }

                if (resultSet.OutputType === "Grid") {
                    this.showGrid(resultSet);
                } else if (resultSet.OutputType === "List") {
                    this.showList(resultSet);
                }
            }
        }
    }

    private showHeading(heading: string): void {
        const factory: ComponentFactory<DataQueryResultsHeadingComponent> =
        this.componentFactoryResolver.resolveComponentFactory(DataQueryResultsHeadingComponent);
        const ref: ComponentRef<DataQueryResultsHeadingComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setHeading(heading);
        ref.changeDetectorRef.detectChanges();
    }

    private showGrid(resultSet: DataQueryResult): void {
        const factory: ComponentFactory<DataQueryResultsGridComponent> =
        this.componentFactoryResolver.resolveComponentFactory(DataQueryResultsGridComponent);
        const ref: ComponentRef<DataQueryResultsGridComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.refreshPage.subscribe((refreshData: boolean) => {
            if (refreshData) {
                this.refreshPage.emit(true);
            } else {
                this.processQueryResults();
            }
        });
        ref.instance.showModalResults.subscribe((configs: ModalDataQueryConfigs) => {
            if (configs) {
                this.showModalResults.emit(configs);
            }
        });
        ref.instance.processResultSet(resultSet);
        ref.changeDetectorRef.detectChanges();
    }

    private showList(resultSet: DataQueryResult): void {
        const factory: ComponentFactory<DataQueryResultsListComponent> =
        this.componentFactoryResolver.resolveComponentFactory(DataQueryResultsListComponent);
        const ref: ComponentRef<DataQueryResultsListComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.refreshPage.subscribe((refreshData: boolean) => {
            if (refreshData) {
                this.refreshPage.emit(true);
            } else {
                this.processQueryResults();
            }
        });
        ref.instance.showModalResults.subscribe((configs: ModalDataQueryConfigs) => {
            if (configs) {
                this.showModalResults.emit(configs);
            }
        });
        ref.instance.processResultSet(resultSet);
        ref.changeDetectorRef.detectChanges();
    }
}
