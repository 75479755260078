import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";

import { environment } from "@environment";
import { ProductQuickLink } from "@models/product-quick-link";
import { QuickLinkImage } from "@models/quick-link-image";

@Component({
    selector: "image-view",
    templateUrl: "./image-view.component.html",
    styleUrls: ["./image-view.component.less"],
    animations: [
        trigger("imageState", [
            state("show", style({ opacity: 1 })),
            state("hide", style({ opacity: 0 })),
            state("fadeIn", style({ opacity: 1, transform: "scale(1)" })),
            state("fadeOut", style({ opacity: 0, transform: "scale(0)" })),
            transition("* => hide", [style({ opacity: 1 }), animate("200ms", style({ opacity: 0 }))]),
            transition("* => show", [style({ opacity: 0 }), animate("200ms 210ms", style({ opacity: 1 }))]),
            transition("* => fadeIn", [style({ opacity: 0, transform: "scale(0)" }), animate("250ms 300ms")]),
            transition("* => fadeOut", [style({ opacity: 1, transform: "scale(1)" }), animate("250ms")])
        ])
    ]
})
export class ImageViewComponent implements OnInit {
    imageLocation: string = environment.ImageLocation;

    @Output() shownImageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() maximizeImage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() images: ProductQuickLink[] = [];
    @Input() maxImageWidth: number = 0;
    @Input()
    set selectedImageIndex(value: number) {
        if (this.viewImageIndex !== value) {
            if (this.viewImages !== undefined && this.viewImages.length > 0) {
                if (this.viewImageIndex !== undefined) {
                    this.hideImage(this.viewImageIndex, "hide");
                }
                this.viewImageIndex = value;
                this.showImage(this.viewImageIndex, "show");
                this.updateImage();
            }
        }
    }
    get selectedImageIndex(): number {
        return this.viewImageIndex;
    }

    viewImageIndex: number;
    viewImages: QuickLinkImage[] = [];

    ngOnInit(): void {
        this.viewImages = [];
        if (this.images.length > 0) {
            const imageCount: number = this.images.length;
            for (let i: number = 0; i < imageCount; ++i) {
                this.viewImages[i] = new QuickLinkImage();
                this.viewImages[i].product = this.images[i];
                this.viewImages[i].active = false;
                this.viewImages[i].transition = undefined;
            }

            this.viewImages[0].active = true;

            this.viewImageIndex = 0;
        }
    }

    private setImage(increment: number): void {
        let newIndex: number = this.viewImageIndex + increment;
        if (newIndex < 0) {
            newIndex = this.images.length - 1;
        }

        if (newIndex >= this.images.length) {
            newIndex = 0;
        }

        if (newIndex !== this.viewImageIndex) {
            this.hideImage(this.viewImageIndex, "hide");
            this.viewImageIndex = newIndex;
            this.showImage(this.viewImageIndex, "show");
            this.updateImage();
        }
    }

    private updateImage(): void {
        setTimeout(() => {
            this.viewImages[this.viewImageIndex].active = true;
            this.viewImages.forEach((image) => {
                if (image !== this.viewImages[this.viewImageIndex]) {
                    image.active = false;
                }
            });
        }, 250);

        this.shownImageChanged.emit(this.viewImageIndex);
    }

    hideImage(imageIndex: number, imageState: string): void {
        this.viewImages[imageIndex].transition = imageState;
    }

    showImage(imageIndex: number, imageState: string): void {
        this.viewImages[imageIndex].transition = imageState;
    }

    private getArrowWidth(image: ProductQuickLink): number {
        const proportionalWidth: number = Math.floor(400 / image.FileInfo.Height * image.FileInfo.Width + .5);
        if (proportionalWidth < this.maxImageWidth) {
            return (this.maxImageWidth - proportionalWidth) / 2 + 40;
        }

        return 40;
    }

    private encode(uriPart: string) {
        return encodeURIComponent(uriPart);
    }

    private showMaximizedImage(): void {
        this.maximizeImage.emit(true);
    }
}
