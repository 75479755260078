<privacy-alert-check></privacy-alert-check>
<shared-header pageType="job"></shared-header>
<div class="jobMenu">
    <job-menu></job-menu>
    <general-features featureType="local"></general-features>
</div>
<div class="jobDetail">
    <div class="jobDetailContent">
        <job-detail [details]="details"></job-detail>
    </div>
</div>
<div class="jobFeatures">
    <standard-features></standard-features>
</div>
