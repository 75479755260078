<div style="clear: both;"></div>
<ng-container *ngFor="let job of listings">
    <p *ngIf="job.IsHeading" class="listingHeading">
        <span>{{job.Title}}</span>
    </p>
    <div *ngIf="!job.IsHeading" class="listingMain">
        <a [routerLink]="['/' + languageCode, 'job', job.Id]" [queryParams]="{ dt: 1 }">
            <img src="{{localImageLocation}}Arrows_12x16_CCC.png" alt=""/>
            <div class="listingItem">{{job.Title}}</div>
        </a>
        <p style="padding-bottom: 5px;">
            <span *ngIf="job.Description && job.Description !== ''">&nbsp;-&nbsp;{{getDescription(job.Description)}}</span>
            <span *ngIf="!job.Description || job.Description === ''">&nbsp;</span>
        </p>
    </div>
    <div style="clear: both;"></div>
</ng-container>
