import {
    Component,
    OnDestroy,
    OnChanges,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    SimpleChanges,
    ComponentFactory,
    ComponentRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

import { ContentDetail } from "@models/content-detail";
import { GlobalService } from "@services/global.service";
import { ContentService } from "@services/content.service";
import { ContentDescriptionHeadingComponent } from "../content-description-heading/content-description-heading.component";
import { ContentDescriptionComponent } from "../content-description/content-description.component";
import { ContentTestimonialComponent } from "../content-testimonial/content-testimonial.component";
import { ContentTableComponent } from "../content-table/content-table.component";
import { ContentImageComponent } from "../content-image/content-image.component";
import { ContentVideoComponent } from "../content-video/content-video.component";
import { ContentFeatureComponent } from "../content-feature/content-feature.component";
import { ContentContentFeatureComponent } from "../content-content-feature/content-content-feature.component";
import { ContentContentItemComponent } from "../content-content-item/content-content-item.component";
import { ContentMediaLinkComponent } from "../content-media-link/content-media-link.component";
import { ContentManualLinkComponent } from "../content-manual-link/content-manual-link.component";
import { ContentJobListingComponent } from "../content-job-listing/content-job-listing.component";

@Component({
    selector: "content-detail",
    templateUrl: "./content-detail.component.html",
    styleUrls: ["./content-detail.component.less"]
})
export class ContentDetailComponent implements OnDestroy, OnChanges {
    private subscription: Subscription;

    @Input() details: ContentDetail[];

    constructor(
        private globalService: GlobalService,
        private contentService: ContentService,
        private route: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {
        this.subscription = globalService.languageId$.subscribe(() => { this.ngOnChanges(null); });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.processDetails();
    }

    private processDetails(): void {
        const languageCode: string = this.globalService.getLanguageCode();
        this.viewContainerRef.clear();
        if (this.details !== undefined) {
            for (const detail of this.details) {
                if (detail.IsHeading) {
                    this.showHeading(detail.Title);
                } else {
                    switch (detail.DetailType) {
                    case "Description":
                        this.showDescription(detail);
                        break;
                    case "Inline Image Left":
                    case "Inline Image Right":
                    case "Inline Image Center":
                        this.showImage(detail);
                        break;
                    case "Feature":
                        this.showFeature(detail);
                        break;
                    case "Content Feature": // CAN'T FIND WHERE THIS EXISTS - NOT IN PWCONTENTDETAILTYPE
                        this.showContentFeature(detail, languageCode);
                        break;
                    case "Content Item": // CAN'T FIND WHERE THIS EXISTS - NOT IN PWCONTENTDETAILTYPE
                        this.showContentItem(detail, languageCode);
                        break;
                    case "Table":
                        this.showTable(detail);
                        break;
                    case "Testimonial Left":
                    case "Testimonial Right":
                        this.showTestimonial(detail);
                        break;
                    case "Manual Link":
                        this.showManualLink(detail);
                        break;
                    case "Media Link":
                        this.showMediaLink(detail);
                        break;
                    case "Inline Video":
                        this.showVideo(detail);
                        break;
                    case "Header Image":
                        break;
                    case "Summary":
                        break;
                    case "Job List":
                        this.showJobListings(detail, languageCode);
                        break;
                    }
                }
            }
        }
    }

    private showHeading(heading: string): void {
        const factory: ComponentFactory<ContentDescriptionHeadingComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentDescriptionHeadingComponent);
        const ref: ComponentRef<ContentDescriptionHeadingComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setHeading(heading);
        ref.changeDetectorRef.detectChanges();
    }

    private showDescription(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentDescriptionComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentDescriptionComponent);
        const ref: ComponentRef<ContentDescriptionComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.setDescription(detail.Description);
        ref.instance.setTitle(detail.Title);
        ref.instance.setOther(detail.Other);
        ref.changeDetectorRef.detectChanges();
    }

    private showTestimonial(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentTestimonialComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentTestimonialComponent);
        const ref: ComponentRef<ContentTestimonialComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.detail = detail;
        ref.changeDetectorRef.detectChanges();
    }

    private showTable(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentTableComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentTableComponent);
        const ref: ComponentRef<ContentTableComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.rows = detail.TableSpecifications;
        ref.instance.prepareTable();
        ref.changeDetectorRef.detectChanges();
    }

    private showImage(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentImageComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentImageComponent);
        const ref: ComponentRef<ContentImageComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.detail = detail;
        ref.changeDetectorRef.detectChanges();
    }

    private showVideo(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentVideoComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentVideoComponent);
        const ref: ComponentRef<ContentVideoComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.detail = detail;
        ref.changeDetectorRef.detectChanges();
    }

    private showFeature(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentFeatureComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentFeatureComponent);
        const ref: ComponentRef<ContentFeatureComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.changeDetectorRef.detectChanges();
    }

    private showContentFeature(detail: ContentDetail, languageCode: string): void {
        const factory: ComponentFactory<ContentContentFeatureComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentContentFeatureComponent);
        const ref: ComponentRef<ContentContentFeatureComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.id = detail.Id;
        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.instance.mediaFileName = detail.MediaFileName;
        ref.instance.targetId = detail.TargetContentId;
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    private showContentItem(detail: ContentDetail, languageCode: string): void {
        const factory: ComponentFactory<ContentContentItemComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentContentItemComponent);
        const ref: ComponentRef<ContentContentItemComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.id = detail.Id;
        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.languageCode = languageCode;
        ref.changeDetectorRef.detectChanges();
    }

    private showMediaLink(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentMediaLinkComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentMediaLinkComponent);
        const ref: ComponentRef<ContentMediaLinkComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.instance.mediaFileName = detail.MediaFileName;
        ref.changeDetectorRef.detectChanges();
    }

    private showManualLink(detail: ContentDetail): void {
        const factory: ComponentFactory<ContentManualLinkComponent> =
            this.componentFactoryResolver.resolveComponentFactory(ContentManualLinkComponent);
        const ref: ComponentRef<ContentManualLinkComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.title = detail.Title;
        ref.instance.description = detail.Description;
        ref.instance.other = detail.Other;
        ref.changeDetectorRef.detectChanges();
    }

    private showJobListings(detail: ContentDetail, languageCode: string): void {
        const factory: ComponentFactory<ContentJobListingComponent> =
                this.componentFactoryResolver.resolveComponentFactory(ContentJobListingComponent);
        const ref: ComponentRef<ContentJobListingComponent> = this.viewContainerRef.createComponent(factory);

        ref.instance.languageCode = languageCode;
        ref.instance.contentId = detail.Id;
        ref.changeDetectorRef.detectChanges();
    }
}
