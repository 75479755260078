import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SearchRoutingModule } from "./search-routing.module";
import { SharedModule } from "@shared/shared.module";
import { SearchResultsComponent } from "./search-results/search-results.component";
import { SearchResultCategoriesComponent } from "./search-result-categories/search-result-categories.component";
import { SearchService } from "@services/search.service";
import { SearchResultDetailsComponent } from "./search-result-details/search-result-details.component";
import { FilterByCategoryPipe } from "./filter-by-category.pipe";
import { SearchResultResolverService } from "./search-result-resolver.service";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SearchRoutingModule
    ],
    providers: [
        SearchService,
        SearchResultResolverService
    ],
    declarations: [
        SearchResultsComponent,
        SearchResultCategoriesComponent,
        SearchResultDetailsComponent,
        FilterByCategoryPipe
    ]
})
export class SearchModule { }
