import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ModalDataQueryConfigs } from "@models/modal-data-query-configs";
import { DataQueryResult } from "@models/data-query-result";

@Component({
    selector: "modal-results-dialog",
    templateUrl: "./modal-results-dialog.component.html",
    styleUrls: ["./modal-results-dialog.component.less"]
})
export class ModalResultsDialogComponent implements OnInit {
    dataQueryResults: DataQueryResult[] = [];
    title: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalDataQueryConfigs,
        private _dialogRef: MatDialogRef<ModalResultsDialogComponent>
    ) { }

    ngOnInit(): void {
        if (this.data) {
            this.title = this.data.title;
            this.dataQueryResults = this.data.dataQueryResults;
        }
    }

    onRefreshPage(): void {
        this._dialogRef.close();
    }
}
