import {
    FormFieldBase,
    FormFieldAttachFileButton,
    FormFieldHidden,
    FormFieldAttachImageButton,
    FormFieldClearButton,
    FormFieldResetPasswordButton,
    FormFieldSubmitAndClearButtons,
    FormFieldSubmitButton,
    FormFieldCaptcha,
    FormFieldHeading,
    FormFieldDescription,
    FormFieldChoice,
    FormFieldDate,
    FormFieldEmail,
    FormFieldMultiLineText,
    FormFieldPassword,
    FormFieldNumber,
    FormFieldSpecial,
    FormFieldSingleLineText,
    FormFieldYesNo,
    FormFieldFailureMessage,
    FormFieldSuccessMessage,
    FormFieldEmailSubject,
    FormFieldEmailConfirm,
    FormFieldCheckIdentityButton,
    FormFieldValidationLink
} from "./form-fields";
import { FormFieldDefinition } from "./form-field-definition";

export class Form {
    convertToFormFields(definitions: FormFieldDefinition[]): FormFieldBase<any>[] {
        const fields: FormFieldBase<any>[] = [];
        let clearButtonProcessed: boolean = false;

        definitions.forEach(definition => {
            switch (definition.DetailType.toLowerCase()) {
            case "button-attachfile":
                fields.push(new FormFieldAttachFileButton({
                    formId: definition.Id,
                    label: definition.Title,
                    description: definition.Description,
                    title: definition.ButtonTitle,
                    validationText: definition.Validation,
                    key: `AttachedFileSelection`
                }));
                fields.push(new FormFieldHidden({ formId: definition.Id, key: "AttachedFile", required: definition.IsRequired }));
                fields.push(new FormFieldHidden({ formId: definition.Id, key: "AttachedFileOriginalName" }));
                fields.push(new FormFieldHidden({ formId: definition.Id, key: "AttachedFileType" }));
                break;
            case "button-attachimage":
                fields.push(new FormFieldAttachImageButton({
                    formId: definition.Id,
                    label: definition.Title,
                    description: definition.Description,
                    title: definition.ButtonTitle,
                    validationText: definition.Validation,
                    key: `AttachedImageSelection`
                }));
                fields.push(new FormFieldHidden({
                    formId: definition.Id, key: "AttachedFile", required: definition.IsRequired, validationText: definition.Validation }));
                fields.push(new FormFieldHidden({ formId: definition.Id, key: "AttachedFileOriginalName" }));
                fields.push(new FormFieldHidden({ formId: definition.Id, key: "AttachedFileType" }));
                break;
            case "button-checkidentity":
                fields.push(new FormFieldCheckIdentityButton({
                    formId: definition.Id,
                    label: definition.ButtonTitle,
                    targetPageId: definition.TargetId
                }));
                break;
            case "button-clear":
                if (!clearButtonProcessed) {
                    fields.push(new FormFieldClearButton({ formId: definition.Id, label: definition.ButtonTitle }));
                }
                break;
            case "button-resetpassword":
                fields.push(new FormFieldResetPasswordButton({ formId: definition.Id, label: definition.ButtonTitle }));
                break;
            case "button-submit":
                {
                    const clearButton: FormFieldDefinition = definitions.find(d => d.DetailType === "Button-Clear");
                    if (clearButton !== undefined) {
                        clearButtonProcessed = true;
                        fields.push(new FormFieldSubmitAndClearButtons({
                            formId: definition.Id,
                            label: definition.ButtonTitle,
                            extra: clearButton.ButtonTitle,
                            targetPageId: definition.TargetId
                        }));
                    } else {
                        fields.push(new FormFieldSubmitButton({
                                formId: definition.Id,
                                label: definition.ButtonTitle,
                                targetPageId: definition.TargetId
                            }));
                    }
                }
                break;
            case "captcha":
                fields.push(
                    new FormFieldCaptcha({
                        formId: definition.Id,
                        key: "captcha",
                        label: definition.Title,
                        description: definition.Description,
                        required: true
                    })
                );
                break;
            case "description":
                if (definition.IsHeading) {
                    fields.push(new FormFieldHeading({ formId: definition.Id, key: definition.FieldName, label: definition.Title }));
                } else {
                    fields.push(new FormFieldDescription({
                        formId: definition.Id, key: definition.FieldName, label: definition.Description, required: definition.IsRequired
                    }));
                }
                break;
            case "field-choice": {
                    const optionList: string[] = definition.ChoiceOptions.split(";");
                    const dropdownOptions: any[] = [];
                    optionList.forEach(option => dropdownOptions.push({ key: option.trim(), value: option.trim() }));
                    fields.push(new FormFieldChoice({
                        formId: definition.Id,
                        key: definition.FieldName,
                        label: definition.Title,
                        required: definition.IsRequired,
                        width: definition.Width,
                        options: dropdownOptions,
                        validationText: definition.Validation
                    }));
                    break;
                }
            case "field-date":
                fields.push(new FormFieldDate({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    value: definition.Value === undefined ? "" : definition.Value,
                    validationText: definition.Validation
                }));
                break;
            case "field-email":
                fields.push(new FormFieldEmail({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    pattern: "\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
                    value: definition.Value === undefined ? "" : definition.Value,
                    validationText: definition.Validation,
                    type: "email"
                }));
                break;
            case "field-emailconfirm":
                fields.push(new FormFieldEmail({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    pattern: "\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
                    value: definition.Value === undefined ? "" : definition.Value,
                    validationText: definition.Validation,
                    type: "email"
                }));
                break;
            case "field-identity":
                fields.push(new FormFieldEmail({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    pattern: "\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
                    value: definition.Value === undefined ? "" : definition.Value,
                    validationText: definition.Validation,
                    type: "email"
                }));
                break;
            case "field-multilinetext":
                {
                    const multiLineTextField: FormFieldMultiLineText = new FormFieldMultiLineText(
                        {
                            formId: definition.Id,
                            key: definition.FieldName,
                            label: definition.Title,
                            required: definition.IsRequired,
                            value: definition.Value === undefined ? "" : definition.Value,
                            validationText: definition.Validation
                        });
                    if (definition.Width > 0) {
                        multiLineTextField.width = definition.Width;
                    }
                    if (definition.Height > 0) {
                        multiLineTextField.height = definition.Height;
                    }
                    fields.push(multiLineTextField);
                }
                break;
            case "field-newpassword":
                fields.push(new FormFieldPassword({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    validationText: definition.Validation
                }));
                break;
            case "field-number":
                fields.push(new FormFieldNumber(
                    {
                        formId: definition.Id,
                        key: definition.FieldName,
                        label: definition.Title,
                        required: definition.IsRequired,
                        value: definition.Value === undefined ? "" : definition.Value,
                        pattern: "^\\d*\\.?\\d*$",
                        validationText: definition.Validation
                    }));
                break;
            case "field-password":
                fields.push(new FormFieldPassword({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    validationText: definition.Validation
                }));
                break;
            case "field-query":
                fields.push(new FormFieldSpecial({
                    formId: definition.Id,
                    key: definition.FieldName,
                    label: definition.Title,
                    required: definition.IsRequired,
                    width: definition.Width,
                    validationText: definition.Validation
                }));
                break;
            case "field-singlelinetext":
                fields.push(new FormFieldSingleLineText(
                    {
                        formId: definition.Id,
                        key: definition.FieldName,
                        label: definition.Title,
                        required: definition.IsRequired,
                        value: definition.Value === undefined ? "" : definition.Value,
                        validationText: definition.Validation
                    }));
                break;
            case "field-yesno":
                fields.push(new FormFieldYesNo(
                    {
                        formId: definition.Id,
                        key: definition.FieldName,
                        label: definition.Title,
                        required: definition.IsRequired,
                        value: definition.Value === undefined ? "" : definition.Value,
                        validationText: definition.Validation
                    }));
                break;
            case "msg-failure":
                fields.push(new FormFieldFailureMessage({
                    formId: definition.Id,
                    value: definition.Description,
                    targetPageId: definition.TargetId
                }));
                break;
            case "msg-password":
                break;
            case "msg-success":
                fields.push(new FormFieldSuccessMessage({
                    formId: definition.Id,
                    value: definition.Description
                }));
                break;
            case "msg-emailsubject":
                    fields.push(new FormFieldEmailSubject({
                        formId: definition.Id,
                        value: definition.Title
                    }));
                    break;
            case "msg-emailconfirm":
                    fields.push(new FormFieldEmailConfirm({
                        formId: definition.Id,
                        value: definition.Description
                    }));
                    break;
            case "msg-validationlink":
                fields.push(new FormFieldValidationLink({
                    formId: definition.Id,
                    value: definition.Title
                }));
            break;
            }
        });

        return fields;
    }

    fileChanged(event): void {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            console.log(fileList[0]);
        }
    }
}
