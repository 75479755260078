import { Component } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { ContentDetail } from "@models/content-detail";
import { environment } from "@environment";

@Component({
    selector: "content-video",
    templateUrl: "./content-video.component.html",
    styleUrls: ["./content-video.component.less"]
})
export class ContentVideoComponent {
    detail: ContentDetail;
    localImageLocation: string = environment.LocalImageLocation;

    showDownloadUrl: boolean = false;

    constructor(private sanitizer: DomSanitizer) {}

    private getVideo(): SafeHtml {
        if (this.detail !== undefined && this.detail !== null) {
            let url: string = this.detail.VideoUrl;
            if (url.indexOf("?") > -1) {
                url += "&autoplay=0";
            } else {
                url += "?autoplay=0";
            }
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }

        return "";
    }

    showDownloadLink(show: boolean): void {
        this.showDownloadUrl = show;
    }
}
