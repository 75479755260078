import { Component, Input } from "@angular/core";

@Component({
    selector: "dq-image",
    templateUrl: "./image.component.html",
    styleUrls: ["./image.component.less"]
})
export class ImageComponent {
    @Input() width: number;
    @Input() height: number;
    @Input() src: string;

    constructor() { }
}
