import { Component, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '@environment';
import { MobileHeaderInfo } from '@models/mobile-header-info';
import { GlobalService } from '@services/global.service';
import { HeaderService } from '@services/header.service';
import { BaseComponent } from 'src/app/base/base.component';
import { HeaderInfo } from '@models/header-info';

@Component({
    selector: 'mobile-toolbar',
    templateUrl: './mobile-toolbar.component.html',
    styleUrls: ['./mobile-toolbar.component.less'],
    animations: [
        trigger('searchToggle', [
            state('in',style({ transform: 'translateX(0)', })),
            state('out', style({ transform: 'translateX(100%)', }) ),
            transition('* => in', animate('300ms')),
            transition('in => *', animate('200ms')),
        ]),
    ],
})
export class MobileToolbarComponent extends BaseComponent {
    searchBarState: string = 'out';

    @Output() toggleMainMenu: EventEmitter<void> = new EventEmitter<void>();

    headerInfo: HeaderInfo;
    languageCode: string;

    searchCriteria: string;

    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;

    constructor(
        private router: Router,
        private globalService: GlobalService,
        private headerService: HeaderService,
        private route: ActivatedRoute
    ) {
        super();

        this.subscriptions.push(
            this.headerService.getHeaderInfo().subscribe({
                next: (info: HeaderInfo) => {
                    this.headerInfo = info;
                },
                error: (error: any) => {
                    this.handleError(error);
                },
            })
        );

        this.languageCode = this.globalService.getLanguageCode();
        this.globalService.addMobileLinkTags(this.route.snapshot.url.slice(1));
    }

    toggleMain(): void {
        this.toggleMainMenu.emit();
    }

    toggleSearch(): void {
        this.searchBarState = this.searchBarState === 'in' ? 'out' : 'in';
    }

    search(): void {
        if (this.searchBarState === 'in') {
            if (this.searchCriteria !== '') {
                this.router.navigate([ this.languageCode, 'search', this.searchCriteria, ]);
            }
        }
    }
}
