import { Component } from "@angular/core";

import { TableSpecification } from "@models/table-specification";

@Component({
    selector: "product-table",
    templateUrl: "./product-table.component.html",
    styleUrls: ["./product-table.component.less"]
})
export class ProductTableComponent {
    rows: TableSpecification[];
    isLessThanFullWidth: boolean;
    columnCount: number;

    prepareTable(): void {
        if (this.rows !== undefined && this.rows.length > 0) {
            let totalWidth: number = 0;

            totalWidth += (this.rows[0].Title === "" ? 0 : this.rows[0].TitleWidth);
            totalWidth += (this.rows[0].Column1 === "" ? 0 : this.rows[0].Column1Width);
            totalWidth += (this.rows[0].Column2 === "" ? 0 : this.rows[0].Column2Width);
            totalWidth += (this.rows[0].Column3 === "" ? 0 : this.rows[0].Column3Width);
            totalWidth += (this.rows[0].Column4 === "" ? 0 : this.rows[0].Column4Width);
            totalWidth += (this.rows[0].Column5 === "" ? 0 : this.rows[0].Column5Width);

            this.isLessThanFullWidth = (totalWidth <= 540);

            this.columnCount = this.rows[0].NumberOfColumns;

            this.rows.forEach((r) => {
                r.columns = [];

                if (r.DetailType !== "Footnote") {
                    r.columns.push({
                        value: r.Title,
                        width: (this.isLessThanFullWidth ? r.TitleWidth : -1),
                        fontWeight: (r.DetailType === "Standard" || r.DetailType === "Heading") ? "bold" : "normal"
                    });
                }

                if (r.DetailType === "Footnote") {
                    r.columns.push({
                        value: r.Column1,
                        width: (this.isLessThanFullWidth ? r.TitleWidth : -1),
                        fontWeight: "normal"
                    });
                } else {
                    if (this.columnCount > 0) {
                        r.columns.push({
                            value: r.Column1,
                            width: (this.isLessThanFullWidth ? r.Column1Width : -1),
                            fontWeight: (r.DetailType === "Heading" ? "bold" : "normal")
                        });
                    }
                    if (this.columnCount > 1) {
                        r.columns.push({
                            value: r.Column2,
                            width: (this.isLessThanFullWidth ? r.Column2Width : -1),
                            fontWeight: (r.DetailType === "Heading" ? "bold" : "normal")
                        });
                    }
                    if (this.columnCount > 2) {
                        r.columns.push({
                            value: r.Column3,
                            width: (this.isLessThanFullWidth ? r.Column3Width : -1),
                            fontWeight: (r.DetailType === "Heading" ? "bold" : "normal")
                        });
                    }
                    if (this.columnCount > 3) {
                        r.columns.push({
                            value: r.Column4,
                            width: (this.isLessThanFullWidth ? r.Column4Width : -1),
                            fontWeight: (r.DetailType === "Heading" ? "bold" : "normal")
                        });
                    }
                    if (this.columnCount > 4) {
                        r.columns.push({
                            value: r.Column5,
                            width: (this.isLessThanFullWidth ? r.Column5Width : -1),
                            fontWeight: (r.DetailType === "Heading" ? "bold" : "normal")
                        });
                    }
                }

                r.class = this.getRowClass(r.DetailType);
            });
        }
    }

    getRowClass(type: string): string {
        if (type === "Footnote") {
            return "tableFooter";
        }
        if (type === "Heading") {
            return "tableHeading";
        }

        return "tableValue";
    }
}
