<div class="videoViewer" *ngIf="show">
    <div class="videoViewerBackground"></div>
    <div class="videoViewerDisplay">
        <div class="videoViewerHeader">
            <h2 class="videoViewHeader">{{productTitle}}</h2>
            <div class="videoViewerClose">
                <img src="{{localImageLocation}}close_btn.jpg" alt="Close" (click)="show = false;" />
            </div>
        </div>
        <div style="clear: both"></div>
        <div class="videoView">
            <video-view [videos]="videos"></video-view>
        </div>
    </div>
</div>
