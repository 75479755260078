import {
    Component,
    OnInit,
    Input,
    ElementRef,
    AfterViewInit,
    AfterContentInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { ReCaptcha2Component } from 'ngx-captcha';

import { environment } from '@environment';
import {
    FormFieldBase,
    FormFieldSubmitButton,
    FormFieldSubmitAndClearButtons,
    FormFieldCheckIdentityButton,
} from '@models/form-fields';
import { DataQueryResult } from '@models/data-query-result';
import { UserService } from '@services/user.service';
import { GlobalService } from '@services/global.service';
import { FormService } from '@services/form.service';
import { ValidatedUser } from '@models/validated-user';
import { ModalService } from '@services/modal.service';
import { ModalResultsDialogComponent } from '../modal-results-dialog/modal-results-dialog.component';
import { ModalDataQueryConfigs } from '@models/modal-data-query-configs';
import { UsnrDomain } from '@models/usnr-domain';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { DomSanitizer } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.less'],
})
export class DynamicFormComponent
    implements OnInit, AfterViewInit, AfterContentInit
{
    @Input() fields: FormFieldBase<any>[] = [];
    @Input() formId: string;
    @Input() pageId: string;
    @Input() returnUrl: string = '';
    @Input() dataQueryId: string = '';
    @Input() autoRunDataQuery: boolean = false;
    @Input() isLoginForm: boolean = false;
    @Input() isAuthenticationForm: boolean = false;

    // @ViewChild('captcha') captcha: ReCaptcha2Component;

    form: UntypedFormGroup;
    payLoad: string = '';
    responseMessage: string = '';
    dataQueryResults: DataQueryResult[] = [];
    private captchaSucceeded: boolean = false;
    private _captchaSubscription: Subscription;

    private responseSuccess: boolean = false;
    private responseFailure: boolean = false;
    private responseEmailConfirm: boolean = false;
    private responseValidationLink: boolean = false;
    private domainList: UsnrDomain[] = [];
    busy: boolean = false;

    get localImageLocation(): string {
        return environment.LocalImageLocation;
    }

    successModalId: string = 'successModal';

    formSubmitAttempt: boolean = false;

    constructor(
        private formService: FormService,
        private userService: UserService,
        private globalService: GlobalService,
        private router: Router,
        private route: ActivatedRoute,
        private el: ElementRef,
        private modalService: ModalService,
        private _dialog: MatDialog,
        private _sanitizer: DomSanitizer,
        private reCaptchaService: ReCaptchaV3Service
    ) {}

    ngOnInit() {
        this.form = this.formService.toFormGroup(this.fields);
        // if (this.returnUrl !== '') {
        //     sessionStorage.setItem('returnUrl', this.returnUrl);
        // }

        if (this.autoRunDataQuery && this.dataQueryId !== '') {
            this.processDataQuery();
        }

        if (this.isLoginForm) {
            this.getDomainList();
        }

        const user: ValidatedUser = this.userService.getCurrentUser();
        if (this.isAuthenticationForm && user !== null) {
            console.log('dynamic-form.navigateToTargetPage');
            this.navigateToTargetPage({ dt: 1, userId: user.UserId });
        }
    }

    ngAfterViewInit(): void {
        this.payLoad = '';
    }

    ngAfterContentInit(): void {
        this.payLoad = '';
    }

    onRefreshDataQuery(): void {
        this.processDataQuery();
    }

    private getDomainList(): void {
        this.globalService.getDomainList().then((domainList: UsnrDomain[]) => {
            this.domainList = domainList;
        });
    }

    private processDataQuery(): void {
        const formData: { [id: string]: string } = {};
        formData['objectId'] = this.pageId;
        formData['objectType'] = 'page';
        formData['LanguageId'] = this.globalService.getLanguage();

        this.route.snapshot.queryParamMap.keys.forEach((key: string) => {
            formData['QueryString-' + key.toLowerCase()] = this.route.snapshot.queryParamMap.get(key);
        });

        const user: ValidatedUser = this.userService.getCurrentUser();
        if (user) {
            formData['loginContext'] = user.UserId;
        }

        this.dataQueryResults = [];
        this.submitDataQuery(formData);
    }

    disableForm(toBeDisabled: boolean): void {
        if (toBeDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private handleCaptchaToken(token: string): void {
        if (token && token !== '') {
            this.captchaSucceeded = true;
            this.submitForm();
        } else {
            this.captchaSucceeded = false;
        }
        if (this._captchaSubscription) {
            this._captchaSubscription.unsubscribe();
        }
    }

    async onSubmit(): Promise<void> {
        if (this.form.contains('captchaToken')) {
            this._captchaSubscription = this.reCaptchaService.execute('submit').subscribe((token) => this.handleCaptchaToken(token));
        } else {
            this.handleCaptchaToken('not required');
        }
    }

    private async submitForm(): Promise<void> {
        this.validateForm();
        if (this.form.valid) {
            const formData = this.form.value;
            const keys: string[] = Object.keys(formData);
            keys.forEach((key) => {
                if (!formData[key] || formData[key] === '' || key === 'captcha' || key === '') {
                    delete formData[key];
                } else if (typeof formData[key] !== 'string') {
                    if (formData[key].formatted) {
                        formData[key] = formData[key].formatted;
                    }
                }
            });

            formData['objectId'] = this.pageId;
            formData['objectType'] = 'page';
            formData['LanguageId'] = this.globalService.getLanguage();

            const user: ValidatedUser = this.userService.getCurrentUser();
            if (user) {
                formData['loginContext'] = user.UserId;
            }
            const referringObjectId = this.route.snapshot.queryParams['referringid'];
            if (referringObjectId) {
                formData['referringObjectId'] = referringObjectId;
            }
            const referringObjectType = this.route.snapshot.queryParams['referringtype'];
            if (referringObjectType) {
                formData['referringObjectType'] = referringObjectType;
            }

            // if (this.isLoginForm) {
            //     if (this.isUsnrDomain(formData["UserId"])) {
            //         if (!this.userService.isAuthenticated()) {
            //             this.userService.loginUsnrUser();
            //         }
            //     } else {
            //         this.submitIdentityCheck(formData);
            //     }
            // } else if (this.isAuthenticationForm) {
            //     this.verifyIdentity(formData);
            // } else if (this.dataQueryId && this.dataQueryId !== "") {
            //     this.submitDataQuery(formData);
            // } else {
            //     this.submitData(formData);
            // }

            switch (this.formId) {
                case 'LoginForm':
                    this.loginUser(formData);
                    break;
                default:
                    if (this.isLoginForm) {
                        if (this.isUsnrDomain(formData['UserId'])) {
                            if (!this.userService.isAuthenticated()) {
                                await this.userService.loginUsnrUser(formData['UserId']);
                                // await this.userService.loginAuthenticatedUser();
                                // const currentUser: ValidatedUser = this.userService.getCurrentUser();
                                // if (currentUser) {
                                //     this.gotoValidationPage(currentUser);
                                // }
                            }
                        } else {
                            this.submitIdentityCheck(formData);
                        }
                    } else if (this.isAuthenticationForm) {
                        this.verifyIdentity(formData);
                    } else if (this.dataQueryId && this.dataQueryId !== '') {
                        this.submitDataQuery(formData);
                    } else {
                        this.submitData(formData);
                    }
            }

            this.payLoad = JSON.stringify(this.form.value);
        }
    }

    private gotoValidationPage(user: ValidatedUser): void {
        this.returnUrl = sessionStorage.getItem('returnUrl');
        if (this.returnUrl) {
            sessionStorage.removeItem('returnUrl');
        }

        if (this.returnUrl && this.returnUrl !== '' && this.returnUrl !== '/') {
            this.userService.getCustomerAccountOptions('').subscribe({
                next: (accounts: UserCustomerAccounts) => {
                    if (accounts && accounts.Accounts && accounts.Accounts.length === 1) {
                        this.userService.setCustomerAccount(accounts.Accounts[0], accounts.CustomerAccountSelectedPageId);
                        this.router.navigateByUrl(this.returnUrl);
                    } else {
                        this.navigateToTargetPage({ userId: user.UserId });
                    }
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });
        } else {
            this.navigateToTargetPage({ userId: user.UserId });
        }
    }

    private isUsnrDomain(emailAddress: string): boolean {
        if (!this.domainList.length) {
            this.getDomainList();
        }

        emailAddress = emailAddress.toLowerCase();
        const domainList: UsnrDomain[] = this.domainList.filter(
            (domain: UsnrDomain) => {
                if (emailAddress.endsWith('@' + domain.Id.toLowerCase())) {
                    return domain;
                }
            }
        );

        return domainList.length > 0;
    }

    private validateForm(): void {
        this.formSubmitAttempt = true;
    }

    private submitIdentityCheck(data: any): void {
        this.formService.submitIdentityCheck(data).subscribe();
        this.navigateToTargetPage({ dt: 1, userId: data.UserId });
    }

    private verifyIdentity(data: any): void {
        const userId: string = this.route.snapshot.queryParamMap.get('userId');
        data['UserId'] = userId;
        this.formService.submitVerifyIdentity(data).subscribe({
            next: (result: ValidatedUser) => {
                if (result) {
                    this.responseSuccess = true;

                    if (!this.isUsnrDomain(userId)) {
                        this.userService.setCurrentUser(result);
                    }

                    this.gotoValidationPage(result);
                    // this.userService.setCurrentUser(result);

                    // this.returnUrl = sessionStorage.getItem('returnUrl');
                    // if (this.returnUrl) {
                    //     sessionStorage.removeItem('returnUrl');
                    // }

                    // if (this.returnUrl && this.returnUrl !== '' && this.returnUrl !== '/') {
                    //     this.userService.getCustomerAccountOptions('').subscribe({
                    //         next: (accounts: UserCustomerAccounts) => {
                    //             if (accounts && accounts.Accounts && accounts.Accounts.length === 1) {
                    //                 this.userService.setCustomerAccount(accounts.Accounts[0], accounts.CustomerAccountSelectedPageId);
                    //                 this.router.navigateByUrl(this.returnUrl);
                    //             } else {
                    //                 this.navigateToTargetPage({ userId: data.UserId });
                    //             }
                    //         },
                    //         error: (error: any) => {
                    //             this.handleError(error);
                    //         }
                    //     });
                    // } else {
                    //     this.navigateToTargetPage({ userId: data.UserId });
                    // }
                } else {
                    this.responseFailure = true;
                    this.userService.setCurrentUser(null);
                    this.responseMessage = this.getFailureMessage();
                    this.formSubmitAttempt = false;
                }
            },
            error: (error: HttpErrorResponse) => {
                if (error.status === 500) {
                    this.responseFailure = true;
                    this.userService.setCurrentUser(null);
                    this.responseMessage = error.statusText;
                    this.formSubmitAttempt = false;
                } else {
                    this.handleError(error);
                }
            }
        });
    }

    private submitData(data: any): void {
        this.formService.submitData(this.formId, data).subscribe({
            next: (result: boolean) => {
                if (result) {
                    this.responseSuccess = true;
                    this.responseMessage = this.getSuccessMessage();
                    this.modalService.open(this.successModalId);
                } else {
                    this.responseFailure = true;
                    this.responseMessage = this.getFailureMessage();
                    this.formSubmitAttempt = false;
                }
            },
            error: (error: HttpErrorResponse) => {
                this.handleError(error);
            }
        });
    }

    private submitDataQuery(data: any) {
        this.busy = true;
        this.dataQueryResults = [];
        this.formService.submitDataQuery(this.formId, this.dataQueryId, data).subscribe({
            next: (results: any) => {
                this.dataQueryResults = results;
                this.busy = false;
            },
            error: (error: HttpErrorResponse) => {
                this.busy = false;
                this.handleError(error);
            }
        });
    }

    private loginUser(data: any): void {
        sessionStorage.removeItem('returnUrl');
        this.userService.loginUser(data).then(
            (response: boolean) => {
                if (response) {
                    if (this.returnUrl !== '') {
                        this.router.navigate([this.returnUrl]);
                    } else {
                        this.responseSuccess = true;
                        this.responseMessage = this.getSuccessMessage();
                    }
                } else {
                    this.responseFailure = true;
                    this.responseMessage = this.getFailureMessage();
                }
            },
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (error.statusText.toLowerCase() !== 'ok') {
                        this.responseMessage = error.statusText;
                    } else {
                        this.responseFailure = true;
                        this.responseMessage = this.getFailureMessage();
                    }
                } else {
                    this.responseFailure = true;
                    this.responseMessage = this.getFailureMessage();
                }
            }
        );
    }

    protected getFailedIdentityValidationMessage(): string {
        if (this.fields && this.fields.length > 0) {
            const failureMessage: FormFieldBase<any>[] = this.fields.filter(
                (f: FormFieldBase<any>) => {
                    if (f.controlType === 'msgValidationLink') {
                        return f;
                    }
                }
            );

            if (failureMessage && failureMessage.length > 0) {
                return failureMessage[0].value;
            }

            return '';
        }
    }

    private getFailureMessage(): string {
        if (this.fields && this.fields.length > 0) {
            const failureMessage: FormFieldBase<any>[] = this.fields.filter(
                (f: FormFieldBase<any>) => {
                    if (f.controlType === 'msgFailure') {
                        return f;
                    }
                }
            );

            if (failureMessage && failureMessage.length > 0) {
                return failureMessage[0].value;
            }

            return '';
        }
    }

    private getSuccessMessage(): string {
        if (this.fields && this.fields.length > 0) {
            const message: FormFieldBase<any>[] = this.fields.filter(
                (f: FormFieldBase<any>) => {
                    if (f.controlType === 'msgSuccess') {
                        return f;
                    }
                }
            );

            if (message && message.length > 0) {
                return message[0].value;
            }

            return '';
        }
    }

    onClear(): void {
        if (this.form.controls.captcha) {
            this.form.controls.captcha.reset();
        }
        this.form.reset();
        this.fields.forEach((f: FormFieldBase<any>) => {
            f.resetToDefault();
        });
    }

    handleCaptchaReset(): void {
        this.captchaSucceeded = false;
    }

    onCaptcha(): void {
        this.captchaSucceeded = true;
    }

    getMessageStyle() {
        if (this.responseFailure) {
            return '#cc0000';
        }

        return 'inherited';
    }

    onModalClosed(): void {
        this.formSubmitAttempt = false;

        const returnUrl: string = sessionStorage.getItem('returnUrl');

        if (returnUrl) {
            sessionStorage.removeItem('returnUrl');
        }

        this.form.reset();

        if (returnUrl && returnUrl !== '' && returnUrl !== '/') {
            this.router.navigate([this.returnUrl], { queryParams: { dt: 1 } });
        } else {
            this.navigateToTargetPage(null);
        }
    }

    private navigateToTargetPage(additionalData: Params | null): void {
        const successTarget: FormFieldBase<any>[] = this.fields.filter(
            (f: FormFieldBase<any>) => {
                if (f.controlType === 'buttonSubmit' ||
                    f.controlType === 'buttonSubmitAndClear' ||
                    f.controlType === 'buttonCheckIdentity'
                ) {
                    return f;
                }
            }
        );

        if (successTarget && successTarget.length > 0) {
            const targetPageId: string = this.getTargetPage(successTarget[0]);

            let params: Params;
            if (additionalData) {
                params = additionalData;
            } else {
                params = { dt: 1 };
            }

            this.router.navigate(
                [this.globalService.getLanguageCode(), 'page', targetPageId],
                { queryParams: params }
            );
        }
    }

    private getTargetPage(field: FormFieldBase<any>): string {
        if (field.controlType === 'buttonSubmit') {
            return (field as FormFieldSubmitButton).targetPageId;
        } else if (field.controlType === 'buttonSubmitAndClear') {
            return (field as FormFieldSubmitAndClearButtons).targetPageId;
        } else if (field.controlType === 'buttonCheckIdentity') {
            return (field as FormFieldCheckIdentityButton).targetPageId;
        }

        return '';
    }

    private handleError(error: any): void {
        console.error('An error occurred', error);
    }

    onShowResultsInModal(configs: ModalDataQueryConfigs): void {
        const dialogRef: MatDialogRef<ModalResultsDialogComponent> =
            this._dialog.open(ModalResultsDialogComponent, {
                data: configs,
                width: 'auto',
            });

        dialogRef.afterClosed().subscribe(() => {
            // this.processDataQuery();
        });
    }
}
