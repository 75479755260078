<div [formGroup]="form">
    <div [ngSwitch]="field.controlType">
        <div *ngSwitchCase="'buttonAttachFile'">
            <label [attr.for]="field.key" class="formLabel" style="display: table-cell;">{{field.label}}</label>
            <div style="display: table-cell">
                <input [formControlName]="field.key" [id]="field.key" [type]="field.type" (change)="attachFileChange($event)" class="formField" style="display: inline-block; vertical-align: middle; width: auto;" accept=".pdf,.doc,.docx">
                <img *ngIf="isUploadingFile" src="{{localImageLocation}}ajax-loader.gif" class="fileUploadIndicator" alt="loading">
                <input [formControlName]="'AttachedFile'" [id]="'AttachedFile'" type="hidden">
                <input [formControlName]="'AttachedFileType'" [id]="'AttachedFileType'" type="hidden">
                <input [formControlName]="'AttachedFileOriginalName'" [id]="'AttachedFileOriginalName'" type="hidden">
                <span [innerHTML]="field.description" class="formFieldTextDescription"></span>
                <span *ngIf="field.required" class="formFieldRequired">*</span>
                <div style="clear: both;"></div>
                <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
            </div>
        </div>

        <div *ngSwitchCase="'buttonAttachImage'">
            <label [attr.for]="field.key" class="formLabel" style="display: table-cell;">{{field.label}}</label>
            <div style="display: table-cell">
                <input [formControlName]="field.key" [id]="field.key" [type]="field.type" (change)="attachImageChange($event)" class="formField" style="display: inline-block; vertical-align: middle; width: auto;" accept="image/*,application/pdf">
                <img *ngIf="isUploadingFile" src="{{localImageLocation}}ajax-loader.gif" class="fileUploadIndicator" alt="loading">
                <input [formControlName]="'AttachedFile'" [id]="'AttachedFile'" type="hidden">
                <input [formControlName]="'AttachedFileType'" [id]="'AttachedFileType'" type="hidden">
                <input [formControlName]="'AttachedFileOriginalName'" [id]="'AttachedFileOriginalName'" type="hidden">
                <span [innerHTML]="field.description" class="formFieldTextDescription"></span>
                <span *ngIf="field.required" class="formFieldRequired">*</span>
                <div style="clear: both;"></div>
                <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
            </div>
        </div>

        <div *ngSwitchCase="'buttonClear'">
            <input [formControlName]="field.key" [type]="field.type" [value]="field.label" class="formButton">
        </div>

        <div *ngSwitchCase="'buttonResetPassword'">
            <input [formControlName]="field.key" [type]="field.type" [value]="field.label" class="formButton">
        </div>

        <div *ngSwitchCase="'buttonSubmit'">
            <input [formControlName]="field.key" [type]="field.type" class="formButton">
        </div>
        <div *ngSwitchCase="'buttonCheckIdentity'">
            <input [formControlName]="field.key" [type]="field.type" class="formButton">
        </div>
        <div *ngSwitchCase="'buttonVerifyIdentity'">
            <input [formControlName]="field.key" [type]="field.type" class="formButton">
        </div>

        <div *ngSwitchCase="'buttonSubmitAndClear'">
            <div class="formLabel">&nbsp;</div>
            <input type="submit" [disabled]="!form.valid" [value]="field.label" class="formButton">
            <input type="reset" [value]="field.extra" (click)="onClearForm()" class="formButton">
        </div>

        <div *ngSwitchCase="'captcha'">
            <span hidden="true" name="captchaToken"></span>
            <!-- <label [attr.for]="field.key" class="formLabel" style="display: table-cell">{{field.label}}</label>
            <ngx-recaptcha2 #captchaElem
                [siteKey]="'6LfwXCQUAAAAAPs9X0AjE3PFo0SkyvpkwpCJ5SqO'"
                (reset)="handleCaptchaReset()"
                (expire)="handleCaptchaExpire()"
                (load)="handleCaptchaLoad()"
                (success)="handleCaptchaSuccess($event)"
                [useGlobalDomain]="false"
                [size]="'normal'"
                [theme]="'light'"
                [type]="'image'"
                formControlName="captcha"></ngx-recaptcha2>
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid(field.key)" errorMessage="{{field.validationText}}"></field-error-display> -->
        </div>

        <div *ngSwitchCase="'choice'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <select [formControlName]="field.key" [id]="field.key" class="formField">
                <option *ngFor="let option of field.options" [value]="option.key">{{option.value}}</option>
            </select><span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'date'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <my-date-picker class="formField" style="border-color: #ababab;" [formControlName]="field.key"></my-date-picker>
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'description'">
            <span class="formMainDescription" [innerHTML]="field.label"></span>
        </div>

        <div *ngSwitchCase="'email'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <input [formControlName]="field.key" [id]="field.key" [type]="field.type" class="formField" style="width: 200px;" [value]="field.value">
            <!-- <input [formControlName]="field.key" [id]="field.key" [type]="field.type" class="formField" style="width: 200px;" [value]="field.value" (blur)="handleEmailAddress($event)"> -->
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'heading'" class="formHeading">
            <span [innerHTML]="field.label" class="formGroupHeading"></span>
        </div>

        <div *ngSwitchCase="'multiLineText'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <textarea [formControlName]="field.key" [id]="field.key" [style.width.px]="field.width" [style.height.px]="field.height" class="formField">{{field.value}}</textarea>
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'number'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <input [formControlName]="field.key" [id]="field.key" [type]="field.type" NumberOnly="true" class="formField" [value]="field.value">
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'password'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <input [formControlName]="field.key" [id]="field.key" [type]="'password'" class="formField" style="width: 200px;">
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'singleLineText'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <input [formControlName]="field.key" [id]="field.key" [type]="field.type" class="formField" [value]="field.value">
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'special'">
            <ng-container *ngIf="field.key.toLowerCase() === 'currency'">
                <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
                <select [formControlName]="field.key" [id]="field.key" class="formField" [(ngModel)]="defaultExpenseCurrency">
                    <option *ngFor="let choice of dynamicChoices" [value]="choice.Id">{{choice.Id}}</option>
                </select>
            </ng-container>
            <ng-container *ngIf="field.key.toLowerCase() === 'category'">
                <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
                <select [formControlName]="field.key" [id]="field.key" class="formField">
                    <option *ngFor="let choice of dynamicChoices" [value]="choice.Id">{{choice.Name}}</option>
                </select>
            </ng-container>
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'yesno'">
            <label [attr.for]="field.key" class="formLabel">{{field.label}}</label>
            <input [formControlName]="field.key" [id]="field.key" [type]="'checkbox'" class="formField" style="width: 200px;">
            <span *ngIf="field.required" class="formFieldRequired">*</span>
            <div style="clear: both;"></div>
            <field-error-display [displayError]="isInvalid()" errorMessage="{{field.validationText}}"></field-error-display>
        </div>

        <div *ngSwitchCase="'msgFailure'"></div>
        <div *ngSwitchCase="'msgEmailSubject'">
            <input [formControlName]="field.key" [id]="field.key" [value]="field.value" type="hidden">
        </div>
        <div *ngSwitchCase="'msgEmailConfirm'">
            <input [formControlName]="field.key" [id]="field.key" [value]="field.value" type="hidden">
        </div>
    </div>
</div>
