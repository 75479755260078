<mat-toolbar class="toolbar-header">
    <button type="button" class="menu-button" (click)="toggleMain()">
        <img *ngIf="headerInfo" src="{{imageLocation}}{{headerInfo?.MenuIconFileName}}" alt="menu" style="width: 25px;"/>
    </button>
    <div class="toolbar-header-image-link">
        <a [routerLink]="['/' + languageCode, 'page', 'home']" class="toolbar-header-home-image">
            <img *ngIf="headerInfo" class="toolbar-header-image" src="{{imageLocation}}{{headerInfo?.HeaderImageLink}}" alt="Home"/>
        </a>
    </div>
    <button type="button" class="search-button" (click)="toggleSearch()">
        <img src="{{localImageLocation}}search.png" alt="" style="width: 25px;"/>
    </button>
    <div [@searchToggle]="searchBarState" class="search-bar">
        <div class="search-bar-container">
            <input type="text" class="search-bar-input" [placeholder]="headerInfo?.SearchText" [(ngModel)]="searchCriteria" (keyup.enter)="search()" />
            <button type="button" class="search-bar-button" (click)="toggleSearch()">
                <img src="{{localImageLocation}}searchClose.png" alt="" />
            </button>
        </div>
    </div>
</mat-toolbar>
