import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgMarqueeModule } from 'ng-marquee';

import { ToolbarComponent } from './toolbar/toolbar.component';
import { MobileToolbarComponent } from './mobile-toolbar/mobile-toolbar.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        ToolbarComponent,
        MobileToolbarComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        // NgMarqueeModule,
        MatToolbarModule
    ],
    exports: [
        ToolbarComponent,
        MobileToolbarComponent
    ]
})
export class ToolbarsModule {}
