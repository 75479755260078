import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { GlobalService } from "./global.service";
import { environment } from "@environment";
import { SearchResult } from "@models/search-result";

@Injectable()
export class SearchService {
    private baseUrl: string = environment.ApiBase + "master";

    constructor(private http: HttpClient, private globalService: GlobalService) { }

    getResults(criteria: string): Observable<SearchResult> {
        const params: HttpParams = new HttpParams({ fromObject: { criteria: criteria, language: this.globalService.getLanguage() } });
        return this.http.get<SearchResult>(this.baseUrl + "/search", { params: params });
    }
}
