import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@environment";
import { Toolbar } from "@models/toolbar";
import { GlobalService } from "./global.service";

@Injectable()
export class ToolbarService {
    private baseUrl: string = environment.ApiBase + "master/toolbar";

    constructor(private http: HttpClient, private globalService: GlobalService) { }

    getToolbar(): Observable<Toolbar> {
        const params: HttpParams = new HttpParams({ fromObject: { language: this.globalService.getLanguage() } });
        return this.http.get<Toolbar>(this.baseUrl, { params: params });
    }
}
