
import {throwError as observableThrowError,  Subscription } from "rxjs";
import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

declare var enableClickDimensions: any;

import { SearchResult } from "@models/search-result";
import { GlobalService } from "@services/global.service";
import { SearchService } from "@services/search.service";
import { SEOService } from "@services/seo.service";

@Component({
    selector: "search-results",
    templateUrl: "./search-results.component.html",
    styleUrls: ["./search-results.component.less"]
})
export class SearchResultsComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription: Subscription;
    private routeSubscription: Subscription;
    private resultsSubscription: Subscription;

    searchResults: SearchResult;
    filterBy: string = "";

    constructor(
        private globalService: GlobalService,
        private searchService: SearchService,
        private route: ActivatedRoute,
        private router: Router,
        private seo: SEOService
    ) {
        let languageId = this.route.snapshot.paramMap.get("languageId");
        if (languageId && languageId !== "") {
            this.globalService.setLanguage(languageId);
        } else {
            languageId = this.globalService.getLanguage();
            if (!languageId) {
                this.globalService.setLanguage("en-us");
            }
        }

        this.subscription = globalService.languageId$.subscribe(
            () => { this.filterBy = ""; this.getResults(); }
        );

        this.seo.addSEOData();
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.data
            .subscribe((data: { searchResults: SearchResult }) => this.searchResults = data.searchResults);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        if (this.resultsSubscription) {
            this.resultsSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.globalService.cookiesEnabled()) {
            enableClickDimensions();
        }
    }

    private getResults(): void {
        this.resultsSubscription = this.searchService.getResults(this.searchResults.OriginalCriteria).subscribe(
            (results: SearchResult) => { this.searchResults = results; },
            (error: any) => { this.handleError(error); }
        );
    }

    onFilterResults(filterBy: string) {
        this.filterBy = filterBy;
    }

    private handleError(error: any): void {
        console.error("An error occurred", error);
        observableThrowError(error);
    }
}
