<div style="padding: 24px;">
    <span style="font-weight: bold">{{privacyMessage}}</span>
    <div *ngIf="showLearnMore" style="max-height: 80%; margin-top: 10px; overflow-y: auto;" [innerHtml]="learnMoreText"></div>
    <div style="margin-top: 10px;">
        <input #okButton type="button" class="form-button" tabindex="1" (click)="onButtonClick()" [value]="privacyButtonText" />
        <a *ngIf="!showLearnMore" href="javascript:void(0)" class="form-button" tabindex="2" (click)="onShowLearnMore()">
            {{privacyLinkText}}
            <mat-icon style="vertical-align: middle;">chevron_right</mat-icon>
        </a>
    </div>
</div>
