import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

import { environment } from "@environment";
import { ProductQuickLink } from "@models/product-quick-link";

@Component({
    selector: "thumbnail-list",
    templateUrl: "./thumbnail-list.component.html",
    styleUrls: ["./thumbnail-list.component.less"]
})
export class ThumbnailListComponent implements OnInit, OnChanges {
    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;
    private imagesPerPage: number = 5;
    private selectedImageIndex: number = 0;
    currentPage: number = 0;

    @Input() images: ProductQuickLink[] = [];
    @Input() maxImageWidth: number = 0;
    @Input() shownImageIndex: number;
    @Output() selectedImageChanged: EventEmitter<number> = new EventEmitter<number>();

    private pagedImages: ProductQuickLink[] = [];
    pages: ProductQuickLink[][] = [];

    ngOnInit(): void {
        this.currentPage = 0;

        if (this.maxImageWidth > 0) {
            this.imagesPerPage = Math.floor((this.maxImageWidth - 10) / 110);
        }

        const pageCount: number = this.getPageCount();
        for (let i: number = 0; i < pageCount; ++i) {
            const pageImageStart: number = i * this.imagesPerPage;
            this.pages[i] = this.images.slice(pageImageStart, pageImageStart + this.imagesPerPage);
        }
    }

    private getPageCount(): number {
        const imagePage: number = this.getImagePage(this.images.length);
        if (this.images.length % this.imagesPerPage > 0) {
            return imagePage + 1;
        }

        return imagePage;
    }

    selectImage(pagedIndex: number): void {
        this.selectedImageIndex = (this.currentPage * this.imagesPerPage) + pagedIndex;
        this.selectedImageChanged.emit(this.selectedImageIndex);
    }

    private getImagePage(index: number): number {
        return Math.floor(index / this.imagesPerPage);
    }

    incrementPage(increment: number): void {
        if (this.pages.length < 2) {
            return;
        }

        const newPage: number = this.currentPage + increment;
        if (newPage > this.pages.length - 1) {
            this.currentPage = this.pages.length - 1;
        } else if (newPage < 0) {
            this.currentPage = 0;
        } else {
            this.currentPage = newPage;
        }

        if (increment > 0) {
            this.selectImage(0);
        } else {
            this.selectImage(this.imagesPerPage - 1);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["images"] !== undefined) {
            this.ngOnInit();
        } else if (changes["shownImageIndex"] !== undefined) {
            const newPage: number = this.getImagePage(this.shownImageIndex);

            if (this.shownImageIndex !== this.selectedImageIndex) {
                this.selectedImageIndex = this.shownImageIndex;
            }

            if (newPage !== this.currentPage) {
                this.currentPage = newPage;
            }
        }
    }
}
