import { Component, Input } from "@angular/core";

import { environment } from "@environment";
import { LinkBaseComponent } from "../link-base/link-base.component";

@Component({
    selector: "dq-anchor",
    templateUrl: "./anchor.component.html",
    styleUrls: ["./anchor.component.less"]
})
export class AnchorComponent extends LinkBaseComponent {
    @Input() value: string;
    @Input() width: number;

    get localImageLocation(): string {
        return environment.LocalImageLocation;
    }

    constructor() { super(); }
}
