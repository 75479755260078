<p class="sectionHeading">
    <span>{{sectionHeader.Title}}</span>
    <a style="float: right; padding-right: 10px; color: #555555;" href="javascript:void(0)" (click)="toggleUpload()">Upload</a>
</p>
<div *ngIf="showUpload" style="padding-bottom: 5px;">
    <input type="file" style="display: inline-block; vertical-align: middle; width: auto;" accept=".pdf,.doc,.docx" (change)="fileChanged($event)"/>
    <input type="button" style="float: right;" value="Save" (click)="uploadFile()" />
    <div style="padding-top: 3px;">
        Description <input type="text" style="width: 400px;" [(ngModel)]="uploadDocumentDescription" />
    </div>
</div>
<table style="width: 100%; border-spacing: 0px; border-collapse: separate; padding-bottom: 40px;">
    <thead>
        <tr class="adHeading">
            <td>Type</td>
            <td>Name</td>
            <td>Size</td>
            <td>Language</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let detail of sectionDetails" class="documentRow" (click)="getFile(detail.Other, detail.FileInfo)" [style.cursor]="detail.FileInfo ? 'pointer': 'default'">
            <td>{{detail.Other | uppercase}}</td>
            <td>{{detail.Title}}</td>
            <td><span *ngIf="detail.FileInfo !== null">{{detail.FileInfo.FileSize | sizeConversion:1}}</span></td>
            <td>{{detail.DocumentLanguage | uppercase}}</td>
        </tr>
    </tbody>
</table>
