import { FileInfo } from "./file-info";
import { TableSpecification } from "./table-specification";

export class ProductDetail {
    Id: string;
    Type: string;
    DetailType: string;
    CenterPage: number;
    CenterSectionTitle: string;
    CenterSequence: number;
    SortOrder: number;
    SpecificationTableId: string;
    IsHeading: boolean;
    Title: string;
    Description: string;
    Other: string;
    MediaFileName: string;
    MediaWatermarkFileName: string;
    TargetProductId: string;
    DocumentType: string;
    DocumentLanguage: string;

    FileInfo: FileInfo;
    TableSpecifications: TableSpecification[];
}
