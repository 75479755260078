<div class="thumbnailList">
    <div class="thumbnailImagesContainer">
        <div class="thumbnailListPageArrow" style="margin-right: 5px;">
            <img *ngIf="currentPage > 0" src="{{localImageLocation}}arrowsL.jpg" (click)="incrementPage(-1)"
                alt="Previous page" width="10" />
        </div>

        <div class="thumbnailListItem" *ngFor="let image of pages[currentPage]; let i = index">
            <img src="{{imageLocation}}{{image.FileInfo.FileName}}" alt="{{image.Caption}}" (click)="selectImage(i)"
                [ngClass]="{'selectedImage': selectedImageIndex===(currentPage*imagesPerPage)+i}" />
        </div>

        <div class="thumbnailListPageArrow" style="margin-left: 5px;">
            <img *ngIf="currentPage < pages.length - 1" src="{{localImageLocation}}arrowsR.jpg"
                (click)="incrementPage(1)" alt="Next page" width="10" />
        </div>
    </div>
</div>