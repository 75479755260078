<div class="featureContainer">
    <div *ngFor="let feature of features" class="featureItemContainer">
        <ng-container *ngIf="feature.Title==='' && feature.Description==='' && feature.MediaId!==0">
            <a *ngIf="!feature.isNone && !feature.isMedia && !feature.isURL && feature.TargetType!==''" href="javascript:void(0)" (click)="navigateToTarget(feature)" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem">
                    <div class="featureImage">
                        <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" style="border: none;" />
                    </div>
                    <div style="clear: both"></div>
                </div>
            </a>
            <div *ngIf="feature.isNone || feature.TargetType===''" class="featureItemNonLink">
                <div class="featureImage">
                    <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" style="border: none;" />
                </div>
                <div style="clear: both"></div>
            </div>
            <a *ngIf="feature.isURL" href="{{feature.Target}}" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem">
                    <div class="featureImage">
                        <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" />
                    </div>
                    <div style="clear: both"></div>
                </div>
            </a>
            <a *ngIf="feature.isMedia" href="{{imageLocation}}{{feature.Target}}" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem">
                    <div class="featureImage">
                        <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" />
                    </div>
                    <div style="clear: both"></div>
                </div>
            </a>
        </ng-container>
        <ng-container *ngIf="(feature.Title!=='' || feature.Description!=='') && feature.MediaId!==0">
            <a *ngIf="!feature.isNone && !feature.isMedia && !feature.isURL && feature.TargetType!==''" href="javascript:void(0)" (click)="navigateToTarget(feature)" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem" [ngStyle]="{ 'background-color': feature.BackgroundColor }" (mouseover)="setHoverColor(feature)" (mouseout)="setBackgroundColor(feature)">
                    <div class="featureImage">
                        <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" />
                    </div>
                    <div *ngIf="feature.Description !== ''" class="featureDescription" [innerHTML]="getField(feature.Description)"></div>
                    <div class="featureTitle" [innerHTML]="getField(feature.Title)"></div>
                    <div style="clear: both"></div>
                    <div class="featureArrow"></div>
                </div>
            </a>
            <a *ngIf="feature.isURL" href="{{feature.Target}}" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem" [ngStyle]="{ 'background-color': feature.BackgroundColor }" (mouseover)="setHoverColor(feature)" (mouseout)="setBackgroundColor(feature)">
                    <div class="featureImage">
                        <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" />
                    </div>
                    <div *ngIf="feature.Description !== ''" class="featureDescription" [innerHTML]="getField(feature.Description)"></div>
                    <div class="featureTitle" [innerHTML]="getField(feature.Title)"></div>
                    <div style="clear: both"></div>
                    <div class="featureArrow"></div>
                </div>
            </a>
            <a *ngIf="feature.isMedia" href="{{imageLocation}}{{feature.Target}}" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem" [ngStyle]="{ 'background-color': feature.BackgroundColor }" (mouseover)="setHoverColor(feature)" (mouseout)="setBackgroundColor(feature)">
                    <div class="featureImage">
                        <img src="{{imageLocation}}{{feature.MediaFileName}}" alt="{{feature.MediaFileName}}" width="190" />
                    </div>
                    <div *ngIf="feature.Description !== ''" class="featureDescription" [innerHTML]="getField(feature.Description)"></div>
                    <div class="featureTitle" [innerHTML]="getField(feature.Title)"></div>
                    <div style="clear: both"></div>
                    <div class="featureArrow"></div>
                </div>
            </a>
        </ng-container>
        <ng-container *ngIf="(feature.Title!=='' || feature.Description!=='') && feature.MediaId===0">
            <a *ngIf="!feature.isURL && !feature.isMedia"  href="javascript:void(0)" (click)="navigateToTarget(feature)" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem" [ngStyle]="{ 'background-color': feature.BackgroundColor }" (mouseover)="setHoverColor(feature)" (mouseout)="setBackgroundColor(feature)">
                    <div *ngIf="feature.Description !== ''" class="featureDescription" [innerHTML]="getField(feature.Description)"></div>
                    <div class="featureTitle" [innerHTML]="getField(feature.Title)"></div>
                    <div style="clear: both"></div>
                    <div class="featureArrow"></div>
                </div>
            </a>
            <a *ngIf="feature.isURL" href="{{feature.Target}}" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem" [ngStyle]="{ 'background-color': feature.BackgroundColor }" (mouseover)="setHoverColor(feature)" (mouseout)="setBackgroundColor(feature)">
                    <div *ngIf="feature.Description !== ''" class="featureDescription" [innerHTML]="getField(feature.Description)"></div>
                    <div class="featureTitle" [innerHTML]="getField(feature.Title)"></div>
                    <div style="clear: both"></div>
                    <div class="featureArrow"></div>
                </div>
            </a>
            <a *ngIf="feature.isMedia" href="{{imageLocation}}{{feature.Target}}" [target]="feature.LinkCausesNewWindow ? '_blank' : ''">
                <div class="featureItem" [ngStyle]="{ 'background-color': feature.BackgroundColor }" (mouseover)="setHoverColor(feature)" (mouseout)="setBackgroundColor(feature)">
                    <div *ngIf="feature.Description !== ''" class="featureDescription" [innerHTML]="getField(feature.Description)"></div>
                    <div class="featureTitle" [innerHTML]="getField(feature.Title)"></div>
                    <div style="clear: both"></div>
                    <div class="featureArrow"></div>
                </div>
            </a>
        </ng-container>
    </div>
</div>
