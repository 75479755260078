import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

import { PageRoutingModule } from './page-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ToolbarsModule } from '../toolbars/toolbars.module';
import { FootersModule } from '../footers/footers.module';

import { PageComponent } from './page/page.component';
import { PageService } from '@services/page.service';
import { PageMenuComponent } from './page-menu/page-menu.component';
import { PageDetailComponent } from './page-detail/page-detail.component';
import { PageResolverService } from './page-resolver.service';
import { PageDescriptionHeadingComponent } from './page-description-heading/page-description-heading.component';
import { PageDescriptionComponent } from './page-description/page-description.component';
import { PageTableComponent } from './page-table/page-table.component';
import { PageImageComponent } from './page-image/page-image.component';
import { PageFeatureComponent } from './page-feature/page-feature.component';
import { PageContentFeatureComponent } from './page-content-feature/page-content-feature.component';
import { PageContentItemComponent } from './page-content-item/page-content-item.component';
import { PageManualLinkComponent } from './page-manual-link/page-manual-link.component';
import { PageMediaLinkComponent } from './page-media-link/page-media-link.component';
import { JobListingComponent } from './job-listing/job-listing.component';
import { JobService } from '@services/job.service';
import { EventsComponent } from './events/events.component';
import { CustomerAccountSelectorComponent } from './customer-account-selector/customer-account-selector.component';
import { PageVideoComponent } from './page-video/page-video.component';

@NgModule({
    imports: [
        CommonModule,
        PageRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ToolbarsModule,
        MatSidenavModule,
        FootersModule,
    ],
    exports: [PageDetailComponent],
    providers: [
        PageService,
        PageResolverService,
        JobService
    ],
    declarations: [
        PageComponent,
        PageMenuComponent,
        PageDetailComponent,
        PageDescriptionHeadingComponent,
        PageDescriptionComponent,
        PageTableComponent,
        PageImageComponent,
        PageFeatureComponent,
        PageContentFeatureComponent,
        PageContentItemComponent,
        PageManualLinkComponent,
        PageMediaLinkComponent,
        JobListingComponent,
        EventsComponent,
        CustomerAccountSelectorComponent,
        PageVideoComponent,
    ],
})
export class PageModule {}
