import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sizeConversion"
})
export class SizeConversionPipe implements PipeTransform {
    static kb: number = 1024;
    static mb: number = 1048576; // kb * 1024;
    static gb: number = 4176741824; // mb * 1024;

    transform(value: number, decimalPlaces?: number): string {
        if (value > SizeConversionPipe.kb) {
            if (value > SizeConversionPipe.mb) {
                if (value > SizeConversionPipe.gb) {
                    return (value / SizeConversionPipe.gb).toFixed(decimalPlaces) + " GB";
                }

                return (value / SizeConversionPipe.mb).toFixed(decimalPlaces) + " MB";
            }

            return (value / SizeConversionPipe.kb).toFixed(decimalPlaces) + " KB";
        }

        return "";
    }
}
