import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { ProductRoutingModule } from "./product-routing.module";
import { ProductComponent } from "./product/product.component";
import { SharedModule } from "@shared/shared.module";
import { ProductMenuComponent } from "./product-menu/product-menu.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductDetailTabContainerComponent } from "./product-detail-tab-container/product-detail-tab-container.component";
import { ProductTabHeadersComponent } from "./product-tab-headers/product-tab-headers.component";
import { ProductQuickLinkComponent } from "./product-quick-link/product-quick-link.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { ImageViewComponent } from "./image-view/image-view.component";
import { ThumbnailListComponent } from "./thumbnail-list/thumbnail-list.component";
import { ImageViewMaximizedComponent } from "./image-view-maximized/image-view-maximized.component";
import { FocusDirective } from "./focus.directive";
import { ProductDetailTabComponent } from "./product-detail-tab/product-detail-tab.component";
import { ProductDetailHeadingComponent } from "./product-detail-heading/product-detail-heading.component";
import { ProductChildrenComponent } from "./product-children/product-children.component";
import { ProductRelatedComponent } from "./product-related/product-related.component";
import { ProductSummaryComponent } from "./product-summary/product-summary.component";
import { ProductDescriptionComponent } from "./product-description/product-description.component";
import { ProductFeatureComponent } from "./product-feature/product-feature.component";
import { ProductTestimonialComponent } from "./product-testimonial/product-testimonial.component";
import { ProductTableComponent } from "./product-table/product-table.component";
import { ProductInternalDocComponent } from "./product-internal-doc/product-internal-doc.component";
import { ProductDirectMailingComponent } from "./product-direct-mailing/product-direct-mailing.component";
import { ProductAdComponent } from "./product-ad/product-ad.component";
import { VideoViewerComponent } from "./video-viewer/video-viewer.component";
import { VideoViewComponent } from "./video-view/video-view.component";
import { SizeConversionPipe } from "./size-conversion.pipe";
import { ProductService } from "@services/product.service";
import { ProductResolverService } from "./product-resolver.service";
import { ProductMediaLinkComponent } from "./product-media-link/product-media-link.component";

@NgModule({
    imports: [
        CommonModule,
        ProductRoutingModule,
        FormsModule,
        SharedModule
    ],
    providers: [
        ProductService,
        ProductResolverService
    ],
    declarations: [
        ProductComponent,
        ProductMenuComponent,
        ProductDetailComponent,
        ProductTabHeadersComponent,
        ProductDetailTabContainerComponent,
        ProductQuickLinkComponent,
        ImageViewerComponent,
        ImageViewComponent,
        ThumbnailListComponent,
        ImageViewMaximizedComponent,
        FocusDirective,
        ProductDetailTabComponent,
        ProductDetailHeadingComponent,
        ProductChildrenComponent,
        ProductRelatedComponent,
        ProductSummaryComponent,
        ProductDescriptionComponent,
        ProductFeatureComponent,
        ProductTestimonialComponent,
        ProductTableComponent,
        ProductInternalDocComponent,
        ProductDirectMailingComponent,
        ProductAdComponent,
        VideoViewerComponent,
        VideoViewComponent,
        SizeConversionPipe,
        ProductMediaLinkComponent
    ]
})
export class ProductModule { }
