import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '@services/global.service';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyAlertDialogComponent, PrivacyMessageData } from '../privacy-alert-dialog/privacy-alert-dialog.component';

@Component({
    selector: 'privacy-alert-check',
    templateUrl: './privacy-alert-check.component.html',
    styleUrls: ['./privacy-alert-check.component.less'],
})
export class PrivacyAlertCheckComponent implements OnInit {
    private privacyMessageData: PrivacyMessageData;

    constructor(
        private _globalService: GlobalService,
        private _dialog: MatDialog,
        private _router: ActivatedRoute
    ) {}

    ngOnInit(): void {
        if (this._router.snapshot.url.length === 3 &&
            this._router.snapshot.url[1].path === 'page' &&
            this._router.snapshot.url[2].path === 'privacy'
        ) {
            return;
        }
        if (!this._globalService.cookiesEnabled()) {
            this.loadAndShowPrivacyMessage();
        }
    }

    private async loadAndShowPrivacyMessage(): Promise<void> {
        await this._globalService.getPrivacyMessageData().then(
            (privacyData: PrivacyMessageData) => this.privacyMessageData = privacyData
        );

        if (this.privacyMessageData) {
            const dialogRef: MatDialogRef<PrivacyAlertDialogComponent> =
            this._dialog.open(PrivacyAlertDialogComponent, {
                data: this.privacyMessageData, disableClose: true
            });

            dialogRef.afterClosed().subscribe(() => {
                this._globalService.enableCookies();
                this._globalService.setupClickDimensions();
            });
        }
    }
}
