import { Component } from "@angular/core";

import { environment } from "@environment";
import { ProductDetail } from "@models/product-detail";

@Component({
    selector: "product-testimonial",
    templateUrl: "./product-testimonial.component.html",
    styleUrls: ["./product-testimonial.component.less"]
})
export class ProductTestimonialComponent {
    imageLocation: string = environment.ImageLocation;

    detail: ProductDetail;
}
