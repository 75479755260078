import { Component, OnInit } from "@angular/core";

import { environment } from "@environment";

@Component({
    selector: "product-media-link",
    templateUrl: "./product-media-link.component.html",
    styleUrls: ["./product-media-link.component.less"]
})
export class ProductMediaLinkComponent {
    imageLocation: string = environment.ImageLocation;

    title: string;
    description: string;
    other: string;
    mediaFileName: string;

    // private getLink(): string {
    //     if (this.other !== "") {
    //         const idx = this.other.indexOf("Id=");
    //         if (idx > -1) {
    //             return this.other.substring(idx + 3);
    //         }
    //     }

    //     return this.other;
    // }
}
