import { Component } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "content-description",
    templateUrl: "./content-description.component.html",
    styleUrls: ["./content-description.component.less"]
})
export class ContentDescriptionComponent {
    description: SafeHtml;
    descriptionPresent: boolean;
    title: SafeHtml;
    titlePresent: boolean;
    other: SafeHtml;
    otherPresent: boolean;

    constructor(private sanitizer: DomSanitizer) { }

    setDescription(description: string): void {
        if (description === "") {
            this.descriptionPresent = false;
        } else {
            this.description = this.sanitizer.bypassSecurityTrustHtml(description);
            this.descriptionPresent = true;
        }
    }

    setTitle(title: string): void {
        if (title === "") {
            this.titlePresent = false;
        } else {
            this.title = this.sanitizer.bypassSecurityTrustHtml(title);
            this.titlePresent = true;
        }
    }

    setOther(other: string): void {
        if (other === "") {
            this.otherPresent = false;
        } else {
            this.other = this.sanitizer.bypassSecurityTrustHtml(other);
            this.otherPresent = true;
        }
    }
}
