
import { Subject ,  Observable, firstValueFrom, filter } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';
import { EventMessage, InteractionRequiredAuthError, PopupRequest, SilentRequest } from '@azure/msal-browser';

import { environment } from "@environment";
import { ValidatedUser } from "@models/validated-user";
import { UserCustomerAccounts } from "@models/user-customer-accounts";
import { CustomerAccount } from "@models/customer-account";

@Injectable()
export class UserService {
    private usnrToken: string;

    private baseUserUrl: string = environment.ApiBase + "users/";

    private currentUser: Subject<ValidatedUser> = new Subject<ValidatedUser>();
    currentUser$: Observable<ValidatedUser> = this.currentUser.asObservable();

    private currentAccount: Subject<CustomerAccount> = new Subject<CustomerAccount>();
    currentAccount$: Observable<CustomerAccount> = this.currentAccount.asObservable();

    constructor(
        private http: HttpClient,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) { }

    // initializeMsal(): void {
    //     this.msalBroadcastService.msalSubject$
    //         .pipe(
    //             filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //         )
    //         .subscribe((result: EventMessage) => {
    //             console.log(result);
    //             const payload = result.payload as AuthenticationResult;
    //             this.authService.instance.setActiveAccount(payload.account);
    //         });

    // }

    signOutUser(): void {
        // if (this.isAuthenticated()) {
        //     this.authService.logoutPopup().subscribe();
        // }
        // console.log('signOutUser');
        // this.clearCurrentUser();
        if (this.isAuthenticated()) {
            let currentAccount: AccountInfo = this.authService.instance.getActiveAccount();
            const logoutHint = currentAccount.idTokenClaims.login_hint;
            this.authService.logoutRedirect({
                logoutHint: logoutHint,
                postLogoutRedirectUri: window.location.origin
            });
        }

        this.clearCurrentUser();
    }

    async loginUser(data: any): Promise<boolean> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        return await firstValueFrom(this.http.post<ValidatedUser>(this.baseUserUrl + "user-login", data, { headers: headers }))
            .then((result: ValidatedUser) => this.setCurrentUser(result));
    }

    async loginUsnrUser(userId: string): Promise<void> {
        // HACK because of bug in MSAL where value doesn't get cleared upon sign-out:
        sessionStorage.removeItem('msal.interaction.status');

        let req: PopupRequest = {
            authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
            scopes: [],
            prompt: "login",
            loginHint: userId
        }
        await firstValueFrom(this.authService.acquireTokenPopup(req));
        // .then(
        //     (response: AuthenticationResult) => {
        //         this.authService.instance.setActiveAccount(response.account);
        //         this.usnrToken = response.idToken;
        //     }
        // );
        // if (this.usnrToken && this.usnrToken !== '') {
        //     await this.createValidatedUser();
        // }
    }

    async setInternalAccount(account: AccountInfo, token: string): Promise<void> {
        this.authService.instance.setActiveAccount(account);
        this.usnrToken = token;
        if (this.usnrToken && this.usnrToken !== '') {
            await this.createValidatedUser();
        }
    }

    isAuthenticated(): boolean {
        return this.authService.instance.getActiveAccount() !== null;
    }

    async createValidatedUser(): Promise<void> {
        await this.loginAuthenticatedUser();
    }

    getCurrentUser(): ValidatedUser {
        const user: string = localStorage.getItem("currentUser");

        if (user !== null && user !== undefined && user !== "") {
            const validatedUser: ValidatedUser = JSON.parse(user);
            return validatedUser;
        }

        return null;
    }

    validateCurrentUser(user: ValidatedUser): void {
        if (user) {
            this.http.get<ValidatedUser>(this.baseUserUrl + "validate-user").subscribe({
                next: (response) => {
                    if (!response) {
                        console.log('validateCurrentUser');
                        this.clearCurrentUser();
                    }
                },
                error: (error: any) => { console.error(error); }
            });
        }
    }

    private clearCurrentUser(): void {
        console.log('clearCurrentUser');
        localStorage.removeItem("currentUser");
        localStorage.removeItem("token");
        localStorage.removeItem("customerAccount");
        this.currentUser.next(null);
        this.currentAccount.next(null);
    }

    public setCurrentUser(user: ValidatedUser): boolean {
        if (!user) {
            // console.log('setCurrentUser - no user');
            this.clearCurrentUser();
            return false;
        }

        const storedUser: string = JSON.stringify({
            UserId: user.UserId,
            Name: user.Name
        });
        localStorage.setItem("currentUser", storedUser);
        localStorage.setItem("token", user.Token);
        localStorage.removeItem("customerAccount");
        this.currentUser.next(user);
        this.currentAccount.next(null);
        return true;
    }

    async loginAuthenticatedUser(): Promise<boolean> {
        if (this.authService.instance) {
            var accountInfo: AccountInfo = this.authService.instance.getActiveAccount();
            if (accountInfo) {
                // let req: SilentRequest = {
                //     authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
                //     scopes: []
                // }
                // await firstValueFrom(this.authService.acquireTokenSilent(req)).then((authResult: AuthenticationResult) => this.usnrToken = authResult.idToken);
            } else {
                await this.getAuthenticationToken();
            }

            let authToken: string = this.usnrToken;
            const data = { userId: accountInfo.username, token: authToken };

            return await firstValueFrom(this.http.post<ValidatedUser>(this.baseUserUrl + "usnr-login", data))
                .then((user: ValidatedUser) => {
                    if (user.Name === "" || user.Name === null) {
                        user.Name = accountInfo.username;
                    }

                    return this.setCurrentUser(user);
                });
        }
    }

    private async getAuthenticationToken(): Promise<string> {
        this.usnrToken = '';
        let req: SilentRequest = {
            authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
            scopes: ['User.Read']
        }
        let self = this;
        await firstValueFrom(this.authService.acquireTokenSilent(req)).then((authResult: AuthenticationResult) => {
            self.usnrToken = authResult.idToken;
        })
        .catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
                this.signOutUser();
            }
        });

        return this.usnrToken;
    }

    getCustomerAccountOptions(criteria: string): Observable<UserCustomerAccounts> {
        const params: HttpParams = new HttpParams({ fromObject: { criteria: criteria } });
        return this.http.get<UserCustomerAccounts>(this.baseUserUrl + "customer-accounts", { params: params });
    }

    setCustomerAccount(account: CustomerAccount, accountPage: string): void {
        localStorage.setItem("customerAccount", JSON.stringify(account));
        localStorage.setItem("customerAccountPage", accountPage);
        this.currentAccount.next(account);
    }

    getCustomerAccount(): CustomerAccount {
        const customerAccount: string = localStorage.getItem("customerAccount");
        if (customerAccount && customerAccount !== "") {
            return JSON.parse(customerAccount);
        }

        return null;
    }

    getCustomerAccountPage(): string {
        return localStorage.getItem("customerAccountPage");
    }
}
