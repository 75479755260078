import { Pipe, PipeTransform } from "@angular/core";

import { SearchResultItem } from "@models/search-result-item";

@Pipe({
    name: "filterByCategory"
})
export class FilterByCategoryPipe implements PipeTransform {
    transform(searchResultItems: SearchResultItem[], key: string): SearchResultItem[] {
        let results: SearchResultItem[];
        if (key !== undefined && key !== null && key !== "") {
            results = searchResultItems.filter(result => result.Category === key);
        } else {
            results = searchResultItems;
        }

        return results;
    }
}
