import { Component } from "@angular/core";

import { environment } from "@environment";

@Component({
    selector: "content-media-link",
    templateUrl: "./content-media-link.component.html",
    styleUrls: ["./content-media-link.component.less"]
})
export class ContentMediaLinkComponent {
    imageLocation: string = environment.ImageLocation;

    title: string;
    description: string;
    other: string;
    mediaFileName: string;
}
